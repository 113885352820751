export const enGenerarDocumentos = {
    translation : {
        "Hola" : "Hello",
        "¿Qué documento quieres rellenar ahora?" : "What document do you want to fill in now?",
        "No tienes permisos para acceder a esta página" : "You do not have permission to access this page",
        "Generar Documentos" : "Generate Documents",
        "Documentos de Gastos" : "Expense Documents",
        "Documento de Prima Única" : "Single Premium Document",
        "Volver atrás" : "Go back",
        "Gestión de plantillas" : "Template management",
        "del generador de documentos" : "of the document generator",
        "Gestión de documentos" : "Document management",
        "Gastos Extrajudiciales" : "Extrajudicial expenses",
        "Prima Única" : "Single Premium",

        //Demanda gastos
        "Claúsula": "Clause",
        "Texto de la cláusula": "Clause text",
        "Tipo de adquisición" : "Type of acquisition",
        "Datos del banco" : "Bank data",
        "Total de gastos" : "Total expenses",
        "Jugzado específico" : "Specific court",

        "Documento nº 3": "Document nº 3",
        "Documento nº 4": "Document nº 4",
        "Documento nº 5": "Document nº 5",
        "Documento nº 6": "Document nº 6",
        "Documento nº 7": "Document nº 7",
        "Documento nº 8": "Document nº 8",

    }
};