import { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { PencilIcon, TrashIcon, XMarkIcon, CheckIcon, XCircleIcon, PlusIcon } from "@heroicons/react/20/solid";
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import dayjs from 'dayjs';

const EditableTable = forwardRef(({
    columns,
    initialData,
    maxRows = Infinity,
    onUpdateRow,
    onAddRow,
    onDeleteRow
}, ref) => {
    const [data, setData] = useState(initialData);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editValues, setEditValues] = useState({});
    const [newRowEditing, setNewRowEditing] = useState(false);
    const rowIdCounter = useRef(initialData.length);

    useImperativeHandle(ref, () => ({
        getAllRows: () => data
    }));

    const handleEditChange = (field, value) => {
        setEditValues({
            ...editValues,
            [field]: value
        });
    };

    const updateRow = (index, updatedRow) => {
        const newData = [...data];
        newData[index] = { ...newData[index], ...updatedRow };
        setData(newData);
        if (newRowEditing) {
            onAddRow(newData[index]);
        } else {
            onUpdateRow(newData[index]);
        }
        setEditingIndex(null);
        setNewRowEditing(false);
    };

    const deleteRow = (index) => {
        const rowToDelete = data[index];
        setData(prevData => prevData.filter((_, i) => i !== index));
        onDeleteRow(rowToDelete);
        setNewRowEditing(false);
    };

    const addRow = () => {
        const newRow = columns.reduce((acc, col) => ({ ...acc, [col.field]: '' }), { id: `row-${rowIdCounter.current}` });
        setData(prevData => [...prevData, newRow]);
        setEditingIndex(data.length);
        setEditValues(newRow);
        setNewRowEditing(true);
        rowIdCounter.current += 1;
    };

    const renderInputField = (column, rowData, index) => {
        if (editingIndex !== index) {
            return rowData[column.field];
        }

        const commonClasses = "w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";

        switch (column.type) {
            case 'text':
                return (
                    <input
                        type="text"
                        onChange={(e) => handleEditChange(column.field, e.target.value)}
                        className={commonClasses}
                        defaultValue={rowData[column.field]}
                    />
                );
                case 'date': {
                    const [day, month, year] = rowData[column.field].split('/');
                    const dateValue = `${year}-${month}-${day}`; // Reordena a formato YYYY-MM-DD
                    return (
                        <input
                            type="date"
                            onChange={(e) => handleEditChange(column.field, e.target.value)}
                            className={commonClasses}
                            defaultValue={dateValue}
                        />
                    );
                }
            case 'select':
                return (
                    <select
                        onChange={(e) => handleEditChange(column.field, e.target.value)}
                        className={commonClasses}
                        defaultValue={rowData[column.field]}
                    >
                        {column.options.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                );
            default:
                return rowData[column.field];
        }
    };

    const renderRow = (rowData, index) => (
        <motion.tr
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="text-gray-500 dark:text-gray-400 dark:bg-gray-800"
            key={`${rowData.id}-${index}`}
        >
            {columns.map((column, colIndex) => (
                <td className={`px-4 py-3 dark:bg-gray-800 ${column.width || ''}`} key={`${rowData.id}-${colIndex}`}>
                    {renderInputField(column, rowData, index)}
                </td>
            ))}
            <td className="px-4 py-3 flex justify-start dark:bg-gray-800 w-1/5">
                {editingIndex === index ? (
                    <div className="flex">
                        <button type="button" className="btn btn-secondary" onClick={() => {
                            if (newRowEditing) {
                                deleteRow(index);
                            } else {
                                setEditingIndex(null);
                                setNewRowEditing(false);
                            }
                        }}>
                            <XMarkIcon className="h-7 w-7 text-gray-300 dark:text-gray-500" />
                        </button>
                        <button type="button" className="btn btn-success ml-2" onClick={() => {
                            updateRow(index, editValues);
                        }}>
                            <CheckIcon className="h-7 w-7 text-gray-300 dark:text-gray-500" />
                        </button>
                    </div>
                ) : (
                    <div className="flex">
                        <button type="button" className="btn btn-secondary" onClick={() => {
                            setEditingIndex(index);
                            setEditValues(rowData);
                        }}>
                            <PencilIcon className="h-5 w-5 text-gray-300 dark:text-gray-500" />
                        </button>
                        <button type="button" className="btn btn-danger ml-2" onClick={() => deleteRow(index)}>
                            <TrashIcon className="h-5 w-5 text-gray-300 dark:text-gray-500" />
                        </button>
                    </div>
                )}
            </td>
        </motion.tr>
    );

    return (
        <div className="relative shadow-md sm:rounded-lg">
            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            {columns.map((column, index) => (
                                <th scope="col" className={`px-6 py-3 ${column.width || ''}`} key={index}>{column.label}</th>
                            ))}
                            <th scope="col" className="px-1 py-3 w-6">Acciones</th>
                        </tr>
                    </thead>
                    <AnimatePresence>
                        <tbody>
                            {data.length === 0 ? (
                                <tr className="bg-white dark:bg-gray-800 dark:border-gray-700">
                                    <td colSpan={columns.length + 1}>
                                        <div className="flex flex-col justify-center items-center my-8">
                                            <XCircleIcon className="w-12 h-12 text-gray-400 dark:text-gray-600" />
                                            <span className="text-gray-400 dark:text-gray-600">No hay datos</span>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                data.map((rowData, index) => renderRow(rowData, index))
                            )}
                        </tbody>
                    </AnimatePresence>
                </table>
            </div>
            <div className="text-center mt-4">
                {data.length < maxRows && (
                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.95 }}
                        type='button'
                        className="btn btn-primary bg-primary-500 dark:bg-gray-700 hover:bg-primary-400 dark:hover:bg-gray-600 w-full flex justify-center items-center rounded-sm"
                        onClick={addRow}
                    >
                        <PlusIcon className="w-6 h-6 text-white" />
                    </motion.button>
                )}
            </div>
        </div>
    );
});

EditableTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            field: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['text', 'date', 'select']).isRequired,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string.isRequired,
                    value: PropTypes.string.isRequired
                })
            ),
            width: PropTypes.string
        })
    ).isRequired,
    initialData: PropTypes.array.isRequired,
    maxRows: PropTypes.number,
    onUpdateRow: PropTypes.func.isRequired,
    onAddRow: PropTypes.func.isRequired,
    onDeleteRow: PropTypes.func.isRequired
};

EditableTable.displayName = 'EditableTable';

export default EditableTable;