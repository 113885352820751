import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import PubSub from "pubsub-js"; // Uncomment if needed
import { useTranslation } from "react-i18next";
import { LoadingComponent } from "../LoadingComponent";
import Toolbar from "./Toolbar";
import supabaseClient from "../../utils/supabaseInit";
import placeholder from "../../assets/img/placeholder.jpeg";
import { useAuth } from "../../hooks/useAuth";
import { v4 as uuidv4 } from "uuid";
import { useColorScheme } from "../../context/ColorSchemeContext";
import { FixedSizeList as List } from "react-window";
import { SparklesIcon } from "@heroicons/react/20/solid";
import { Document } from "postcss";
import { formatDate } from "../../utils/convertTimestamp";

const urlgetData = `https://${import.meta.env.VITE_COVENTIA_WA_URL}` + "/userlist";
const urlgetUserInfo = `https://${import.meta.env.VITE_COVENTIA_WA_URL}` + "/alluser";
const urlsetUnread = `https://${import.meta.env.VITE_COVENTIA_WA_URL}` + "/setunread";
const urlChangeAgent = `https://${import.meta.env.VITE_COVENTIA_WA_URL}` + "/changeagent";
const urlUpdateAllMessage = `https://${import.meta.env.VITE_COVENTIA_WA_URL}` + "/updatemsg";
const urlchangeAIState = `https://${import.meta.env.VITE_COVENTIA_WA_URL}` + "/changeaistate";
const socketUUID = uuidv4();
let selectedUser = null
const { data } = await supabaseClient.auth.getSession();

const Sidebar = (props) => {
  const access_token = data.session.access_token;
  const { t } = useTranslation(); // React-i18next hook for translation
  const [chats, setChats] = useState([]); // State for storing chat data
  const [filteredChats, setFilteredChats] = useState([]); // State for storing filtered chat data
  const [chatInfo, setChatInfo] = useState([]); // State for storing chat info data
  const [inputValue, setInputValue] = useState(""); // State for input value
  const [isLoading, setIsLoading] = useState(true); // State for loading status
  const [isLostConnection, setIsLostConnection] = useState(false);
  const [selected, setSelected] = useState(null);
  const [rightClickSelect, setRightClickSelect] = useState(null);
  const contextMenu = useRef(null);
  const liRefs = useRef({});
  const [contextMenuState, setContextMenuState] = useState(false);
  const [users, setUsers] = useState([]);
  const [inChangeAgent, setInChangeAgent] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectArr, setSelectedArr] = useState([]);
  const [agentFilter, setAgentFilter] = useState([]);
  const [notReadFilter, setNotReadFilter] = useState(false);
  const [agent, setAgent] = useState("");
  const { userPublicData } = useAuth();
  const [selectAgentMenu, setSelectAgentMenu] = useState(false);
  const [agentMenuFilter, setAgentMenuFilter] = useState("");
  const [listRange, setListRange] = useState(40);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const prevValuesRef = useRef({
    inputValue: '',
    agentFilter: [],
    notReadFilter: false,
  });
  const notReadInput = useRef({})
  const searchInputRef = useRef({})
  const [isAtBottom, setIsAtBottom] = useState(false);
  const { colorScheme, changeColorScheme } = useColorScheme(); //ColorScheme es el contexto que nos dice si el tema es dark o light
  const darkMode = colorScheme === "dark"; //Dark mode estará a true si el contexto dice que el tema es dark
  const socketRef = useRef(null);

  const connectWebSocket = () => {
    let socket = socketRef.current;
    socket = new WebSocket(`wss://${import.meta.env.VITE_COVENTIA_WA_URL}?userId=` + socketUUID);
    socket.onopen = async () => {
      setIsLostConnection(false);
      setIsLoading(false);
      console.log("Connection established");
      if (reconnectInterval) {
        clearInterval(reconnectInterval);
      }
      intervalId = setInterval(() => {
        socket.send(`ping@${socketUUID}`);
      }, 20 * 1000);
    };

    socket.onmessage = async (event) => {
      if (event.data == "pong") {
        return;
      }
      const [msg, target] = JSON.parse(event.data);
      const msgWs = msg
      if (msgWs === "updatemsgstatus") {
        const data = JSON.parse(event.data)[2];
        const { id, status } = data;
        setChats((prevChats) => {
          return prevChats.map((chat) => {
            if (chat.name !== target) {
              return chat;
            } else {
              setChatInfo((prevChatInfo) => {
                return prevChatInfo.map((element) => {
                  if (chat.chatID !== element.chatID) {
                    return element;
                  }

                  if (id === element.lastMsg.id) {
                    return {
                      ...element,
                      lastMsg: {
                        ...element.lastMsg,
                        status: status,
                      },
                    };
                  }

                  return element;
                });
              });
            }
            return chat;
          });
        });
      } else if (msgWs === "insertIncomingMessage" || msgWs === "send_text" || msgWs === "send_media" || msgWs === "send_template") {
        if (notReadInput.current && notReadInput.current.value == 'true' && msgWs == "insertIncomingMessage") {
          getData()
          return
        }
        const data = JSON.parse(event.data)[2];
        const { state, msg } = data;
        const { insertedUser } = data;
        if (!insertedUser) {
          //#region case without insertuser
          setChats((prevChats) => {
            const sortedchat = [];
            prevChats.map((chat) => {
              if (chat.name !== target) {
                return chat;
              } else {
                setChatInfo((prevChatInfo) => {

                  const arr = prevChatInfo.map((element) => {
                    if (chat.chatID !== element.chatID) {
                      return element;
                    }

                    if (state !== undefined) {
                      return {
                        ...element,
                        lastMsg: { ...msg },
                      };
                    } else {
                      return {
                        ...element,
                        lastMsg: { ...msg },
                      };
                    }
                  });

                  arr.sort((a, b) => b.lastMsg.timestamp - a.lastMsg.timestamp);
                  arr.forEach((chat) => {
                    const item = prevChats.find(
                      (prevChat) => prevChat.chatID === chat.chatID
                    );
                    if (state != undefined) {
                      sortedchat.push({ ...item, state: state });
                    } else {
                      sortedchat.push({ ...item });
                    }
                  });
                  return arr;
                });
                return { ...chat, state: state };
              }
            });
            return sortedchat;
          });

          //#endregion
        } else {
          console.log("inserteduser");
          let chatID;
          setChats((prevchat) => {
            chatID = prevchat.length + 1;
            return [
              ...prevchat,
              {
                chatID: prevchat.length + 1,
                name: target,
                username: insertedUser.username,
                agent: "",
                state: insertedUser.state,
                date: [formatDate(insertedUser.lastmsg.timestamp)],
              },
            ];
          });
          setChatInfo((prevChatInfo) => {
            return [
              ...prevChatInfo,
              {
                chatID: chatID,
                lastMsg: { ...msg },
              },
            ];
          });

          setChats((prevChats) => {
            const sortedchat = [];
            setChatInfo((prevChatInfo) => {
              const arr = prevChatInfo.sort(
                (a, b) => b.lastMsg.timestamp - a.lastMsg.timestamp
              );
              arr.forEach((chat) => {
                const item = prevChats.find(
                  (prevChat) => prevChat.chatID === chat.chatID
                );
                sortedchat.push({ ...item });
              });
              return arr;
            });
            return sortedchat;
          });
        }
      } else {
        getData();
      }

      if (event.data === "changeagent" || event.data === "updateUser") {
        PubSub.publish("MsgSend", null);
        getAllUser();
      }
      // Handle the received message from the server here
    };
    socket.onclose = () => {
      console.log("Connection closed");
      clearInterval(intervalId);
      if (!reconnectInterval) {
        reconnectInterval = setInterval(() => {
          console.log("Attempting to reconnect...");
          connectWebSocket();
        }, 5000);
      }
    };

    socketRef.current = socket;
  };
  useEffect(() => {
    const getInitialData = async () => {
      let socket;
      // Fetch data on component mount
      setIsLoading(true);
      await getData();
      getAllUser();
      const token = PubSub.subscribe("AgentName", agentName);
      const token2 = PubSub.subscribe("moveToNewUser", moveToNewUser);
      const token3 = PubSub.subscribe("ChangeChat", changeChat);
      let intervalId;
      let reconnectInterval;
      document.addEventListener("contextmenu", showContextMenu);
      document.addEventListener("click", hideContextMenu);

      connectWebSocket();
      // Cleanup function
      return () => {
        document.removeEventListener("contextmenu", showContextMenu);
        document.removeEventListener("click", hideContextMenu);
        PubSub.unsubscribe(token);
        PubSub.unsubscribe(token2);

        if (socketRef.current.readyState === WebSocket.OPEN) {
          socketRef.current.send("Disconnect@" + userPublicData.first_name);
        }
      };
    };
    getInitialData();
  }, []);

  // useEffect que cuando cambia el input value o el filtro de agentes, filtra los chats y los guarda en filteredChats. Filtra por name y username (input) y por agent (select)
  useEffect(() => {
    if (isInitialRender) {
      // Set initial render to false after the first render
      setTimeout(() => { setIsInitialRender(false); }, 1000)

      return;
    }
    const timer = setTimeout(() => {
      if (
        prevValuesRef.current.inputValue !== inputValue ||
        prevValuesRef.current.agentFilter !== agentFilter ||
        prevValuesRef.current.notReadFilter !== notReadFilter
      ) {
        // 更新 listRange 逻辑
        if (listRange === 40) {
          getData();
        } else {
          setListRange(40);
        }
        // 更新前一个值
        prevValuesRef.current = {
          inputValue,
          agentFilter,
          notReadFilter,
        };
      }
    }, 500); // 500ms debounce

    // 清理定时器
    return () => {
      clearTimeout(timer);
    };
  }, [inputValue, agentFilter, notReadFilter]);
  useEffect(() => {
    if (isInitialRender) {
      // Set initial render to false after the first render
      setTimeout(() => { setIsInitialRender(false); }, 1000)
      return;
    }

    if (listRange - 40 <= filteredChats.length) {
      getData();
    }

  }, [listRange]);
  useEffect(() => {
    if (filteredChats.length == 0) {
    } else {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting == true) {
            console.log('增加列表长度')
            setListRange(listRange + 40);
          }
        },
        { threshold: 0.2 }
      );
      const enditem = filteredChats[filteredChats.length - 1].name;
      const intervalId = setInterval(() => {
        if (liRefs.current[enditem]) {
          observer.observe(liRefs.current[enditem]);
          console.log(liRefs.current[enditem])
          clearInterval(intervalId);
        }
      }, 100);

      return () => {
        clearInterval(intervalId);
        if (liRefs.current[enditem]) {
          observer.unobserve(liRefs.current[enditem]);
        }
      };
    }
  }, [filteredChats]);

  useEffect(() => {
    setFilteredChats(chats)
  },[chats])

  const agentName = (_, data) => {
    setAgent(data);
  };
  const changeChat = (_, data) => {
    setSelected(data.user);
    selectedUser = data.user
    console.log("updatemsg3");
    updateAllMessage(data.user);
  };

  const moveToNewUser = (_, data) => {
    const wa_id = data.wa_id;
    function getTodayDate() {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      let date = today.getDate();
      date = date < 10 ? "0" + date : date;
      const formattedDate = `${date}-${month}-${year}`;

      return formattedDate;
    }

    const todayDate = getTodayDate();
    setSelected(wa_id);
    selectedUser = wa_id
    props.changeChat(wa_id, [], "");
  };
  function showContextMenu(event, name) {

    if (contextMenu.current && event.target != contextMenu && contextMenu.current.style.display == "none") {
      const listItem = liRefs.current[name];
      if (listItem) {
        event.preventDefault();
        const rect = listItem.getBoundingClientRect();
        contextMenu.current.style.top = `${rect.y - 100}px`;
        contextMenu.current.style.left = `${rect.x - 100}px`;
        contextMenu.current.style.display = "block";
        setContextMenuState(true);
      }
    } else if (contextMenu && event.target != contextMenu) {
      const listItem = liRefs.current[name];

      event.preventDefault();

      const { clientX, clientY } = event;
      contextMenu.current.style.top = `${clientY}px`;
      contextMenu.current.style.left = `${clientX}px`;
      contextMenu.current.style.display = "block";
      setContextMenuState(true);
    }
  }

  function hideContextMenu(event) {
    if (event.target.id === "changeAgent") {
      return;
    }
    if (event.target.id !== "selectAgent") {
      setSelectAgentMenu(false);
    }
    if (
      contextMenu &&
      event.target != contextMenu &&
      contextMenu?.current?.style.display == "block"
    ) {
      contextMenu.current.style.display = "none";
      setContextMenuState(false);
      setRightClickSelect(null);
      setInChangeAgent(false);
    }
  }
  const handleRightClick = (e, name) => {
    if (e.type === "contextmenu") {
      showContextMenu(e, name);
      // setSelected(name);
      setRightClickSelect(name);
    }
  };

  const handleClick = async (name, date, listagent) => {
    if (!contextMenuState) {
      if (selected !== name) {
        setSelected(name);
        selectedUser = name;
  
        // Aquí agregas la llamada a setConversation
        const chat = chats.find(chat => chat.name === name);
        if (chat) {
          const conversationName = `${chat.username ? chat.username : "Nombre desconocido"} (${chat.name})`;
          props.setConversation({
            name: conversationName,
            agent: chat.agent,
            state: chat.state
          });
        }
  
        socketRef.current.send(
          "changeChat@" + agent + "@" + selected + "@" + name + "@" + socketUUID
        );
        console.log("updatemsg2");
        await updateAllMessage(name);
        props.changeChat(name, date, listagent);
      }
    }
  };

  const updateAllMessage = async (name) => {
    const access_token = data?.session.access_token;
    const setting = {
      wa_id: name,
    };
    const headers = {
      Authorization: access_token,
    };
    const response = await axios.post(urlUpdateAllMessage, setting, {
      headers,
    });
    //console.log(response);
  };
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleChangeAgent = (e) => {
    setInChangeAgent(true);
    contextMenu.current.style.display = "block";
  };
  const handleSelectAgent = () => {
    setSelectAgentMenu((prev) => !prev);
  };
  const handleCheckboxChange = (name) => {
    console.log(name);
    if (name == "") {
      //setSelectedItem('')
      setSelectedArr([]);
      setAgentFilter([]);
    } else {
      //setSelectedItem(e.target.value)
      setSelectedArr((prevArr) => {
        if (prevArr.includes(name)) {
          setAgentFilter(prevArr.filter((item) => item !== name));
          return prevArr.filter((item) => item !== name);
        } else {
          setAgentFilter([...prevArr, name]);
          return [...prevArr, name];
        }
      });
    }
  };
  const changeAgent = async (agent) => {
    const access_token = data?.session.access_token;
    const postData = {
      wa_id: rightClickSelect,
      agent: agent,
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": "69420",
      Authorization: access_token,
    };
    // 发起 POST 请求
    axios
      .post(urlChangeAgent, postData, { headers })
      .then((response) => { })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getAllUser = async () => {
    const access_token = data?.session.access_token;
    const token = data.session.access_token;
    const axiosInstance = axios.create({
      params: {
        token,
      },
    });
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "69420",
        Authorization: access_token,
      },
    };
    const response = await axiosInstance.get(urlgetUserInfo, config);
    setUsers(response.data);
  };

  const agentFilterRef = useRef(agentFilter);

  useEffect(() => {
    agentFilterRef.current = agentFilter;
  }, [agentFilter]);

  // Fetch data function
  const getData = async () => {
    const access_token = data?.session.access_token;
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "69420",
        Authorization: access_token,
      },
    };
    // let data
    try {
      const agents = encodeURIComponent(JSON.stringify(agentFilterRef.current));
      const params = {
        agent: agents,
        user: searchInputRef.current.value,
        range: listRange,
        notRead: notReadInput.current.value,
        selected: selectedUser
      };
      console.log('111', selectedUser)
      // Fetch data using axios
      const response = await axios.get(urlgetData, {
        ...config,
        params,
      });
      const data = response.data;
      // fetch('https://smiling-peaceful-prawn.ngrok-free.app/userlist', {
      //     method: 'GET',
      //     headers: {
      //         'Accept': 'application/json',
      //         'Content-Type': 'application/json'
      //     }
      // })
      //     .then(response => {
      //         if (!response.ok) {
      //             throw new Error('Network response was not ok');
      //         }
      //         return response.json(); // 解析 JSON 数据
      //     })
      //     .then(data => {
      //         data=data // 打印返回的 JSON 数据
      //     })
      //     .catch(error => {
      //         console.error('There was a problem with your fetch operation:', error);
      //     });

      // Process data and update state
      let chats = [];
      let chatInfo = [];
      let index = 0;
      data.forEach((element) => {
        chats.push({
          chatID: index,
          name: element.wa_id,
          username: element.username,
          agent: element.agent,
          state: element.state,
          date: element.message_date,
        });
        chatInfo.push({ chatID: index, lastMsg: element.lastmsg });
        index++;
      });
      chatInfo.sort((a, b) => b.lastMsg.timestamp - a.lastMsg.timestamp);
      let newChats = [];
      chatInfo.forEach((chat) => {
        newChats.push(chats[chat.chatID]);
      });
      setChats(newChats);
      setFilteredChats(newChats);
      //setFilteredChats(newChats);
      setChatInfo(chatInfo);
      setIsLoading(false); // Set loading status to false
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false); // Set loading status to false
      setIsLostConnection(true);
    }
  };
  const setUnread = async (wa_id) => {
    const access_token = data?.session.access_token;
    const setting = {
      wa_id: wa_id,
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": "69420",
      Authorization: access_token,
    };

    axios
      .post(urlsetUnread, setting, { headers })
      .then((response) => { })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const changeAIState = async (wa_id, ai_state) => {
    const access_token = data?.session.access_token;
    const setting = {
      wa_id: wa_id,
      ai_state: ai_state,
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": "69420",
      Authorization: access_token,
    };

    axios
      .post(urlchangeAIState, setting, { headers })
      .then((response) => { })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  // Display message function
  const displayMessage = (message) => {

    if (Object.keys(message).length === 0) {
      return;
    }
    if (message.status === "failed") {
      return t("Error al enviar el mensaje");
    }
    if (message.body !== null && message.body !== "" && "body" in message) {
      return message.body;
    } else if (
      message.mime_type === "image/jpeg" ||
      message.mime_type === "image/png" ||
      message.mime_type === "image" ||
      message.type === "sticker"
    ) {
      return t("Mensaje de imagen");
    } else if (
      message.mime_type === "audio/mp3" ||
      message.mime_type === "audio/ogg" ||
      message.mime_type === "audio/ogg; codecs=opus"
    ) {
      return t("Mensaje de audio");
    } else if (message.type === "document") {
      return t("Mensaje de documento");
    } else if (message.type === "contacts") {
      return t("Mensaje de contacto");
    } else if (message.type === "template") {
      return t("Mensaje de plantilla");
    } else if (message.type === "button" && message.replyTo !== "") {
      return t("Respuesta marcada");
    } else if (message.type === "reaction") {
      return t("Reacción") + ": " + message.emoji;
    } else {
      return t("Mensaje no admitido");
    }
  };

  // Display status function
  const displayStatus = (status) => {
    switch (status) {
      case "delivered":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            {/* {t("Llegado")} */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              focusable="false"
              viewBox="0 0 12 12"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 6l2 2 4-4m0 4l4-4"
              />
            </svg>
          </span>
        );

      case "read":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            {/* {t("Leido")} */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              focusable="false"
              viewBox="0 0 12 12"
              className="text-primary-500"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 6l2 2 4-4m0 4l4-4"
              />
            </svg>
          </span>
        );

      case "sent":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            {/* {t("Enviado")} */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              focusable="false"
              viewBox="0 0 12 12"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 6l2 2 4-4"
              />
            </svg>
          </span>
        );

      case "failed":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            {/* {t("Fallado")} */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              focusable="false"
              viewBox="0 0 12 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
                d="M3.5 8.5l5-5m0 5l-5-5"
              />
            </svg>
          </span>
        );
      case "system":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              focusable="false"
              viewBox="0 0 12 12"
            >
              <path
                fill="currentColor"
                d="M10.5 6c0-.31-.03-.62-.09-.92l1.06-.78c.14-.1.19-.28.13-.44-.23-.6-.55-1.15-.95-1.64a.371.371 0 00-.44-.1L9 2.65c-.46-.41-1-.73-1.59-.92L7.26.41a.353.353 0 00-.3-.33C6.64.03 6.33 0 6 0c-.32 0-.64.03-.95.07-.16.03-.28.17-.3.34L4.6 1.72c-.6.2-1.14.52-1.59.93L1.8 2.11a.368.368 0 00-.44.1C.95 2.7.63 3.26.4 3.85c-.06.16 0 .34.13.44l1.07.78c-.07.3-.1.61-.1.93 0 .31.03.62.09.92L.53 7.7c-.14.1-.19.28-.13.44.23.6.55 1.15.95 1.64.11.13.29.17.44.1L3 9.35c.46.41 1 .73 1.59.92l.14 1.32c.02.17.14.3.31.33.32.05.63.08.96.08.32 0 .64-.03.95-.07.16-.03.29-.16.31-.33l.15-1.32c.6-.2 1.14-.51 1.59-.92l1.21.53c.15.07.34.03.44-.1.4-.49.72-1.04.95-1.64a.39.39 0 00-.13-.44l-1.07-.78c.07-.3.1-.61.1-.93zM6 7.5c-.83 0-1.5-.67-1.5-1.5S5.17 4.5 6 4.5s1.5.67 1.5 1.5S6.83 7.5 6 7.5z"
              />
            </svg>
          </span>
        );
      default:
        break;
    }
  };

  const Row = ({ index, style }) => {
    const chat = filteredChats[index];
    return (
      <li id="right-clickableList" key={chat.name}
        ref={(el) => (liRefs.current[chat.name] = el)}
        onClick={() => { handleClick(chat.name, chat.date, chat.agent); }}
        onContextMenu={(e) => handleRightClick(e, chat.name)}
        className={
          "relative flex justify-between gap-x-6 py-5 pl-3 pr-3 rounded-lg hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-800 mt-2 w-full " +
          (chatInfo.find((element) => element.chatID === chat.chatID)?.lastMsg.unread || (chatInfo.find((element) => element.chatID === chat.chatID)?.lastMsg.status != "read" && chatInfo.find((element) => element.chatID === chat.chatID)?.lastMsg.from_id == "")
            ? "bg-gray-100 dark:bg-gray-800"
            : "bg-white dark:bg-gray-900")
        }
        style={{
          ...style, transition: "background-color 0.2s",
          backgroundColor:
            selected === chat.name && !darkMode
              ? "#deeaff"
              : selected === chat.name && darkMode
                ? "#2d3748"
                : "",
        }}
      >
        <div className="flex items-center min-w-0 gap-x-4">
          <img className="hidden xl:block h-12 w-12 flex-none rounded-full bg-gray-50" src={placeholder} alt="" />
          <div className="min-w-0 flex-auto">
            <div className="flex items-center">
              <p className="text-m font-semibold leading-6 text-gray-900 dark:text-white w-full">

                {chat.username === chat.name || chat.username === "" || chat.username === null
                  ? (<>
                    <span className="hidden md:inline-block">
                      &nbsp;{`Nombre desconocido`}
                    </span>
                    <span className="inline-block md:hidden">
                      &nbsp;{`${chat.name}`}
                    </span>
                  </>)
                  : (chat.username)}

                <span className="hidden md:inline-block">
                  &nbsp;{`(${chat.name})`}
                </span>
              </p>
            </div>
            <div className="inline-flex items-center gap-x-2 w-full">
              <p className="mt-1 text-s leading-5 text-gray-500">
                <time>
                  {displayStatus(chatInfo.find((element) => element.chatID === chat.chatID).lastMsg.status)}
                </time>
              </p>
              {/* Si el mensaje no está leído, el texto va en negrita */}
              <p
                className={
                  "mt-1 truncate text-s leading-5 " +
                  (chatInfo.find((element) => element.chatID === chat.chatID)
                    .lastMsg.unread ||
                    (chatInfo.find((element) => element.chatID === chat.chatID)
                      .lastMsg.status != "read" &&
                      chatInfo.find((element) => element.chatID === chat.chatID)
                        .lastMsg.from_id == "")
                    ? "font-semibold text-gray-600"
                    : "text-gray-500")
                }
                style={{ width: "100%" }}
                title={displayMessage(chatInfo.find((element) => element.chatID === chat.chatID).lastMsg)}
              >
                {displayMessage(chatInfo.find((element) => element.chatID === chat.chatID).lastMsg)}
              </p>
            </div>
          </div>
        </div>
        <div className="relative hidden shrink-0 sm:flex sm:items-center sm:flex-col sm:justify-center">
          {chat.agent && (
            <>
            <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600" title={chat.agent} style={{ backgroundColor: users.find((element) => element.first_name === chat.agent)?.color }}>
              <span className="font-medium text-xs text-white">
                {chat.agent.split(" ").length > 1
                  ? chat.agent.split(" ")[0].charAt(0) + chat.agent.split(" ")[1].charAt(0)
                  : chat.agent.charAt(0) + chat.agent.charAt(1)}
              </span>
            </div>
            {chat.state === "ai" && (
              <SparklesIcon className="z-5000000 absolute top-2.5 -left-5 w-5 h-5 text-indigo-400 rounded-full"/>
            )}
            </>
          )}
          {chat.state === "ai" && !chat.agent && (
            <div className="relative inline-flex items-center justify-center w-8 h-8 rounded-full" title="AI">
              <div className="absolute top-1 -left-0.5 w-3 h-3">
                <SparklesIcon className="w-full h-full text-indigo-400" />
              </div>
              <span className="font-bold text-md text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-500">
                AI
              </span>
            </div>
          )}
        </div>
        {/* Esto solo se debe mostrar si el chat no está leído */}
        {(() => {
          const chatID = chat.chatID;
          const chatElement = chatInfo.find(
            (element) => element.chatID === chatID
          );
          const lastMsg = chatElement?.lastMsg;

          if (!lastMsg) {
            return null;
          }

          const isDelivered =
            lastMsg.status === "delivered" && chat.name == lastMsg.from;
          const isUnread = lastMsg.unread === true;
          const inChat = selected == lastMsg.from;

          if (isUnread) {
            return (
              <div className="absolute top-0 right-0 mt-2 mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <circle cx="5" cy="5" r="5" fill="red" />
                </svg>
              </div>
            );
          }
          if (isDelivered && !inChat) {
            return (
              <div className="absolute top-0 right-0 mt-2 mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <circle cx="5" cy="5" r="5" fill="red" />
                </svg>
              </div>
            );
          } else if (inChat) {
            console.log("updatemsg");
            updateAllMessage(selected);
            return null;
          } else {
            return null;
          }
        })()}
      </li>
    );
  };

  return (
    <div>
      {isLoading ? (
        <div
          className="flex justify-center items-center h-screen"
          style={{ height: "70%" }}
        >
          <LoadingComponent />
        </div>
      ) : isLostConnection ? (
        <p
          style={{ height: "55vh", width: "60%" }}
          className="flex justify-center items-center h-screen"
        >
          {t("Conexión perdida. Vuelve a intentarlo más tarde.")}
        </p>
      ) : (
        <div className="mb-0 ">
          <div className="w-[90%] xl:w-[95%] lg:w-[100%] md:w-[110%] sm:w-[120%]  mr-4">
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              {t("Buscar")}
            </label>
            <div className="w-[85%] flex xl:flex-nowrap">
              <input
                ref={searchInputRef}
                value={inputValue}
                onChange={handleChange}
                type="search"
                id="default-search"
                className="ml-1 w-full min-w-[70px] h-[40px] xl:h-[50px] text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 text-center placeholder-center"
                placeholder={t("Buscar por número")}
                required
              />
              <div id="selectAgent" className="w-[50px]">
                <button
                  value={selectedItem}
                  onClick={handleSelectAgent}
                  id="selectAgent"
                  className=" ml-1 bg-gray-50 w-[40px] h-[40px] xl:w-[50px] xl:h-[50px] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-[100%] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                >
                  {/* <option className="mx-4 text-gray-500" value={''}>{t('Ninguno')}</option>
                                        {
                                            users.map((element, index) => {
                                                return (
                                                    <option key={index} className="mx-4 text-gray-500" value={element.first_name}> {selectArr.includes(element.first_name) ? '√' + element.first_name : element.first_name}</option>
                                                )
                                            })
                                        } */}
                  <svg id="selectAgent" className="size-6 text-white dark:text-gray-600 dark:fill-primary-400 fill-primary-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path id="selectAgent" d="M4.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM14.25 8.625a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.5 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM17.25 19.128l-.001.144a2.25 2.25 0 0 1-.233.96 10.088 10.088 0 0 0 5.06-1.01.75.75 0 0 0 .42-.643 4.875 4.875 0 0 0-6.957-4.611 8.586 8.586 0 0 1 1.71 5.157v.003Z" />
                  </svg>


                </button>
                {selectAgentMenu ? (
                  <div
                    id="selectAgent"
                    className="absolute z-10 bg-white rounded-lg shadow w-60 dark:bg-gray-700"
                  >
                    <div id="selectAgent" className="p-3">
                      <label
                        id="selectAgent"
                        htmlFor="input-group-search"
                        className="sr-only"
                      >
                        Search
                      </label>
                      <div id="selectAgent" className="relative">
                        <div
                          id="selectAgent"
                          className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none"
                        >
                          <svg
                            id="selectAgent"
                            className="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                            />
                          </svg>
                        </div>
                        <input
                          id="selectAgent"
                          onChange={(event) =>
                            setAgentMenuFilter(event.target.value)
                          }
                          type="text"
                          className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder={t("Buscar usuario")}
                        />
                      </div>
                    </div>
                    <ul
                      id="selectAgent"
                      key={-1}
                      value={"noagent"}
                      className="h-8 px-3 pb-2 overflow-y-hidden text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownSearchButton"
                    >
                      <li id="selectAgent">
                        <div
                          id="selectAgent"
                          className="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                        >
                          <input
                            checked={selectArr.includes("noagent")}
                            id="selectAgent"
                            key={-1}
                            type="checkbox"
                            value={"noagent"}
                            className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            onChange={() => handleCheckboxChange("noagent")}
                          />
                          <label
                            id="selectAgent"
                            htmlFor={`checkbox-item-${-1}`}
                            className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                          >
                            <span className="text-primary-500">
                              {" "}
                              {t("Sin asignar")}
                            </span>
                          </label>
                        </div>
                      </li>
                    </ul>
                    {users.map((element, index) => {
                      function containsCaseInsensitive(str1, str2) {
                        var str1Lower = str1.toLowerCase();
                        var str2Lower = str2.toLowerCase();
                        return str1Lower.includes(str2Lower);
                      }
                      if (
                        containsCaseInsensitive(
                          element.first_name,
                          agentMenuFilter
                        )
                      ) {
                        return (
                          <ul
                            id="selectAgent"
                            key={index}
                            value={element.first_name}
                            className="h-8 px-3 pb-2 overflow-y-hidden text-sm text-gray-700 dark:text-gray-200"
                            aria-labelledby="dropdownSearchButton"
                          >
                            <li id="selectAgent">
                              <div
                                id="selectAgent"
                                className="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                              >
                                <input
                                  checked={selectArr.includes(
                                    element.first_name
                                  )}
                                  id="selectAgent"
                                  key={index}
                                  type="checkbox"
                                  value={element.first_name}
                                  className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                  onChange={() =>
                                    handleCheckboxChange(element.first_name)
                                  }
                                />
                                <label
                                  id="selectAgent"
                                  htmlFor={`checkbox-item-${index}`}
                                  className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                                >
                                  {element.first_name}
                                </label>
                              </div>
                            </li>
                          </ul>
                        );
                      }
                    })}

                    <a
                      onClick={() => handleCheckboxChange("")}
                      id="selectAgent"
                      href="#"
                      className="flex items-center p-3 text-sm font-medium text-red-600 border-t border-gray-200 rounded-b-lg bg-gray-50 dark:border-gray-600 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-red-500 hover:underline"
                    >
                      <svg
                        className="w-4 h-4 me-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 18"
                      >
                        <path d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-6a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2Z" />
                      </svg>
                      {t("Borrar selección")}
                    </a>
                  </div>
                ) : null}
              </div>

              <Toolbar changeChat={props.changeChat} conversation={props.conversation} setConversation={props.setConversation} />
            </div>
            <label className="inline-flex items-center mb-3 mt-3 ml-2 cursor-pointer">
              <input
                ref={notReadInput}
                type="checkbox"
                value={notReadFilter}
                className="sr-only peer"
                onChange={() => {
                  setNotReadFilter(!notReadFilter)
                }}
              />
              <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-300 dark:peer-focus:ring-primary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-primary-600"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                {t("Mostrar solo no leídos")}
              </span>
            </label>
          </div>
          <ul
            agent="list"
            className="w-[23%] scrollbar-hide"
            style={{
              position: "fixed",
              maxHeight: "90%",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <List
              height={window.innerHeight - 220}
              itemCount={filteredChats.length}
              itemSize={100}
              className="scrollbar scrollbar-thumb-gray-200 dark:scrollbar-thumb-gray-700 scrollbar-thumb-rounded-full"
            >
              {Row}
            </List>

          </ul>
          <div>
            {isAtBottom == true ? (
              <div
                className="flex justify-center items-center"
                style={{ height: "30%" }}
              >
                <LoadingComponent />
              </div>
            ) : null}
          </div>
          {inChangeAgent ? (
            <div
              ref={contextMenu}
              id="contextMenu"
              className="block z-50"
              style={{ display: "none", position: "absulute" }}
            >
              <div className="bg-white w-60 border border-gray-300 rounded-lg flex flex-col text-sm py-2 px-2 text-gray-500 shadow-lg dark:bg-gray-800 dark:border-gray-600">
                {
                  <>
                    <div
                      key={999999}
                      onClick={() => {
                        changeAgent("");
                      }}
                      className="flex hover:bg-gray-100 py-1 px-2 rounded cursor-default dark:hover:bg-gray-700"
                    >
                      <div className="text-gray-500 ml-2 dark:text-white">
                        {t("Nadie")}
                      </div>
                    </div>
                    {users.map((element, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            changeAgent(element.first_name);
                          }}
                          className="flex hover:bg-gray-100 py-1 px-2 rounded cursor-default dark:hover:bg-gray-700"
                        >
                          <div className="text-gray-500 ml-2 dark:text-white">
                            {element.first_name}
                          </div>
                        </div>
                      );
                    })}
                  </>
                }
                <button
                  onClick={() => setInChangeAgent(false)}
                  className="mt-1 text-white  bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  {t("Volver")}
                </button>
              </div>
            </div>
          ) : (
            <div
              ref={contextMenu}
              id="contextMenu"
              className="block z-50"
              style={{ display: "none", position: "absolute" }}
            >
              <div className="bg-white w-60 border border-gray-300 rounded-lg flex flex-col text-sm py-2 px-2 text-gray-500 shadow-lg dark:bg-gray-800 dark:border-gray-600">
                <div
                  onClick={() => setUnread(rightClickSelect)}
                  className="flex hover:bg-gray-100 py-1 px-2 rounded cursor-default dark:hover:bg-gray-700"
                >
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 7.8C6.7 6.3 9.2 5 12 5s5.3 1.3 7 2.8a12.7 12.7 0 0 1 2.7 3.2c.2.2.3.6.3 1s-.1.8-.3 1a2 2 0 0 1-.6 1 12.7 12.7 0 0 1-9.1 5c-2.8 0-5.3-1.3-7-2.8A12.7 12.7 0 0 1 2.3 13c-.2-.2-.3-.6-.3-1s.1-.8.3-1c.1-.4.3-.7.6-1 .5-.7 1.2-1.5 2.1-2.2Zm7 7.2a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <div className="text-gray-500 ml-2 dark:text-white">
                    {t("Marcar como no leído")}
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleChangeAgent();
                  }}
                  id="changeAgent"
                  className="flex hover:bg-gray-100 py-1 px-2 rounded cursor-default dark:hover:bg-gray-700"
                >
                  <svg
                    id="changeAgent"
                    className="w-5 h-5 text-gray-500 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="changeAgent"
                      fillRule="evenodd"
                      d="M9 7V2.2a2 2 0 0 0-.5.4l-4 3.9a2 2 0 0 0-.3.5H9Zm2 0V2h7a2 2 0 0 1 2 2v9.3l-2-2a1 1 0 0 0-1.4 1.4l.3.3h-6.6a1 1 0 1 0 0 2h6.6l-.3.3a1 1 0 0 0 1.4 1.4l2-2V20a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <div
                    id="changeAgent"
                    className="text-gray-500 ml-2 dark:text-white"
                  >
                    {t("Cambiar agente")}
                  </div>
                </div>
                {rightClickSelect == null ? (
                  ""
                ) : chats.find((element) => element.name === rightClickSelect)
                  .state == "ai" ? (
                  <div
                    onClick={() => {
                      changeAIState(rightClickSelect, "agent");
                    }}
                    id="changeIAstate"
                    className="flex hover:bg-gray-100 py-1 px-2 rounded cursor-default dark:hover:bg-gray-700"
                  >
                    <svg
                      id="changeIAstate"
                      className="w-5 h-5 text-gray-500 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        id="changeIAstate"
                        fillRule="evenodd"
                        d="M9 7V2.2a2 2 0 0 0-.5.4l-4 3.9a2 2 0 0 0-.3.5H9Zm2 0V2h7a2 2 0 0 1 2 2v9.3l-2-2a1 1 0 0 0-1.4 1.4l.3.3h-6.6a1 1 0 1 0 0 2h6.6l-.3.3a1 1 0 0 0 1.4 1.4l2-2V20a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <div
                      id="changeIAstate"
                      className="text-gray-500 ml-2 dark:text-white"
                    >
                      Desactivar IA
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      changeAIState(rightClickSelect, "ai");
                    }}
                    id="changeIAstate"
                    className="flex hover:bg-gray-100 py-1 px-2 rounded cursor-default dark:hover:bg-gray-700"
                  >
                    <svg
                      id="changeIAstate"
                      className="w-5 h-5 text-gray-500 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        id="changeIAstate"
                        fillRule="evenodd"
                        d="M9 7V2.2a2 2 0 0 0-.5.4l-4 3.9a2 2 0 0 0-.3.5H9Zm2 0V2h7a2 2 0 0 1 2 2v9.3l-2-2a1 1 0 0 0-1.4 1.4l.3.3h-6.6a1 1 0 1 0 0 2h6.6l-.3.3a1 1 0 0 0 1.4 1.4l2-2V20a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <div
                      id="changeIAstate"
                      className="text-gray-500 ml-2 dark:text-white"
                    >
                      Activar IA
                    </div>
                  </div>
                )}

                {/* <hr className="my-3 border-gray-300" /> */}
              </div>
            </div>
          )}
        </div>
      )}
      {/* <Toolbar /> */}
    </div>
  );
};

export default Sidebar;
