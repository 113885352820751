/* eslint-disable no-unused-vars */
import { Fragment, useEffect, useRef, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import toast from "react-hot-toast";
import axios from "axios";
import { formCompanies } from "../../data/formCompanies";
import { formatDate as formatDateUtil, validarDNIoNIF, validarTelefono, buscarDNIEnBaserow } from '../../utils'
import { FormGastos2 } from "./FormGastos2";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";
import { saveAs } from "file-saver";
import { FileInputWithCheckbox } from "../FileInputCheckBox";
import { Dialog, Transition } from "@headlessui/react";


export const FormGastos = ({ step1Loading, setStep1Loading, setCurrentStep }) => {
    const { t } = useTranslation();
    const [demandaGastos, setDemandaGastos] = useState(false);
    const [cantidadPersonasGE, setCantidadPersonasGE] = useState(1);
    const [numeroNegocioGastos, setNumeroNegocioGastos] = useState('');
    const [formValues, setFormValues] = useState({});
    const [step, setStep] = useState(1);
    const [hubspotData, setHubspotData] = useState({});
    const [tasacionBloqueGastos, setTasacionBloqueGastos] = useState('');
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [sendApud, setSendApud] = useState(true);
    const [autoFill, setAutoFill] = useState({
        'cantidad_personas': 1,
        'numero_negocio_gastos': '',
        'nprotocolo_gastos': '',
        'fecha_inicio_prestamo_gastos': '',
        'fecha_extrajudicial_gastos': '',
        'notario_gastos': '',
        'nombre_y_apellidos_gastos': '',
        'nombre': '',
        'dni_gastos': '',
        'direccion_gastos': '',
        'codigo_postal_gastos': '',
        'provincia_gastos': '',
        'banco_gastos': '',
        'datos_banco_gastos': '',
        'gastos_notaria_gastos': 0,
        'gastos_registro_gastos': 0,
        'gastos_gestoria_gastos': 0,
        'gastos_tasacion_gastos': 0,
        'porcentaje_cobro_gastos': 12,
        'email_gastos': '',
        'id_negocio': '',
        'telefono_gastos': '',
        'tipo_adquisicion_gastos': 'la adquisición de Vivienda, adquirida el mismo día en Escritura autorizada por el mismo notario.',
        'clausula_gastos': 'Quinta',
        'clausula_texto_gastos': '',
        'telefono2_gastos': '',
        'nombre_y_apellidos2_gastos': '',
        'dni2_gastos': '',
        'juzgado_especifico_gastos': 'juzgado especializado en ACCIONES INDIVIDUALES A LAS CONDICIONES GENERALES DE LA CONTRATACIÓN de la Provincia de Alicante, de acuerdo con el artículo 89.2 LOPJ y por ser el domicilio del demandante de acuerdo con el 52.2.14 de la Ley de Enjuiciamiento Civil',
        'documento_notaria_gastos': 'documento nº3',
        'documento_registro_gastos': 'documento nº4',
        'documento_gestoria_gastos': 'documento nº5',
        'documento_reclamacion_gastos': 'documento nº6',
        'documento_extrajudicial_gastos': 'documento nº7',
        'documento_tasacion_gastos': 'documento nº8',

    });
    const companies = formCompanies;
    const formatDate = formatDateUtil;
    const validacionDNIoNIF = validarDNIoNIF;
    const validacionTelefono = validarTelefono;
    const inputRef = useRef();
    const postalCodeRef = useRef();
    const provinciaRef = useRef();

    const [contratoFile, setContratoFile] = useState(null);
    const [apudFile, setApudFile] = useState(null);
    const [selectedBank, setSelectedBank] = useState(null);
    const [oneFile, setOneFile] = useState(false);

    const handleBankChange = (e) => {
        const selectedBank = formCompanies.find(company => company.name === e.target.value);
        setSelectedBank(selectedBank);
        setAutoFill({ ...autoFill, [e.target.name]: e.target.value });
    };

    //Función para manejar errores
    const handleError = (error, setStep1Loading) => {
        console.log(error);
        toast.error("Ha ocurrido un error inesperado");
        setStep1Loading(false);
    };

    //Función para manejar la descarga de archivos
    const downloadFile = async (response, index, setFileFunction) => {
        const url = "https://api.office.coventia.es/media/generated/" + response.data.documentos_generados[index];
        const resp = await fetch(url);
        const blob = await resp.blob();
        const fileName = response.data.documentos_generados[index];
        saveAs(blob, fileName);
        setFileFunction({ name: fileName, blob: blob });
    }

    //Use Effect para usar la api google places
    useEffect(() => {
        const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
        autocomplete.setComponentRestrictions({
            'country': ['es']
        });
        autocomplete.setFields(['address_component']);

        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            var city = '';
            var country = '';
            var province = '';
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (addressType == 'locality') {
                    city = place.address_components[i]['long_name'];
                }
                if (addressType == 'country') {
                    country = place.address_components[i]['long_name'];
                }
                if (addressType == 'administrative_area_level_2') {
                    province = place.address_components[i]['long_name'];
                }
            }
            var address = inputRef.current.value;
            var index = address.indexOf(city);
            if (index >= 0) {
                address = address.substr(0, index);
            }
            index = address.indexOf(country);
            if (index >= 0) {
                address = address.substr(0, index);
            }
            inputRef.current.value = address;
            provinciaRef.current.value = province;
            setAutoFill(prevState => ({ ...prevState, direccion_gastos: address, provincia_gastos: province }));
        });

        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            var postal_code = '';
            for (var i = 0; i < place.address_components.length; i++) {
                for (var j = 0; j < place.address_components[i].types.length; j++) {
                    if (place.address_components[i].types[j] == "postal_code") {
                        postal_code = place.address_components[i].long_name;
                    }
                }
            }
            postalCodeRef.current.value = postal_code;
            setAutoFill(prevState => ({ ...prevState, codigo_postal_gastos: postal_code }));
        });
    }, [step, demandaGastos]);

    //Efecto para actualizar los datos del banco cuando es autocompletado
    useEffect(() => {
        if (autoFill.banco_gastos) {
            const selectedBank = formCompanies.find(company => company.name === autoFill.banco_gastos);
            setSelectedBank(selectedBank);
        }
    }, [autoFill]);

    const handleInputChange = (event) => {
        setNumeroNegocioGastos(event.target.value);
        setAutoFill({ ...autoFill, [event.target.name]: event.target.value });
    };

    async function handleSubmit(event) {
        event.preventDefault()
        setStep1Loading(true)
        if (!demandaGastos) {
            try {
                //Introducir los datos del formulario en el estado formValues
                setFormValues({
                    cantidad_personas: event.target["cantidad_personas_gastos"].value,
                    nombre: event.target["nombre_y_apellidos_gastos"].value,
                    email: event.target["email_gastos"].value,
                    id_negocio: event.target["numero_negocio_gastos"].value,

                });

                setHubspotData({
                    "tipo_formulario": "gastos",
                    "dni": event.target["dni_gastos"].value,
                    "direccion": event.target["direccion_gastos"].value,
                    "banco_origen": event.target["banco_gastos"].value,
                    "fecha_inicio_prestamo": event.target["fecha_inicio_prestamo_gastos"].value,
                    "id_negocio": event.target["numero_negocio_gastos"].value,
                });

                let formData = new FormData(event.target);
                formData.set("tipo_formulario", "gastos_extrajudicial")
                formData.set("send_apud", sendApud)
                formData.set("fecha_inicio_prestamo_gastos", formatDate(event.target["fecha_inicio_prestamo_gastos"].value))
                const supabaseClient = createClient(
                    'https://uoclqlfzojdjrhcqhgef.supabase.co',
                    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
                )

                const { data, error } = await supabaseClient.auth.getSession()
                const access_token = data.session.access_token
                axios.post("https://api.office.coventia.es/enviar-datos-gastos-extrajudicial/", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': access_token
                    }
                }).then(async (response) => {
                    try {
                        // Array para almacenar todas las promesas de descarga
                        let downloadPromises = [];
                        //Crear fileDownloads, si sendApud es true se descargarán 3, si no 2
                        const fileDownloads = sendApud ? 3 : 2;

                        // Descargar los primeros archivos
                        for (let i = 0; i < fileDownloads; i++) {
                            const url = "https://api.office.coventia.es/media/generated/" + response.data.documentos_generados[i];
                            const fileName = response.data.documentos_generados[i];
                            const blob = await fetch(url).then(resp => resp.blob());
                            console.log(response.data.documentos_generados[i])
                            saveAs(blob, fileName);
                        }

                        // Descargar el archivo de contrato y almacenar la promesa en el array
                        if (sendApud) {
                            downloadPromises.push(downloadFile(response, 3, setContratoFile));
                            downloadPromises.push(downloadFile(response, 4, setApudFile));
                        } else {
                            downloadPromises.push(downloadFile(response, 2, setContratoFile));
                        }

                        // Esperar a que todas las descargas se completen antes de continuar
                        await Promise.all(downloadPromises);
                        // Mostrar mensaje de éxito y actualizar el estado
                        toast.success(t("¡Archivos descargados correctamente!"));
                        setStep1Loading(false);

                        // Si hay un número de negocio de gastos, avanzar al siguiente paso
                        if (numeroNegocioGastos !== '' && numeroNegocioGastos !== null && numeroNegocioGastos !== undefined) {
                            setStep(2);
                        }
                    } catch (error) {
                        handleError(error, setStep1Loading);
                        console.log(error);
                        toast.error(t("Ha ocurrido un error inesperado"));
                        setStep1Loading(false);
                    }
                }).catch((error) => {
                    handleError(error, setStep1Loading);
                    console.log(error);
                    toast.error(t("Ha ocurrido un error inesperado"));
                    setStep1Loading(false);
                });
            }
            catch (error) {
                handleError(error, setStep1Loading);
                console.log(error);
                toast.error(t("Ha ocurrido un error inesperado"));
                setStep1Loading(false);
            }
        } else {
            setFormValues({
                cantidad_personas: event.target["cantidad_personas_gastos"].value,
                nombre: event.target["nombre_y_apellidos_gastos"].value,
                id_negocio: event.target["numero_negocio_gastos"].value,

            });

            setHubspotData({
                "tipo_formulario": "gastos_judicial",
                "dni": event.target["dni_gastos"].value,
                "direccion": event.target["direccion_gastos"].value,
                "banco_origen": event.target["banco_gastos"].value,
                "fecha_inicio_prestamo": event.target["fecha_inicio_prestamo_gastos"].value,
                "id_negocio": event.target["numero_negocio_gastos"].value,
            });

            let formData = new FormData(event.target);
            formData.set("tipo_formulario", "gastos_judicial")
            formData.set("fecha_inicio_prestamo_gastos", formatDate(event.target["fecha_inicio_prestamo_gastos"].value))
            formData.set("fecha_extrajudicial_gastos", formatDate(event.target["fecha_extrajudicial_gastos"].value))
            formData.set("one_file", oneFile)
            formData.set("send_apud", sendApud)

            formData.set("total_gastos", autoFill.total_gastos)


            const supabaseClient = createClient(
                'https://uoclqlfzojdjrhcqhgef.supabase.co',
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
            )

            const { data, error } = await supabaseClient.auth.getSession()
            const access_token = data.session.access_token
            axios.post("https://api.office.coventia.es/enviar-datos-gastos-judicial/", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': access_token
                }
            }).then(async (response) => {
                try {
                    // Array para almacenar todas las promesas de descarga
                    let downloadPromises = [];
                    //Crear fileDownloads, si sendApud es true se descargarán 4, si no 3
                    const fileDownloads = sendApud ? 4 : 3;


                    if (oneFile) {
                        const url = "https://api.office.coventia.es/media/generated/" + response.data.documentos_generados[0];
                        const fileName = response.data.documentos_generados[0];
                        const blob = await fetch(url).then(resp => resp.blob());
                        saveAs(blob, fileName);
                    } else {
                        for (let i = 0; i < fileDownloads; i++) {
                            const url = "https://api.office.coventia.es/media/generated/" + response.data.documentos_generados[i];
                            const fileName = response.data.documentos_generados[i];
                            const blob = await fetch(url).then(resp => resp.blob());
                            saveAs(blob, fileName);
                        }
                    }

                    if (!oneFile) {
                        if (sendApud) {
                            downloadPromises.push(downloadFile(response, 4, setContratoFile));
                            downloadPromises.push(downloadFile(response, 5, setApudFile));
                        } else {
                            downloadPromises.push(downloadFile(response, 3, setContratoFile));
                        }
                    }

                    await Promise.all(downloadPromises);

                    // Mostrar mensaje de éxito y actualizar el estado
                    toast.success(t("¡Archivos descargados correctamente!"));
                    setStep1Loading(false);

                    // Si hay un número de negocio de gastos, avanzar al siguiente paso
                    if (numeroNegocioGastos !== '' && numeroNegocioGastos !== null && numeroNegocioGastos !== undefined) {
                        setStep(2);
                    }
                } catch (error) {
                    handleError(error, setStep1Loading);
                    console.log(error);
                    toast.error(t("Ha ocurrido un error inesperado"));
                    setStep1Loading(false);
                }
            }).catch((error) => {
                handleError(error, setStep1Loading);
                console.log(error);
                toast.error(t("Ha ocurrido un error inesperado"));
                setStep1Loading(false);
            });
        }
    }

    useEffect(() => {
        const totalGastos = (
            (Number(autoFill.gastos_notaria_gastos) || 0) * 0.5 +
            (Number(autoFill.gastos_registro_gastos) || 0) +
            (Number(autoFill.gastos_gestoria_gastos) || 0) +
            (Number(autoFill.gastos_tasacion_gastos) || 0)
        ).toFixed(2); // Redondea a 2 decimales

        setAutoFill(prevState => ({ ...prevState, total_gastos: totalGastos }));
    }, [autoFill.gastos_notaria_gastos, autoFill.gastos_registro_gastos, autoFill.gastos_gestoria_gastos, autoFill.gastos_tasacion_gastos]);

    const handleChangeFechaInicioPrestamo = (e) => {
        const selectedDate = new Date(e.target.value);
        const limitDate = new Date('2020-01-01');
        const apudCheck = new Date('2017-01-01');
        if (selectedDate > limitDate) {
            alert('La fecha no puede ser mayor que el 1 de enero de 2020');
            return;
        }
        if (selectedDate > apudCheck) {
            if (window.confirm('¿Quieres enviar el APUD?')) {
                setSendApud(true);
            } else {
                setSendApud(false);
            }
        }
        if (selectedDate < apudCheck) {
            setSendApud(true);
        }

        setAutoFill(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    return (
        <>
            {step === 1 && (
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4 mb-3">
                        <h2 className="col-span-2 sm:col-span-2 mt-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            {!demandaGastos ?
                                <>
                                    {t("🖋  Rellenando documento de ")}
                                    <span className=" underline decoration-primary-500">{t("Gastos Extrajudiciales")}</span>
                                </>
                                :
                                <>
                                    {t("🖋  Rellenando documento de ")}
                                    <span className=" underline decoration-primary-500">{t("Demanda Gastos")}</span>
                                </>
                            }
                        </h2>
                        <div className="w-full md:w-auto flex flex-col md:flex-row items-stretch md:items-center justify-end  flex-shrink-0">
                            <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300" onClick={(e) => { e.preventDefault(); setCurrentStep(1) }}>
                                {t("Volver atrás")}
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        {/* Campo que sea un checkbox que ponga Demanda gastos */}
                        <div className="col-span-2 sm:col-span-2">
                            <label className="flex items-center cursor-pointer text-sm font-medium text-gray-700 dark:text-gray-400" htmlFor="demanda_gastos">
                                <span className="mr-3">{t("Demanda de gastos")}</span>
                                <div className="relative">
                                    <input disabled={step1Loading} type="checkbox" id="demanda_gastos" name="demanda_gastos" autoComplete="demanda_gastos" className="sr-only peer" onChange={(e) => setDemandaGastos(e.target.checked)} />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:bg-blue-600"></div>
                                    <div className="absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full transition-all duration-200 peer-checked:left-6"></div>
                                </div>
                            </label>
                        </div>
                        {demandaGastos && (
                            <div className="col-span-2 sm:col-span-2">
                                <label className="flex items-center cursor-pointer text-sm font-medium text-gray-700 dark:text-gray-400" htmlFor="solo_demanda_gastos">
                                    <span className="mr-3">{t("¿Generar sólo el archivo de demanda?")}</span>
                                    <div className="relative">
                                        <input disabled={step1Loading} type="checkbox" id="solo_demanda_gastos" name="solo_demanda_gastos" autoComplete="solo_demanda_gastos" className="sr-only peer" onChange={(e) => setOneFile(e.target.checked)} />
                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:bg-blue-600"></div>
                                        <div className="absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full transition-all duration-200 peer-checked:left-6"></div>
                                    </div>
                                </label>
                            </div>
                        )}
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="cantidad_personas_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Cantidad de personas")} *</label>
                            <select disabled={step1Loading} id="cantidad_personas_gastos" name="cantidad_personas_gastos" autoComplete="cantidad_personas_gastos" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={(e) => { setCantidadPersonasGE(e.target.value) }}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                        </div>
                        {demandaGastos ? (
                            <>
                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="dni_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">DNI *</label>
                                    <input
                                        disabled={step1Loading}
                                        type="text"
                                        value={autoFill.dni_gastos}
                                        name="dni_gastos"
                                        id="dni_gastos"
                                        autoComplete="dni_gastos"
                                        className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="DNI"
                                        required
                                        onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}
                                        onBlur={async (e) => {
                                            if (validacionDNIoNIF(e.target.value)) {
                                                const datos = await buscarDNIEnBaserow(e.target.value, 'Gastos');
                                                if (datos.length > 0) {
                                                    if (datos[1].length == 1 || datos[1].length == 0) {

                                                        // Rellenar el formulario con los datos
                                                        setAutoFill({
                                                            ...autoFill,
                                                            banco_gastos: datos[1]?.['Banco'],
                                                            codigo_postal_gastos: datos[0]?.['Codigo Postal'],
                                                            dni_gastos: datos[0]?.['DNI'],
                                                            telefono_gastos: datos[0]?.['Telefono'],
                                                            direccion_gastos: datos[0]?.['Direccion'],
                                                            email_gastos: datos[0]?.['Email'],
                                                            provincia_gastos: datos[0]?.['Provincia'],
                                                            fecha_inicio_prestamo_gastos: datos[1]?.['Fecha Inicio Prestamo'],
                                                            fecha_extrajudicial_gastos: datos[1]?.['Fecha Reclamacion Extrajudicial'],
                                                            nombre_y_apellidos_gastos: datos[0]?.['Nombre Y Apellidos'],
                                                            notario_gastos: datos[1]?.['Notario'],
                                                            gastos_gestoria_gastos: datos[1]?.['Gastos Gestoria'],
                                                            gastos_notaria_gastos: datos[1]?.['Gastos Notaria'],
                                                            gastos_registro_gastos: datos[1]?.['Gastos Registro'],
                                                            gastos_tasacion_gastos: datos[1]?.['Gastos Tasacion'],
                                                            numero_negocio_gastos: datos[1]?.['Numero Negocio'],

                                                        });
                                                        handleChangeFechaInicioPrestamo({ target: { name: 'fecha_inicio_prestamo_gastos', value: datos[1]?.['Fecha Inicio Prestamo'] } });
                                                    }
                                                    if (datos[1].length > 1) {
                                                        setOpen(true);
                                                        //Juntar los datos de datos[0] con cada uno de los datos de datos[1]
                                                        let datosCompletos = []
                                                        datos[1].forEach((element) => {
                                                            let { Email, ...rest } = element; // Excluir Email de datos[1] (provisional) de cada elemento
                                                            datosCompletos.push({ ...datos[0], ...rest }) // Combinar datos[0] con el resto de cada elemento
                                                        })
                                                        setData(datosCompletos);
                                                    }
                                                }
                                            } else {
                                                toast.error(t("El DNI introducido no es válido"));
                                            }
                                        }}                     >
                                    </input>
                                </div>
                                <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasGE == 2 ? "block" : "none" }}>
                                    <label htmlFor="dni2_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">DNI 2 *</label>
                                    <input
                                        disabled={step1Loading}
                                        type="text"
                                        value={autoFill.dni2_gastos}
                                        name="dni2_gastos"
                                        id="dni2_gastos"
                                        autoComplete="dni2_gastos"
                                        className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="DNI 2"
                                        onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}
                                        onBlur={async (e) => {
                                            if (validacionDNIoNIF(e.target.value)) {
                                                const datos = await buscarDNIEnBaserow(e.target.value, 'Gastos');

                                                if (datos.length > 0) {

                                                    if (datos[1].length == 1 || datos[1].length == 0) {
                                                        // Rellenar el formulario con los datos
                                                        setAutoFill({
                                                            ...autoFill,
                                                            dni2_gastos: datos[0]?.['DNI'],
                                                            telefono2_gastos: datos[0]?.['Telefono'],
                                                            nombre_y_apellidos2_gastos: datos[0]?.['Nombre Y Apellidos'],
                                                        });
                                                    }
                                                    if (datos[1].length > 1) {
                                                        setOpen(true);
                                                        //Juntar los datos de datos[0] con cada uno de los datos de datos[1]
                                                        let datosCompletos = []
                                                        datos[1].forEach((element) => {
                                                            let { Email, ...rest } = element; // Excluir Email de cada elemento
                                                            datosCompletos.push({ ...datos[0], ...rest }) // Combinar datos[0] con el resto de cada elemento
                                                        })
                                                        setData(datosCompletos);
                                                    }
                                                }
                                            } else {
                                                toast.error(t("El DNI introducido no es válido"));
                                            }
                                        }}
                                    >
                                    </input>
                                </div>
                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="nombre_y_apellidos_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Nombre y apellidos")} *</label>
                                    <input disabled={step1Loading} value={autoFill.nombre_y_apellidos_gastos} type="text" name="nombre_y_apellidos_gastos" id="nombre_y_apellidos_gastos" autoComplete="nombre_y_apellidos_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Nombre y Apellidos")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                                </div>
                                <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasGE == 2 ? "block" : "none" }}>
                                    <label htmlFor="nombre_y_apellidos2_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Nombre y apellidos 2")}</label>
                                    <input disabled={step1Loading} value={autoFill.nombre_y_apellidos2_gastos} type="text" name="nombre_y_apellidos2_gastos" id="nombre_y_apellidos2_gastos" autoComplete="nombre_y_apellidos2_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} placeholder={t("Nombre y Apellidos 2")}></input>
                                </div>

                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="direccion_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Dirección")} *</label>
                                    <input disabled={step1Loading} ref={inputRef} value={autoFill.direccion_gastos} type="text" name="direccion_gastos" id="direccion_gastos" autoComplete="direccion_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Dirección")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                                </div>
                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="codigo_postal_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Código Postal")} *</label>
                                    <input disabled={step1Loading} ref={postalCodeRef} value={autoFill.codigo_postal_gastos} type="text" name="codigo_postal_gastos" id="codigo_postal_gastos" autoComplete="codigo_postal_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} placeholder={t("46011")} required></input>
                                </div>
                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="provincia_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Provincia")} *</label>
                                    <input disabled={step1Loading} ref={provinciaRef} value={autoFill.provincia_gastos} type="text" name="provincia_gastos" id="provincia_gastos" autoComplete="provincia_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} placeholder={t("Valencia")} required></input>
                                </div>
                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="numero_negocio_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Número de negocio")} </label>
                                    <input disabled={step1Loading} value={autoFill.numero_negocio_gastos} type="text" name="numero_negocio_gastos" id="numero_negocio_gastos" autoComplete="numero_negocio_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={handleInputChange}></input>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="numero_negocio_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Número de negocio")} </label>
                                    <input disabled={step1Loading} value={autoFill.numero_negocio_gastos} type="text" name="numero_negocio_gastos" id="numero_negocio_gastos" autoComplete="numero_negocio_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={handleInputChange}></input>
                                </div>
                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="dni_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">DNI *</label>
                                    <input
                                        disabled={step1Loading}
                                        type="text"
                                        value={autoFill.dni_gastos}
                                        name="dni_gastos"
                                        id="dni_gastos"
                                        autoComplete="dni_gastos"
                                        className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="DNI"
                                        required
                                        onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}
                                        onBlur={async (e) => {
                                            if (validacionDNIoNIF(e.target.value)) {
                                                const datos = await buscarDNIEnBaserow(e.target.value, 'Gastos');
                                                if (datos.length > 0) {

                                                    if (datos[1].length == 1 || datos[1].length == 0) {
                                                        // Rellenar el formulario con los datos
                                                        setAutoFill({
                                                            ...autoFill,
                                                            banco_gastos: datos[1][0]?.['Banco'], 
                                                            codigo_postal_gastos: datos[0]?.['Codigo Postal'],
                                                            dni_gastos: datos[0]?.['DNI'],
                                                            telefono_gastos: datos[0]?.['Telefono'],
                                                            direccion_gastos: datos[0]?.['Direccion'],
                                                            email_gastos: datos[0]?.['Email'],
                                                            provincia_gastos: datos[0]?.['Provincia'],
                                                            fecha_inicio_prestamo_gastos: datos[1][0]?.['Fecha Inicio Prestamo'],
                                                            fecha_extrajudicial_gastos: datos[1][0]?.['Fecha Reclamacion Extrajudicial'],
                                                            nombre_y_apellidos_gastos: datos[0]['Nombre Y Apellidos'],
                                                            notario_gastos: datos[1][0]?.['Notario'], 
                                                            gastos_gestoria_gastos: datos[1][0]?.['Gastos Gestoria'], 
                                                            gastos_notaria_gastos: datos[1][0]?.['Gastos Notaria'], 
                                                            gastos_registro_gastos: datos[1][0]?.['Gastos Registro'], 
                                                            gastos_tasacion_gastos: datos[1][0]?.['Gastos Tasacion'], 
                                                            numero_negocio_gastos: datos[1][0]?.['Numero Negocio'], 
                                                        });
                                                        handleChangeFechaInicioPrestamo({ target: { name: 'fecha_inicio_prestamo_gastos', value: datos[1][0]?.['Fecha Inicio Prestamo'] } });
                                                    }
                                                }
                                                if (datos[1].length > 1) {
                                                    setOpen(true);
                                                    //Juntar los datos de datos[0] con cada uno de los datos de datos[1]
                                                    let datosCompletos = []
                                                    datos[1].forEach((element) => {
                                                        let { Email, ...rest } = element; // Excluir Email de cada elemento
                                                        datosCompletos.push({ ...datos[0], ...rest }) // Combinar datos[0] con el resto de cada elemento
                                                    })
                                                    setData(datosCompletos);
                                                }
                                            } else {
                                                toast.error(t("El DNI introducido no es válido"));
                                            }
                                        }}
                                    >
                                    </input>
                                </div>
                                <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasGE == 2 ? "block" : "none" }}>
                                    <label htmlFor="dni2_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">DNI 2 *</label>
                                    <input
                                        disabled={step1Loading}
                                        type="text"
                                        value={autoFill.dni2_gastos}
                                        name="dni2_gastos"
                                        id="dni2_gastos"
                                        autoComplete="dni2_gastos"
                                        className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="DNI 2"
                                        onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}
                                        onBlur={async (e) => {
                                            if (validacionDNIoNIF(e.target.value)) {
                                                const datos = await buscarDNIEnBaserow(e.target.value, 'Gastos');

                                                if (datos.length > 0) {

                                                    if (datos[1].length == 1 || datos[1].length == 0) {
                                                        //Juntar los datos de datos[0] con el de datos[1]
                                                        let datosCompletos = { ...datos[0], ...datos[1][0] }
                                                        // Rellenar el formulario con los datos
                                                        setAutoFill({
                                                            ...autoFill,
                                                            dni2_gastos: datos[0]['DNI'],
                                                            telefono2_gastos: datos[0]['Telefono'],
                                                            nombre_y_apellidos2_gastos: datos[0]['Nombre Y Apellidos'],
                                                        });
                                                    }
                                                    if (datos[1].length > 1) {
                                                        setOpen(true);
                                                        //Juntar los datos de datos[0] con cada uno de los datos de datos[1]
                                                        let datosCompletos = []
                                                        datos[1].forEach((element) => {
                                                            let { Email, ...rest } = element; // Excluir Email de cada elemento
                                                            datosCompletos.push({ ...datos[0], ...rest }) // Combinar datos[0] con el resto de cada elemento
                                                        })
                                                        setData(datosCompletos);
                                                    }
                                                }
                                            } else {
                                                toast.error(t("El DNI introducido no es válido"));
                                            }
                                        }}                           >
                                    </input>
                                </div>
                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="nombre_y_apellidos_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Nombre y apellidos")} *</label>
                                    <input disabled={step1Loading} value={autoFill.nombre_y_apellidos_gastos} type="text" name="nombre_y_apellidos_gastos" id="nombre_y_apellidos_gastos" autoComplete="nombre_y_apellidos_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Nombre y Apellidos")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                                </div>
                                <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasGE == 2 ? "block" : "none" }}>
                                    <label htmlFor="nombre_y_apellidos2_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Nombre y apellidos 2")}</label>
                                    <input disabled={step1Loading} value={autoFill.nombre_y_apellidos2_gastos} type="text" name="nombre_y_apellidos2_gastos" id="nombre_y_apellidos2_gastos" autoComplete="nombre_y_apellidos2_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} placeholder={t("Nombre y Apellidos 2")}></input>
                                </div>
                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="direccion_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Dirección")} *</label>
                                    <input disabled={step1Loading} ref={inputRef} value={autoFill.direccion_gastos} type="text" name="direccion_gastos" id="direccion_gastos" autoComplete="direccion_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Dirección")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                                </div>
                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="codigo_postal_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Código Postal")} *</label>
                                    <input disabled={step1Loading} ref={postalCodeRef} value={autoFill.codigo_postal_gastos} type="text" name="codigo_postal_gastos" id="codigo_postal_gastos" autoComplete="codigo_postal_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} placeholder={t("46011")} required></input>
                                </div>
                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="provincia_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Provincia")} *</label>
                                    <input disabled={step1Loading} ref={provinciaRef} value={autoFill.provincia_gastos} type="text" name="provincia_gastos" id="provincia_gastos" autoComplete="provincia_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} placeholder={t("Valencia")} required></input>
                                </div>
                            </>
                        )}

                        {(!oneFile || !demandaGastos) && (
                            <>
                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="email_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Email *</label>
                                    <input disabled={step1Loading} value={autoFill.email_gastos} type="email" name="email_gastos" id="email_gastos" autoComplete="email_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                                </div>

                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="telefono_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Teléfono")} *</label>
                                    <input disabled={step1Loading} value={autoFill.telefono_gastos} type="text" name="telefono_gastos" id="telefono_gastos" autoComplete="telefono_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}
                                        onBlur={(e) => { if (!validacionTelefono(e.target.value)) { alert("El teléfono no es válido") } }}
                                    />
                                </div>

                                <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasGE == 2 ? "block" : "none" }}>
                                    <label htmlFor="telefono2_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Teléfono 2")}</label>
                                    <input disabled={step1Loading} type="text" value={autoFill.telefono2_gastos} name="telefono2_gastos" id="telefono2_gastos" autoComplete="telefono2_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}></input>
                                </div>
                            </>
                        )}
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="nprotocolo_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Nº de Protocolo")} *</label>
                            <input disabled={step1Loading} value={autoFill.nprotocolo_gastos} type="text" name="nprotocolo_gastos" id="nprotocolo_gastos" autoComplete="nprotocolo_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('Nº de Protocolo')} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                        </div>
                        {demandaGastos && (
                            <div className="col-span-2 sm:col-span-2">
                                <label htmlFor="clausula_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Cláusula")} *</label>
                                <input disabled={step1Loading} value={autoFill.clausula_gastos} type="text" name="clausula_gastos" id="clausula_gastos" autoComplete="clausula_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('Cláusula')} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                                <label htmlFor="clausula_texto_gastos" className="block text-sm mt-5 font-medium text-gray-700 dark:text-gray-400">{t("Texto de la cláusula")} *</label>
                                <textarea disabled={step1Loading} value={autoFill.clausula_texto_gastos} type="text" name="clausula_texto_gastos" id="clausula_texto_gastos" autoComplete="clausula_texto_gastos" className="mt-1 h-20 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('Texto de la cláusula')} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></textarea>
                                {/* Input listado tipo_adiquisicion_gastos */}
                                <label htmlFor="tipo_adquisicion_gastos" className="block text-sm mt-5 font-medium text-gray-700 dark:text-gray-400">{t("Destino del Préstamo")} *</label>
                                <textarea
                                    disabled={step1Loading}
                                    value={autoFill.tipo_adquisicion_gastos}
                                    id="tipo_adquisicion_gastos"
                                    name="tipo_adquisicion_gastos"
                                    autoComplete="tipo_adquisicion_gastos"
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    required
                                    onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}
                                >
                                </textarea>
                            </div>
                        )}
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="fecha_inicio_prestamo_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Fecha de inicio del préstamo")} *</label>
                            <input
                                disabled={step1Loading}
                                value={autoFill.fecha_inicio_prestamo_gastos}
                                type="date"
                                name="fecha_inicio_prestamo_gastos"
                                id="fecha_inicio_prestamo_gastos"
                                autoComplete="fecha_inicio_prestamo_gastos"
                                className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder={t("Fecha de nacimiento")}
                                required
                                min="1900-01-01"
                                onChange={handleChangeFechaInicioPrestamo}
                                max={"2020-01-01"}
                            />
                        </div>
                        {demandaGastos && (
                            <div className="col-span-2 sm:col-span-2">
                                <label htmlFor="fecha_extrajudicial_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Fecha reclamo extrajudicial")}</label>
                                <input disabled={step1Loading} value={autoFill.fecha_extrajudicial_gastos} type="date" name="fecha_extrajudicial_gastos" id="fecha_extrajudicial_gastos" autoComplete="fecha_extrajudicial_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Fecha de nacimiento")} min="1900-01-01" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} max={new Date().toISOString().split("T")[0]}></input>
                            </div>
                        )}
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="notario_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Notario")} *</label>
                            <input disabled={step1Loading} value={autoFill.notario_gastos} type="text" name="notario_gastos" id="notario_gastos" autoComplete="notario_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Catalunya, D. Fernando de Salas Moreno")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                        </div>

                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="banco_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Nombre del banco")} *</label>
                            <select onChange={handleBankChange} disabled={step1Loading} value={autoFill.banco_gastos} id="banco_gastos" name="banco_gastos" autoComplete="banco_gastos" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                                <option disabled value="">{t('Selecciona un banco')}</option>
                                {companies.map((company) => (
                                    <option key={company.name} value={company.name}>{company.name}</option>
                                ))}
                            </select>
                        </div>

                        {demandaGastos && (
                            <div className="col-span-2 sm:col-span-2">
                                <label htmlFor="datos_banco_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Datos del banco")} *</label>
                                <input value={selectedBank ? `CIF ${selectedBank.cif}, con domicilio social en ${selectedBank.address}` : ''} disabled={step1Loading} type="text" name="datos_banco_gastos" id="datos_banco_gastos" autoComplete="datos_banco_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Datos del banco")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                            </div>
                        )}
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="gastos_notaria_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Gastos de Notaría (100%)")}</label>
                            <input disabled={step1Loading} type="number" value={autoFill.gastos_notaria_gastos} name="gastos_notaria_gastos" id="gastos_notaria_gastos" autoComplete="gastos_notaria_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" min={0} step="0.01"
                                onInput={(e) => {
                                    if (e.target.value < 0) {
                                        e.target.value = 0;
                                    } else {
                                        setAutoFill({ ...autoFill, [e.target.name]: e.target.value })
                                    }
                                }}>
                            </input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="gastos_registro_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Gastos de Registro")} </label>
                            <input disabled={step1Loading} type="number" value={autoFill.gastos_registro_gastos} name="gastos_registro_gastos" id="gastos_registro_gastos" autoComplete="gastos_registro_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" step="0.01" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="gastos_gestoria_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Gastos de Gestoría")} </label>
                            <input disabled={step1Loading} type="number" value={autoFill.gastos_gestoria_gastos} name="gastos_gestoria_gastos" id="gastos_gestoria_gastos" autoComplete="gastos_gestoria_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" step="0.01" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="gastos_tasacion_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Gastos de Tasación")} </label>
                            <input disabled={step1Loading} type="number" value={autoFill.gastos_tasacion_gastos} name="gastos_tasacion_gastos" id="gastos_tasacion_gastos" autoComplete="gastos_tasacion_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" step="0.01" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}></input>
                            {autoFill.gastos_tasacion_gastos > 0 && demandaGastos && (
                                <>
                                    <label htmlFor="tasacion_bloque_gastos" className="block text-sm mt-5 font-medium text-gray-700 dark:text-gray-400">{t("Bloque tasación")} *</label>
                                    <textarea
                                        className="mt-5 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Tasación"
                                        name="tasacion_bloque_gastos"
                                        id="tasacion_bloque_gastos"
                                        value={`Honorarios profesionales por los servicios de tasación el inmueble: ${autoFill.gastos_tasacion_gastos}€ como se acredita mediante el documento adjunto al presente escrito en el ${autoFill.documento_tasacion_gastos}`}
                                        onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}
                                        required
                                    ></textarea>
                                </>
                            )}
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="porcentaje_cobro_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Porcentaje de cobro")} *</label>
                            <input disabled={step1Loading} type="number" value={autoFill.porcentaje_cobro_gastos} name="porcentaje_cobro_gastos" id="porcentaje_cobro_gastos" autoComplete="porcentaje_cobro_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                            <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">{t("Cambiar cuando sea distinto al 12%")}</p>
                        </div>
                        {demandaGastos && (
                            <div className="col-span-2 sm:col-span-2">
                                <label htmlFor="total_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Total de gastos")} *</label>
                                <input disabled={true} type="number" value={autoFill.total_gastos} name="total_gastos" id="total_gastos" autoComplete="total_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" step="0.01" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                            </div>
                        )}
                        {demandaGastos && (
                            <>
                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="juzgado_especifico_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Juzgado Específico")} *</label>
                                    <textarea
                                        disabled={step1Loading}
                                        value={autoFill.juzgado_especifico_gastos}
                                        type="text"
                                        name="juzgado_especifico_gastos"
                                        id="juzgado_especifico_gastos"
                                        autoComplete="juzgado_especifico_gastos"
                                        className="mt-1 h-20 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder={t("Juzgado Específico")}
                                        onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="documento_notaria_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Documento notaria")} </label>
                                    <input disabled={step1Loading} type="text" value={autoFill.documento_notaria_gastos} name="documento_notaria_gastos" id="documento_notaria_gastos" autoComplete="documento_notaria_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Documento nº 3")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} ></input>
                                </div>

                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="documento_registro_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Documento registro")} </label>
                                    <input disabled={step1Loading} type="text" value={autoFill.documento_registro_gastos} name="documento_registro_gastos" id="documento_registro_gastos" autoComplete="documento_registro_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Documento nº 4")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} ></input>
                                </div>

                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="documento_gestoria_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Documento gestoria")} </label>
                                    <input disabled={step1Loading} type="text" value={autoFill.documento_gestoria_gastos} name="documento_gestoria_gastos" id="documento_gestoria_gastos" autoComplete="documento_gestoria_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Documento nº 5")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} ></input>
                                </div>

                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="documento_reclamacion_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Documento reclamación")} </label>
                                    <input disabled={step1Loading} type="text" value={autoFill.documento_reclamacion_gastos} name="documento_reclamacion_gastos" id="documento_reclamacion_gastos" autoComplete="documento_reclamacion_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Documento nº 6")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} ></input>
                                </div>

                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="documento_extrajudicial_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Documento extrajudicial")} </label>
                                    <input disabled={step1Loading} type="text" value={autoFill.documento_extrajudicial_gastos} name="documento_extrajudicial_gastos" id="documento_extrajudicial_gastos" autoComplete="documento_extrajudicial_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Documento nº 7")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} ></input>
                                </div>

                                <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="documento_tasacion_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Documento tasación")} </label>
                                    <input disabled={step1Loading} type="text" value={autoFill.documento_tasacion_gastos} name="documento_tasacion_gastos" id="documento_tasacion_gastos" autoComplete="documento_tasacion_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Documento nº 8")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} ></input>
                                </div>
                            </>
                        )}
                        {(!demandaGastos || (demandaGastos && !oneFile)) && (
                            <>
                                <div className="col-span-2 sm:col-span-2">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">DNI Front</label>
                                    <input disabled={step1Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="dni_front_gastos" id="dni_front_gastos" type="file"></input>
                                </div>
                                <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasGE == 2 ? "block" : "none" }}>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">DNI Front 2</label>
                                    <input disabled={step1Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="dni_front2_gastos" id="dni_front2_gastos" type="file"></input>
                                </div>
                                <div className="col-span-2 sm:col-span-2">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">DNI Back</label>
                                    <input disabled={step1Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="dni_back_gastos" id="dni_back_gastos" type="file"></input>
                                </div>
                                <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasGE == 2 ? "block" : "none" }}>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">DNI Back 2</label>
                                    <input disabled={step1Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="dni_back2_gastos" id="dni_back2_gastos" type="file"></input>
                                </div>
                                {/* Archivos con opción a adicionar un archivo más */}
                                <FileInputWithCheckbox name="inotaria_gastos" id="inotaria_gastos" label={t("Imagen Notaría")} disabled={step1Loading} />
                                <FileInputWithCheckbox name="iregistro_gastos" id="iregistro_gastos" label={t("Imagen Registro")} disabled={step1Loading} />
                                <FileInputWithCheckbox name="igestoria_gastos" id="igestoria_gastos" label={t("Imagen Gestoría")} disabled={step1Loading} />
                                <FileInputWithCheckbox name="itasacion_gastos" id="itasacion_gastos" label={t("Imagen Tasación")} disabled={step1Loading} />
                            </>
                        )}
                        <div className={"col-span-2 sm:col-span-2 " + (step1Loading ? "hidden" : "")}>
                            <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300">
                                {t("Siguiente")}
                            </button>
                        </div>
                        <div className={"col-span-2 sm:col-span-2 " + (step1Loading ? "" : "hidden")}>
                            <button disabled type="button" className={"w-full justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"}>
                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg>
                                {t('Cargando...')}
                            </button>
                        </div>
                    </div>
                </form >
            )}
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-50 inset-0 overflow-y-auto sm:px-0 md:px-8 lg:px-16 xl:px-32 2xl:px-64"
                    onClose={() => setOpen(false)}
                >
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 w-full h-full bg-gray-500 bg-opacity-75 transition-opacity dark:bg-gray-900 dark:bg-opacity-75" />
                        </Transition.Child>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >

                            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full dark:bg-gray-800 dark:text-gray-200">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 dark:bg-gray-800">
                                    <div className="overflow-x-auto rounded-lg">
                                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                                            <thead className="text-xs  text-gray-700 uppercase bg-gray-50 dark:bg-gray-800 dark:text-gray-200">
                                                <tr>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap"></th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">DNI</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Nombre Y Apellidos</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Email</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Telefono</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Dirección</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Código Postal</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Fecha Inicio Prestamo</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Fecha Reclamacion Extrajudicial</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Notario</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Provincia</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Banco</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Numero Negocio</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Numero Protocolo</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Gastos Notaria</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Gastos Registro</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Gastos Gestoria</th>
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">Gastos Tasacion</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-gray-700 rounded-xl">
                                                {data.map((item, index) => (
                                                    <tr key={index} onClick={() => setSelectedRow(item)} className={selectedRow === item ? 'bg-blue-100 dark:bg-gray-700' : 'border-b dark:border-gray-700 hover:bg-gray-50 cursor-pointer dark:hover:bg-gray-700'}>
                                                        {/* Hacer un radio button que se ilumine en azul cuando esté seleccionada la row */}
                                                        <th scope="row" className="px-4 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap flex items-center justify-center">
                                                            <input type="radio" checked={selectedRow === item} className="text-primary-600" readOnly />
                                                        </th>
                                                        <th scope="row" className="px-4 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">{item['DNI']}</th>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Nombre Y Apellidos']}</td>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Email']}</td>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Telefono']}</td>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Direccion']}</td>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Codigo Postal']}</td>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Fecha Inicio Prestamo']}</td>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Fecha Reclamacion Extrajudicial']}</td>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Notario']}</td>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Provincia']}</td>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Banco']}</td>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Numero Negocio']}</td>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Numero Protocolo']}</td>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Gastos Notaria']}</td>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Gastos Registro']}</td>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Gastos Gestoria']}</td>
                                                        <td className="px-4 py-3 whitespace-nowrap dark:text-gray-400">{item['Gastos Tasacion']}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse dark:bg-gray-800">
                                        <button
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => {
                                                setOpen(false);
                                                setNumeroNegocioGastos(selectedRow['Numero Negocio']);
                                                setAutoFill({
                                                    ...autoFill,
                                                    dni_gastos: selectedRow['DNI'],
                                                    email_gastos: selectedRow['Email'],
                                                    telefono_gastos: selectedRow['Telefono'],
                                                    fecha_inicio_prestamo_gastos: selectedRow['Fecha Inicio Prestamo'],
                                                    fecha_extrajudicial_gastos: selectedRow['Fecha Reclamacion Extrajudicial'],
                                                    notario_gastos: selectedRow['Notario'],
                                                    nombre_y_apellidos_gastos: selectedRow['Nombre Y Apellidos'],
                                                    direccion_gastos: selectedRow['Direccion'],
                                                    codigo_postal_gastos: selectedRow['Codigo Postal'],
                                                    nprotocolo_gastos: selectedRow['Numero Protocolo'],
                                                    provincia_gastos: selectedRow['Provincia'],
                                                    banco_gastos: selectedRow['Banco'],
                                                    numero_negocio_gastos: selectedRow['Numero Negocio'],
                                                    gastos_notaria_gastos: selectedRow['Gastos Notaria'],
                                                    gastos_registro_gastos: selectedRow['Gastos Registro'],
                                                    gastos_gestoria_gastos: selectedRow['Gastos Gestoria'],
                                                    gastos_tasacion_gastos: selectedRow['Gastos Tasacion'],
                                                });
                                                handleChangeFechaInicioPrestamo({ target: { name: 'fecha_inicio_prestamo_gastos', value: selectedRow['Fecha Inicio Prestamo'] } });
                                            }}
                                        >
                                            Seleccionar
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            {
                step === 2 && (
                    <FormGastos2 setStep={setStep} setCurrentStep={setCurrentStep} step1Loading={step1Loading} apudFile={apudFile} contratoFile={contratoFile} formValues={formValues} hubspotData={hubspotData} />
                )
            }
        </>
    )
}

FormGastos.propTypes = {
    step1Loading: propTypes.bool.isRequired,
    setStep1Loading: propTypes.func.isRequired,
    setCurrentStep: propTypes.func.isRequired,
}
