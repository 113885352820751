import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { useAuth } from "../hooks/useAuth";
import { DocumentManager } from "../components/DocumentManager";
import { FormGastos } from "../components/forms/FormGastos";
import { FormPrima } from "../components/forms/FormPrima";
import { Tooltip } from 'react-tooltip'
import { useTranslation } from "react-i18next";


export const GenerarDocumentos = () => {
    const { t, i18n } = useTranslation();
    const { userPublicData } = useAuth();
    const { correduria } = useAuth();
    const [notAllowed, setNotAllowed] = useState(true);
    const [currentStep, setCurrentStep] = useState(1);
    const [step1Loading, setStep1Loading] = useState(false);
    const [step2Loading, setStep2Loading] = useState(false);
    const [theme, setTheme] = useState(document.documentElement.classList.contains('dark') ? 'light' : 'dark');


    document.title = "Coventia Office | Generar Documentos";

    useEffect(() => {
        if (correduria.name == 'coventia') {
            setNotAllowed(false);
        }
    }, [correduria.name]);

    useEffect(() => {
        const classList = document.documentElement.classList;
        const mutationObserver = new MutationObserver(() => setTheme(classList.contains('dark') ? 'light' : 'dark'));

        mutationObserver.observe(document.documentElement, { attributes: true, attributeFilter: ['class'] });

        return () => mutationObserver.disconnect();
    }, []);

    return (
        <>
            {notAllowed &&
                <div className="flex justify-center items-center h-96">
                    <div className="flex flex-col justify-center items-center">
                        <div className="flex flex-col justify-center items-center">
                            <XCircleIcon className="w-12 h-12 text-gray-400 dark:text-gray-600" />
                            <span className="text-gray-400 dark:text-gray-600">{t('No tienes permisos para acceder a esta página')}</span>
                        </div>
                    </div>
                </div>
            }
            {!notAllowed &&
                <>
                    <nav className="flex mb-2" aria-label="Breadcrumb">
                        <ol className="inline-flex items-center space-x-1 md:space-x-3">
                            <li className="inline-flex items-center">
                                <Link to="/" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-primary-600 dark:text-gray-400 dark:hover:text-white">
                                    <svg className="w-3 h-3 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    Coventia Office
                                </Link>
                            </li>
                            <li className="inline-flex items-center">
                                <Link className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-primary-600 dark:text-gray-400 dark:hover:text-white" onClick={() => { setCurrentStep(1) }}>
                                    <svg className="w-3 h-3 mr-2.5 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    {t('Generar Documentos')}
                                </Link>
                            </li>
                            {/* Esto de abajo solo se debe mostrar si currentStep es 2 o 3 */}
                            <li aria-current="page" style={{ display: currentStep == 2 || currentStep == 3 ? "block" : "none" }}>
                                <div className="flex items-center">
                                    <svg className="w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">{currentStep == 2 ? t("Gastos Extrajudiciales") : t("Prima Única")}</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                    <div className={"max-w-full mx-4 py-14 sm:mx-auto sm:px-6 md:px-8 lg:px-10 xl:px-80 " + (currentStep == 1 ? "" : "hidden")}>
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <h2 className="col-span-2 sm:col-span-2 mt-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">👋&nbsp;&nbsp;¡{t('Hola')} {userPublicData.first_name}! {t('¿Qué documento quieres rellenar ahora?')}</h2>
                            <div className={"mt-4 col-span-2 sm:col-span-2 " + (step1Loading ? "hidden" : "")}>
                                <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300" onClick={() => { setCurrentStep(2) }}>
                                    {t('Documentos de Gastos')}
                                </button>
                            </div>
                            <div className={"col-span-2 sm:col-span-2 " + (step1Loading ? "hidden" : "")}>
                                <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300" onClick={() => { setCurrentStep(3) }}>
                                    {t('Documento de Prima Única')}
                                </button>
                            </div>
                            <Tooltip id="gestion_text" />
                            <div data-dial-init className="fixed end-6 bottom-6 group">
                                <div data-tooltip-variant={theme} data-tooltip-place="left" data-tooltip-id="gestion_text" data-tooltip-content={t("Gestión de documentos")} id="speed-dial-menu-default" className="flex flex-col items-center mb-4 space-y-2">
                                    <button type="button" data-dial-toggle="speed-dial-menu-default" aria-controls="speed-dial-menu-default" aria-expanded="false" className="flex items-center justify-center text-white bg-blue-700 rounded-full w-14 h-14 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800" onClick={() => { setCurrentStep(5) }}>
                                        <svg className="w-6 h-6 text-white transition-transform group-hover:rotate-45" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M18 7.5h-.423l-.452-1.09.3-.3a1.5 1.5 0 0 0 0-2.121L16.01 2.575a1.5 1.5 0 0 0-2.121 0l-.3.3-1.089-.452V2A1.5 1.5 0 0 0 11 .5H9A1.5 1.5 0 0 0 7.5 2v.423l-1.09.452-.3-.3a1.5 1.5 0 0 0-2.121 0L2.576 3.99a1.5 1.5 0 0 0 0 2.121l.3.3L2.423 7.5H2A1.5 1.5 0 0 0 .5 9v2A1.5 1.5 0 0 0 2 12.5h.423l.452 1.09-.3.3a1.5 1.5 0 0 0 0 2.121l1.415 1.413a1.5 1.5 0 0 0 2.121 0l.3-.3 1.09.452V18A1.5 1.5 0 0 0 9 19.5h2a1.5 1.5 0 0 0 1.5-1.5v-.423l1.09-.452.3.3a1.5 1.5 0 0 0 2.121 0l1.415-1.414a1.5 1.5 0 0 0 0-2.121l-.3-.3.452-1.09H18a1.5 1.5 0 0 0 1.5-1.5V9A1.5 1.5 0 0 0 18 7.5Zm-8 6a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"max-w-full mx-4 py-14 sm:mx-auto sm:px-6 md:px-8 lg:px-10 xl:px-60 -mt-2 " + (currentStep == 2 ? "" : "hidden")}>
                        <FormGastos step1Loading={step1Loading} setStep1Loading={setStep1Loading} setCurrentStep={setCurrentStep} />
                    </div>
                    <div className={"max-w-full mx-4 py-14 sm:mx-auto sm:px-6 md:px-8 lg:px-10 xl:px-60 -mt-2 " + (currentStep == 3 ? "" : "hidden")}>
                        <FormPrima step2Loading={step2Loading} setStep2Loading={setStep2Loading} setCurrentStep={setCurrentStep} />
                    </div>
                    {/* <div className={"max-w-full mx-4 py-14 sm:mx-auto sm:px-6 md:px-8 lg:px-10 xl:px-10 " + (currentStep == 4 ? "" : "hidden")}>
                    <div className="flex flex-col items-center justify-center mt-12">
                        <svg className="w-12 h-12 text-gray-800 dark:text-white mb-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        <h3 className="text-2xl font-bold text-gray-800 dark:text-white">Solicitud enviada correctamente</h3>
                        <p className="text-gray-600 dark:text-gray-400">En breve el cliente recibirá por email los documentos para firmar</p>
                    </div>
                </div> */}
                    <div className={"max-w-full mx-4 py-14 sm:mx-auto sm:px-6 md:px-8 lg:px-10 xl:px-60 -mt-2 " + (currentStep == 5 ? "" : "hidden")}>
                        {/* Creamos un bloque donde van a ir dos columnas */}
                        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4 mb-3">
                            <h2 className="col-span-2 sm:col-span-2 mt-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">⚙️&nbsp;&nbsp;<span className=" underline decoration-primary-500">{t('Gestión de plantillas')}</span> {t('del generador de documentos')}</h2>
                            <div className="w-full md:w-auto flex flex-col md:flex-row items-stretch md:items-center justify-end  flex-shrink-0">
                                {/* Aquí deben ir un botón para crear un nuevo usuario */}
                                <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300" onClick={(e) => { e.preventDefault(); setCurrentStep(1) }}>
                                    {t('Volver atrás')}
                                </button>
                            </div>
                        </div>
                        <DocumentManager />
                    </div>
                </>
            }
        </>
    );
}