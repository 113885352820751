import React, { useEffect, useState } from 'react'
import { createClient } from '@supabase/supabase-js';
import toast from 'react-hot-toast';
import { supabaseClient } from '../../../config/supabase-clients';

const supabase = supabaseClient

export const ManageVacation = () => {

    const [records, setRecords] = useState([]);

    const holidays = [
        new Date('2024-01-01'), // Año Nuevo
        new Date('2024-01-06'), // Epifanía del Señor
        new Date('2024-03-19'), // San José (Comunidad Valenciana)
        new Date('2024-04-01'), // Lunes de Pascua (Comunidad Valenciana)
        new Date('2024-05-01'), // Fiesta del Trabajo
        new Date('2024-08-15'), // Asunción de la Virgen
        new Date('2024-10-09'), // Día de la Comunidad Valenciana
        new Date('2024-10-12'), // Fiesta Nacional de España
        new Date('2024-11-01'), // Todos los Santos
        new Date('2024-12-06'), // Día de la Constitución Española
        new Date('2024-12-08'), // Inmaculada Concepción
        new Date('2024-12-25'), // Navidad
        new Date('2024-12-31'), // Nochevieja
        new Date('2025-01-01'), // Año Nuevo
    ];
    const isHoliday = (date) => {
        return holidays.some(holiday =>
            holiday.getDate() === date.getDate() &&
            holiday.getMonth() === date.getMonth() &&
            holiday.getFullYear() === date.getFullYear()
        );
    };

    const countBusinessDays = (start, end) => {
        let count = 0;
        let currentDate = new Date(start);

        while (currentDate <= end) {
            const dayOfWeek = currentDate.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6 && !isHoliday(currentDate)) {
                count++;
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return count;
    };

    //useEffect para llamar a los datos de supabase dfe la tabla vacation que tengan el estado pendiente
    useEffect(() => {
        const fetchRecords = async () => {
            const { data, error } = await supabase
                .from('vacations')
                .select('*')
                .eq('status', 'Pendientes');

            if (error) {
                return;
            }

            if (data) {
                // Formatear data
                const formattedData = data.map(record => ({
                    id: record.id,
                    user_id: record.user_id, // Asegúrate de que user_id esté presente
                    startDate: new Date(record.start_date),
                    endDate: new Date(record.end_date),
                }));

                // Conseguir username a partir del user_id buscando en la tabla users
                const { data: usersData, error: usersError } = await supabase
                    .from('users')
                    .select('user_id, username') // Asegúrate de seleccionar user_id también
                    .in('user_id', formattedData.map(record => record.user_id));

                if (usersError) {
                    return;
                }

                if (usersData) {
                    formattedData.forEach(record => {
                        const user = usersData.find(user => user.user_id === record.user_id);
                        if (user) {
                            record.user = user.username;
                        }
                    });
                }

                setRecords(formattedData);
            } else {
                toast.error('No se encontraron registros');
                setRecords([]);

            }
        };

        fetchRecords();
    }, []);

    //Accion de aceptar vacaciones
    const handleAccept = async (id) => {
        const { error } = await supabase
            .from('vacations')
            .update({ status: 'Aceptadas' })
            .eq('id', id);

        if (error) {
            console.log('ERROR AL ACEPTAR LA VACACIÓN', error);
        } else {
            console.log('Vacación aceptada');
            // Actualiza el estado o vuelve a buscar los registros
            toast.success('Vacaciones aceptadas');
            setRecords(records.filter(record => record.id !== id));
        }
    };


    //Accion de rechazar vacaciones
    const handleReject = async (id) => {
        const { error } = await supabase
            .from('vacations')
            .update({ status: 'Rechazadas' })
            .eq('id', id);

        if (error) {
            console.log('ERROR AL RECHAZAR LA VACACIÓN', error);
        } else {
            console.log('Vacación rechazada ', error);
            // Actualiza el estado o vuelve a buscar los registros
            toast.success('Vacaciones rechazadas');
            setRecords(records.filter(record => record.id !== id));

        }
    };


    return (
        <div>
            <div className="overflow-x-auto overflow-y-hidden shadow-md relative mt-4 rounded-lg">
                <table
                    className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                >
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope='col' className='py-3 px-4 md:px-6'>Usuario</th>
                            <th scope="col" className="py-3 px-4 md:px-6">Fecha de inicio</th>
                            <th scope="col" className="py-3 px-4 md:px-6">Fecha de fin</th>
                            <th scope="col" className="py-3 px-4 md:px-6">Días solicitados</th>
                            <th scope="col" className="py-3 px-4 md:px-6">Acciones</th>
                        </tr>
                    </thead>

                    <tbody>
                        {records.length > 0 ? (
                            records.map((record, index) => (
                                <tr key={index} className="bg-white dark:bg-gray-800">
                                    <td className="py-3 px-4 md:px-6">{record.user}</td>
                                    <td className="py-3 px-4 md:px-6">{new Date(record.startDate).toLocaleDateString()}</td>
                                    <td className="py-3 px-4 md:px-6">{new Date(record.endDate).toLocaleDateString()}</td>
                                    <td className="py-3 px-4 md:px-6">{countBusinessDays(record.startDate, record.endDate)}</td>
                                    <td className="py-3 px-4 md:px-6">
                                        <button className="text-xs text-white bg-blue-500 p-1 mx-2 rounded-md dark:bg-blue-600" onClick={() => handleAccept(record.id)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                            </svg>

                                        </button>
                                        <button className="text-xs text-white bg-red-500 p-1 mx-2 rounded-md dark:bg-red-600" onClick={() => handleReject(record.id)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                            </svg>

                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center py-4">No hay vacaciones por gestionar</td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </div>
        </div>
    )
}
