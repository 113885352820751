/* eslint-disable no-unused-vars */
import { useEffect, useState, Fragment } from "react";
import toast from 'react-hot-toast';
import { useAuth } from "../hooks/useAuth";
import { createClient } from "@supabase/supabase-js";
import { NavBreadcrumb } from "../components/NavBreadcrumb";
import { useTranslation } from 'react-i18next';
import supabaseClient from '../utils/supabaseInit';
import Colorful from '@uiw/react-color-colorful';
import { Transition, Dialog } from '@headlessui/react';
import { GetUsuarioXCorreduria } from "../data/GetUsuarioXCorreduria";
import { XCircleIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import {
    DndContext,
    closestCenter,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export const Profile = () => {
    const { user, userPublicData, correduria } = useAuth();
    const [changeProfileButtonLoading, setChangeProfileButtonLoading] = useState(false);
    const [changePasswordButtonLoading, setChangePasswordButtonLoading] = useState(false);
    const [changeWhatsappButtonLoading, setChangeWhatsappButtonLoading] = useState(false);
    const [createTemplateModalOpen, setCreateTemplateModalOpen] = useState(false);
    const [editTemplateModalOpen, setEditTemplateModalOpen] = useState(false);
    const [deleteTemplateModalOpen, setDeleteTemplateModalOpen] = useState(false);
    const [creatingButtonLoading, setCreatingButtonLoading] = useState(false);
    const [editingButtonLoading, setEditingButtonLoading] = useState(false);
    const [usuarioxcorreduria, setUsuarioxcorreduria] = useState({});
    const [deletingButtonLoading, setDeletingButtonLoading] = useState(false);
    const [reorderingButtonLoading, setReorderingButtonLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const [selectedColor, setSelectedColor] = useState('');
    const [userColor, setUserColor] = useState('')
    const [whatsappTemplates, setWhatsappTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [notificationCheck, setNotificationCheck] = useState(userPublicData.whatsapp_notification)
    const [desktopNotificationCheck, setDesktopNotificationCheck] = useState(userPublicData.desktop_notification)
    const baseURL = `https://${import.meta.env.VITE_COVENTIA_WA_URL}`;
    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
        localStorage.setItem("language", event.target.value);

    };
    document.title = "Coventia Office | Perfil";

    useEffect(() => {
        getColor()
        getWhatsappTemplates()
        
        const getUsuarioXCorreduria = async () => {
            const usuarioxcorreduria = await GetUsuarioXCorreduria();
            setUsuarioxcorreduria(usuarioxcorreduria);
        }
        getUsuarioXCorreduria();
    }, []);


    const handleChangeProfile = async (event) => {
        event.preventDefault()
        setChangeProfileButtonLoading(true);
        var firstName = event.target["first-name"].value
        var email = event.target.email.value
        try {
            const supabaseClient = createClient(
                'https://uoclqlfzojdjrhcqhgef.supabase.co',
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
            );
            const { data, error } = await supabaseClient.auth.updateUser({
                email: email,
            })
            if (error) {
                console.log(error)
                if (error.status == 429) {
                    toast.error(t("Solo puedes realizar esto cada 60 segundos"))
                } else {
                    toast.error(t("Ha ocurrido un error inesperado"))
                }
                setChangeProfileButtonLoading(false);
            } else {
                await supabaseClient.from('users').update({ first_name: firstName }).eq('user_id', user.id).select();
                userPublicData.first_name = firstName
                toast.success(t("Los datos han sido guardados. Si has cambiado el email, deberás confirmar el antiguo y el nuevo email para que el cambio se aplique."), {
                    duration: 10000,
                    position: 'top-center',
                })
                setChangeProfileButtonLoading(false);
            }
        } catch (error) {
            console.log(error)
            toast.error(t("Ha ocurrido un error inesperado"))
            setChangeProfileButtonLoading(false);
        }
    }

    const handleChangePassword = async (event) => {
        event.preventDefault()
        setChangePasswordButtonLoading(true);
        var password = event.target.password.value
        var confirmPassword = event.target["confirm-password"].value
        // Comprobar que las contraseñas coinciden
        if (password != confirmPassword) {
            toast.error(t("Las contraseñas no coinciden"))
            setChangePasswordButtonLoading(false);
            return false;
        }
        // Comprueba que tenga al menos 8 caracteres
        if (password.length < 8) {
            toast.error(t("La contraseña debe tener al menos 8 caracteres"))
            setChangePasswordButtonLoading(false);
            return false;
        }
        try {
            const supabaseClient = createClient(
                'https://uoclqlfzojdjrhcqhgef.supabase.co',
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
            );
            const { data, error } = await supabaseClient.auth.updateUser({
                password: password,
            })
            if (error) {
                console.log(error)
                if (error.status == 429) {
                    toast.error(t("Solo puedes realizar esto cada 60 segundos"))
                } else {
                    toast.error(t("Ha ocurrido un error inesperado"))
                }
                setChangePasswordButtonLoading(false);
            } else {
                toast.success(t("La contraseña ha sido cambiada correctamente"), {
                    duration: 10000,
                    position: 'top-center',
                })
                setChangePasswordButtonLoading(false);
            }
            console.log(error)
        } catch (error) {
            console.log(error)
            toast.error(t("Ha ocurrido un error inesperado"))
            setChangePasswordButtonLoading(false);
        }
    }
    const handleChangeWhatsapp = async (event) => {
        try {
          event.preventDefault();
          setChangeWhatsappButtonLoading(true);
          const updates = {
            color: selectedColor,
            whatsapp_notification: notificationCheck,
            desktop_notification: desktopNotificationCheck
          };
      
          // Antes de nada debemos comprobar si el usuario ha cambiado las notificaciones de escritorio
          if (desktopNotificationCheck != userPublicData.desktop_notification) {
            // Si las ha activado
            if (desktopNotificationCheck) {
              // Pedir permiso para las notificaciones
              if (Notification.permission !== "granted") {
                const permission = await Notification.requestPermission();
                if (permission !== "granted") {
                  // Si el usuario no concede permisos, detener el proceso
                  setChangeWhatsappButtonLoading(false);
                  setDesktopNotificationCheck(false);
                  toast.error("No se concedieron permisos de notificación");
                  return;
                }
              }
              // Si las ha activado y se concedieron permisos
              if ("serviceWorker" in navigator) {
                console.log("SERVICE WORKER FOUND");
                const handleServiceWorker = async () => {
                  const register = await navigator.serviceWorker.register("/sw.js");
      
                  const subscription = await register.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: "BNZ73carnkPi66zy4mablJbQ3ph--zzxygQ78g1LB9EumeGFiy0Dp_DFyrm3afLtyso6tqmTV0SG1xa4EzCxl_k",
                  });

                  try {
        
                    const res = await fetch("https://wa.api.coventia.es/subscribe?user_id=" + userPublicData.user_id + "&username=" + userPublicData.first_name, {
                        method: "POST",
                        body: JSON.stringify(subscription),
                        headers: {
                        "content-type": "application/json",
                        },
                    });
                    // En caso de error al suscribirse a las notificaciones mostramos una notificación y cambiamos el estado de las notificaciones de escritorio ademas de eliminarlo de updates
                        if (!res.ok) {
                            toast.error("No se ha podido suscribir a las notificaciones de escritorio");
                            setDesktopNotificationCheck(false);
                            delete updates.desktop_notification;
                        }
                    } catch (error) {
                        toast.error("No se ha podido suscribir a las notificaciones de escritorio");
                        setDesktopNotificationCheck(false);
                        delete updates.desktop_notification;
                    }
                };
                await handleServiceWorker();
              }
            } else {
              // Si las ha desactivado
              if ("serviceWorker" in navigator) {
                console.log("SERVICE WORKER FOUND");
                const handleServiceWorker = async () => {
                  const register = await navigator.serviceWorker.register("/sw.js");
                  const subscription = await register.pushManager.getSubscription();
                  if (subscription) {
                    const res = await fetch("https://wa.api.coventia.es/unsubscribe?user_id=" + userPublicData.user_id + "&username=" + userPublicData.first_name, {
                      method: "POST",
                      body: JSON.stringify(subscription),
                      headers: {
                        "content-type": "application/json",
                      },
                    });
                    const data = await res.json();
                    console.log(data);
                  }
                };
                await handleServiceWorker();
              }
            }
          }
      
          // Actualizar la base de datos
          const { data, error } = await supabaseClient
            .from('users')
            .update(updates)
            .match({ user_id: userPublicData.user_id });
      
          setChangeWhatsappButtonLoading(false);
          toast.success(t("La configuración de WhatsApp ha sido cambiada correctamente"), {
            duration: 10000,
            position: 'top-center',
          });
        } catch (error) {
          console.log(error);
          toast.error(t("Ha ocurrido un error inesperado"));
          setChangeWhatsappButtonLoading(false);
        }
      };
      
    function showId() {
        var id = user.id
        var idLength = id.length
        var idFirstPart = id.substring(0, 4)
        var idSecondPart = id.substring(4, idLength)
        var idFinal = idFirstPart + "-" + idSecondPart
        toast(t("Tu ID de usuario es: ") + idFinal, {
            icon: '⚙️',
            duration: 10000,
            position: 'top-center',
        })
    }

    async function changeProfileImage(event) {
        event.preventDefault();
        if (event.target.files[0].size > 2097152) {
            toast.error(t("La imagen no debe pesar más de 2MB"))
            return false;
        }
        const avatarFile = event.target.files[0];
        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        );
        // Cambiar el nombre del archivo a id del usuario + extensión

        var fileExtension = avatarFile.name.split('.').pop();
        var fileName = user.id + "." + fileExtension;

        const { data, error } = await supabaseClient
            .storage
            .from('coventia-office')
            .upload(`profile_pic/${fileName}`, avatarFile, {
                upsert: true,
                cacheControl: 0,
            })
        // Debemos actualizar el campo profile_pic de la tabla users
        if (error) {
            console.log(error)
            if (error.status == 429) {
                toast.error(t("Solo puedes realizar esto cada 60 segundos"))
            } else {
                toast.error(t("Ha ocurrido un error inesperado"))
            }
        } else {
            const { data, error } = await supabaseClient.from('users').update({ profile_pic: 'https://uoclqlfzojdjrhcqhgef.supabase.co/storage/v1/object/public/coventia-office/profile_pic/' + fileName }).eq('user_id', user.id).select();
            if (error) {
                console.log(error)
                if (error.status == 429) {
                    toast.error(t("Solo puedes realizar esto cada 60 segundos"))
                } else {
                    toast.error(t("Ha ocurrido un error inesperado"))
                }
            } else {
                userPublicData.profile_pic = 'https://uoclqlfzojdjrhcqhgef.supabase.co/storage/v1/object/public/coventia-office/profile_pic/' + fileName
                document.getElementById("profile_picture").src = userPublicData.profile_pic
                document.getElementById("navbar_profile_picture").src = userPublicData.profile_pic
                toast.success(t("La imagen de perfil ha sido cambiada correctamente. Es posible que debas recargar la página para ver los cambios."), {
                    duration: 10000,
                    position: 'top-center',
                })
            }
        }

    }
    const getCurretColor = (color) => {
        setSelectedColor(color)
    }
    const getColor = async () => {
        try {

            const { data } = await supabaseClient.auth.getSession();
            const token = data.session.access_token
            const axiosInstance = axios.create({
                baseURL,
                params: {
                    token,
                },
                headers:{
                    authorization:token
                }
            });
            const response = await axiosInstance.get('/getuserinfo');
            setSelectedColor(response.data.color)
            setUserColor(response.data.color)
        } catch (error) {
            console.error('Error en la llamada a la API:', error);
        }
    }
    const getWhatsappTemplates = async () => {
        try {
            const { data } = await supabaseClient
                .from('whatsapp_templates')
                .select('*')
                .order('order', { ascending: true });
    
            setWhatsappTemplates(data);
        } catch (error) {
            console.error('Error en la llamada a la API:', error);
        }
    };
    const hadleNotificationCheck = () => {
        setNotificationCheck(!notificationCheck)
    }

    const hadleDesktopNotificationCheck = () => {
        setDesktopNotificationCheck(!desktopNotificationCheck)
    }

    const handleDragEnd = (event) => {
        const { active, over } = event;
    
        if (active.id !== over.id) {
            setWhatsappTemplates((items) => {
                const oldIndex = items.findIndex((item) => item.id === active.id);
                const newIndex = items.findIndex((item) => item.id === over.id);
    
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };

    const SortableItem = ({ id, template, index, setEditTemplate, setTemplateModal, setDeleteTemplate, setDeleteTemplateModal, activeId }) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            setActivatorNodeRef, // Esta referencia se usará para el icono de arrastre
            transform,
            transition,
        } = useSortable({ id });

        const isActive = id === activeId;
        const style = {
            transform: CSS.Transform.toString({
                ...transform,
                scaleX: isActive ? 1.05 : 1,
                scaleY: isActive ? 1.05 : 1,
                zIndex: isActive ? 1000 : 'auto'
            }),
            transition: transition ?? 'transform 150ms ease',
            boxShadow: isActive ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none'
        };

        return (
            <div
                ref={setNodeRef}
                style={style}
                {...attributes}
                className={`shadow-md flex items-center justify-between p-4 bg-white border border-gray-200 dark:border-gray-600 rounded-lg dark:bg-gray-800 mt-2 cursor-default ${isActive ? 'ring-2 ring-blue-500' : ''}`}
            >
                <div className="flex items-center space-x-4">
                    <div ref={setActivatorNodeRef} {...listeners} className="flex items-center cursor-grab">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4h18M3 8h18M3 12h18M3 16h18M3 20h18" />
                        </svg>
                        <p className="text-sm font-medium text-gray-900 dark:text-white">{template.title}</p>
                    </div>
                    <p className="text-sm text-gray-600 dark:text-gray-400">{template.text}</p>
                </div>
                <div className="flex items-center space-x-4">
                    <button className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200" onClick={(e) => { e.preventDefault(); setSelectedTemplate(template); setEditTemplateModalOpen(true) }}>
                        <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"/>
                        </svg>
                    </button>
                    <button className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200" onClick={(e) => { e.preventDefault(); setSelectedTemplate(template); setDeleteTemplateModalOpen(true) }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
        );
    };

    const handleCreateTemplate = async (event) => {
        event.preventDefault();
        setCreatingButtonLoading(true);
        var name = event.target.name.value
        var text = event.target.text.value
        try {
            const { data, error } = await supabaseClient.from('whatsapp_templates').insert([{ name: name, text: text, created_by: user.id }]);
            if (error) {
                console.log(error)
                if (error.status == 429) {
                    toast.error(t("Solo puedes realizar esto cada 60 segundos"))
                } else {
                    toast.error(t("Ha ocurrido un error inesperado"))
                }
                setCreatingButtonLoading(false);
            } else {
                setCreatingButtonLoading(false);
                setCreateTemplateModalOpen(false);
                getWhatsappTemplates()
                toast.success(t("El template ha sido creado correctamente"), {
                    duration: 10000,
                    position: 'top-center',
                })
            }
        } catch (error) {
            console.log(error)
            toast.error(t("Ha ocurrido un error inesperado"))
            setCreatingButtonLoading(false);
        }
    }

    const handleEditTemplate = async (event) => {
        event.preventDefault();
        setEditingButtonLoading(true);
        var name = event.target.name.value
        var text = event.target.text.value
        try {
            const { data, error } = await supabaseClient.from('whatsapp_templates').update({ name: name, text: text }).eq('id', selectedTemplate.id);
            if (error) {
                console.log(error)
                if (error.status == 429) {
                    toast.error(t("Solo puedes realizar esto cada 60 segundos"))
                } else {
                    toast.error(t("Ha ocurrido un error inesperado"))
                }
                setEditingButtonLoading(false);
            } else {
                setEditingButtonLoading(false);
                setEditTemplateModalOpen(false);
                getWhatsappTemplates()
                toast.success(t("El template ha sido editado correctamente"), {
                    duration: 10000,
                    position: 'top-center',
                })
            }
        } catch (error) {
            console.log(error)
            toast.error(t("Ha ocurrido un error inesperado"))
            setEditingButtonLoading(false);
        }
    }

    const handleDeleteTemplate = async (event) => {
        event.preventDefault();
        setDeletingButtonLoading(true);
        try {
            const { data, error } = await supabaseClient.from('whatsapp_templates').delete().eq('id', selectedTemplate.id);
            if (error) {
                console.log(error)
                if (error.status == 429) {
                    toast.error(t("Solo puedes realizar esto cada 60 segundos"))
                } else {
                    toast.error(t("Ha ocurrido un error inesperado"))
                }
                setDeletingButtonLoading(false);
            } else {
                setDeletingButtonLoading(false);
                setDeleteTemplateModalOpen(false);
                getWhatsappTemplates()
                toast.success(t("El template ha sido eliminado correctamente"), {
                    duration: 10000,
                    position: 'top-center',
                })
            }
        } catch (error) {
            console.log(error)
            toast.error(t("Ha ocurrido un error inesperado"))
            setDeletingButtonLoading(false);
        }
    }


    const handleReorderTemplates = async () => {
        setReorderingButtonLoading(true);
        try {
            const updatedTemplates = whatsappTemplates.map((template, index) => ({
                ...template,
                order: index + 1
            }));
    
            setWhatsappTemplates(updatedTemplates);
    
            const { error } = await supabaseClient
                .from('whatsapp_templates')
                .upsert(updatedTemplates, {
                    onConflict: ['id'],
                });
    
            if (error) {
                console.error('Error actualizando el orden en la base de datos:', error);
                toast.error(t("Ha ocurrido un error al guardar el orden"));
            } else {
                toast.success(t("El orden ha sido guardado correctamente"));
            }
            setReorderingButtonLoading(false);
        } catch (error) {
            console.error('Error en la llamada a la API:', error);
            toast.error(t("Ha ocurrido un error inesperado"));
            setReorderingButtonLoading(false);
        }
    };


    return (
        <>
            <NavBreadcrumb currentPage={[{ text: t("Perfil"), url: "" }]} />
            <div className="max-w-full  py-6 sm:mx-auto sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 xl:grid-cols-3 xl:gap-4 dark:bg-gray-900">
                    <div className="mb-4 col-span-full xl:mb-2">
                        <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">{t('Ajustes del usuario')}</h1>
                    </div>
                    <div className="col-span-full xl:col-auto">
                        <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                            <div className="items-center sm:flex xl:block 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
                                <div className="flex flex-col items-center justify-center">
                                    <img id="profile_picture" className="mb-4 w-28 h-28 sm:mb-0 xl:mb-4 2xl:mb-0 rounded-full" src={userPublicData.profile_pic} alt="Profile picture"></img>
                                    <label htmlFor="ppic-file-input" className="w-28 h-28 group hover:bg-gray-200 opacity-60 rounded-full absolute flex justify-center items-center cursor-pointer transition duration-500">
                                        <img className="hidden group-hover:block w-8" src="https://www.svgrepo.com/show/33565/upload.svg" alt="" />
                                    </label>
                                </div>
                                <input onChange={changeProfileImage} type="file" id="ppic-file-input" className="hidden" />
                                <div>
                                    <h3 className="mb-1 text-xl font-bold text-gray-900 dark:text-white">{userPublicData.first_name}</h3>
                                    <div className="mb-4 text-sm text-gray-500 dark:text-gray-400">
                                        <p><b>{t('Grupo')}: </b>{correduria.name}</p>
                                    </div>
                                    <div className="flex items-center space-x-4">
                                        <button onClick={showId} type="button" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                            {t('Ver Id de usuario')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-span-2">
    
                        <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                            <h3 className="mb-4 text-xl font-semibold dark:text-white">{t('Información general')}</h3>
                            <form onSubmit={handleChangeProfile} >
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-6">
                                        <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t('Nombre completo')}</label>
                                        <input defaultValue={userPublicData.first_name} type="text" name="first-name" id="first-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Nombre completo" required></input>
                                    </div>
                                    <div className="col-span-6 sm:col-span-6">
                                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                        <input defaultValue={user.email} type="email" name="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="david@example.com" required></input>
                                    </div>
                                    <div className="col-span-6 sm:col-full">
                                        <button className={"text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 " + (changeProfileButtonLoading ? "hidden" : "")} type="submit">{t('Guardar')}</button>
                                        <button disabled type="button" className={"justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 inline-flex items-center " + (changeProfileButtonLoading ? "" : "hidden")}>
                                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                            </svg>
                                            {t('Guardando...')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                            <h3 className="mb-4 text-xl font-semibold dark:text-white">{t('Cambiar contraseña')}</h3>
                            <form onSubmit={handleChangePassword}>
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t('Nueva contraseña')}</label>
                                        <input data-popover-target="popover-password" data-popover-placement="bottom" type="password" id="password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="••••••••" required></input>
                                        <div data-popover id="popover-password" role="tooltip" className="absolute z-10 invisible inline-block text-sm font-light text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
                                            <div data-popper-arrow></div>
                                        </div>
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t('Confirmar nueva contraseña')}</label>
                                        <input type="password" name="confirm-password" id="confirm-password" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="••••••••" required></input>
                                    </div>
                                    <div className="col-span-6 sm:col-full">
                                        <button className={"text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 " + (changePasswordButtonLoading ? "hidden" : "")} type="submit">{t('Cambiar')}</button>
                                        <button disabled type="button" className={"justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 inline-flex items-center " + (changePasswordButtonLoading ? "" : "hidden")}>
                                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                            </svg>
                                            {t('Cambiando contraseña...')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {correduria.name == 'coventia' && userPublicData.whatsapp == true &&
                            <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                                <h3 className="mb-4 text-xl font-semibold dark:text-white">{t('Ajustes de WhatsApp')}</h3>
                                <form onSubmit={handleChangeWhatsapp}>
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 sm:col-span-3">

                                            <div>
                                                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{t('Notificaciones In-App')}</span>
                                                <label className="items-center mb-5 cursor-pointer">
        
        
                                                    <input type="checkbox" checked={notificationCheck}
                                                        onChange={hadleNotificationCheck} value="" className="sr-only peer" ></input>
        
        
                                                    <div className="relative ml-3 mt-3 w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        
                                                </label>
                                            </div>

                                            <div className="mt-4">
                                                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{t('Notificaciones de escritorio')}</span>
                                                <label className="items-center mb-5 cursor-pointer">
        
        
                                                    <input type="checkbox" checked={desktopNotificationCheck}
                                                        onChange={hadleDesktopNotificationCheck} value="" className="sr-only peer" ></input>
        
        
                                                    <div className="relative ml-3 mt-3 w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{t('Color')}</span>
                                            <div className="w-[50%] mt-2 ml-2 flex">
                                                {userColor === ''
                                                    ?
                                                    <div id='popover1' role="status">
                                                        <svg id='popover1' aria-hidden="true" className="w-[20px] h-[20px] ml-2 mr-2 mb-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
    
                                                    </div>
                                                    :
                                                    <Colorful
                                                        color={selectedColor ? selectedColor : userColor}
                                                        onChange={(color) => { console.log(color.hex); setSelectedColor(color.hex) }}
                                                        disableAlpha={true}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-span-6 sm:col-full">
                                            <button  className={"text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 " + (changeWhatsappButtonLoading ? "hidden" : "")} type="submit">{t('Cambiar')}</button>
                                            <button disabled type="button" className={"justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 inline-flex items-center " + (changeWhatsappButtonLoading ? "" : "hidden")}>
                                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg>
                                                {t('Cambiando ajustes...')}
                                            </button>
                                        </div>
                                        {usuarioxcorreduria.staff == true &&
                                        <div className="col-span-6 sm:col-span-6">
                                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{t('Plantillas')}</span>
                                            <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                                                <SortableContext items={whatsappTemplates.map(template => template.id)} strategy={verticalListSortingStrategy}>
                                                    <div className="mt-4">
                                                        {whatsappTemplates.map((template, index) => (
                                                            <SortableItem key={template.id} id={template.id} template={template} index={index} />
                                                        ))}
                                                    </div>
                                                </SortableContext>
                                            </DndContext>

                                            {/* Dos botones uno al lado del otro, a la izquierda uno mas suave para añadir una plantilla y a la derecha uno mas fuerte para guardar los cambios */}
                                            <div className="flex items-center justify-between mt-4 ms-3">
                                                <button onClick={(e) => { e.preventDefault(); setCreateTemplateModalOpen(true) }} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">{t('Añadir plantilla')}</button>
                                                <button onClick={(e) => { e.preventDefault(); handleReorderTemplates() }} className={`text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800` + (reorderingButtonLoading ? " hidden" : "")}>{t('Guardar orden de las plantillas')}</button>
                                                <button disabled type="button" className={`justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 inline-flex items-center` + (reorderingButtonLoading ? "" : " hidden")}>
                                                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                    </svg>
                                                    {t('Guardando...')}
                                                </button>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </form>
                            </div>}
                        <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                            <div className="items-center sm:flex xl:block 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
                                <div>
                                    <h3 className="mb-4 text-xl font-semibold dark:text-white">{t('Cambiar idioma')}</h3>
                                    <div className="mb-4 text-sm text-gray-500 dark:text-gray-400">
                                    </div>
                                    <select onChange={changeLanguage} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={i18n.language}>
                                        <option value="es">{t('Español')}</option>
                                        <option value="en">{t('Inglés')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Transition.Root show={createTemplateModalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" initialFocus={open} onClose={() => { setCreateTemplateModalOpen(false); }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl dark:bg-gray-800">
                                    <div>
                                        <form onSubmit={handleCreateTemplate}>
                                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-gray-800">
                                                <div className="sm:flex sm:items-start">
                                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10 dark:bg-primary-400">
                                                        <PencilSquareIcon className="h-6 w-6 text-primary-600 dark:text-white" aria-hidden="true" />
                                                    </div>
                                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                                            {t('Crear plantilla')}
                                                        </Dialog.Title>
                                                        <div className="mt-2">
                                                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                                                {t('Rellena los campos para crear una nueva plantilla de mensaje')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                                    <div className="col-span-2 sm:col-span-2 mb-4 mt-4">
                                                        {/* Usuario */}
                                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Nombre de la plantilla')} *</label>
                                                        <input type="text" name="name" id="name" autoComplete="name" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Nombre de la plantilla" required></input>
                                                    </div>
                                                    <div className="col-span-2 sm:col-span-2 mb-4 mt-4">
                                                        {/* Mensaje */}
                                                        <label htmlFor="text" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Mensaje')} *</label>
                                                        <textarea rows="5" type="text" name="text" id="text" autoComplete="text" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Mensaje" required></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-gray-700">
                                                <button
                                                    type="submit"
                                                    className={"inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto dark:bg-primary-500 dark:hover:bg-primary-400 " + (creatingButtonLoading ? "hidden" : "")}
                                                >
                                                    {t('Crear plantilla')}
                                                </button>
                                                <button disabled type="button" className={"inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto dark:bg-primary-500 dark:hover:bg-primary-400 items-center " + (creatingButtonLoading ? "" : "hidden")}>
                                                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                    </svg>
                                                    {t('Creando plantilla...')}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 dark:border-gray-900 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
                                                    onClick={() => { setCreateTemplateModalOpen(false); }}
                                                >
                                                    {t('Cancelar')}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={editTemplateModalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" initialFocus={open} onClose={() => { setEditTemplateModalOpen(false); }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl dark:bg-gray-800">
                                    <div>
                                        <form onSubmit={handleEditTemplate}>
                                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-gray-800">
                                                <div className="sm:flex sm:items-start">
                                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10 dark:bg-primary-400">
                                                        <PencilSquareIcon className="h-6 w-6 text-primary-600 dark:text-white" aria-hidden="true" />
                                                    </div>
                                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                                            {
                                                                t('Editar plantilla')
                                                            }
                                                        </Dialog.Title>
                                                        <div className="mt-2">
                                                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                                                {t('Rellena los campos para editar la plantilla de mensaje')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                                    <div className="col-span-2 sm:col-span-2 mb-4 mt-4">
                                                        {/* Usuario */}
                                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Nombre de la plantilla')} *</label>
                                                        <input type="text" name="name" id="name" autoComplete="name" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Nombre de la plantilla" required defaultValue={selectedTemplate.name}></input>
                                                    </div>
                                                    <div className="col-span-2 sm:col-span-2 mb-4 mt-4">
                                                        {/* Mensaje */}
                                                        <label htmlFor="text" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Mensaje')} *</label>
                                                        <textarea rows="5" type="text" name="text" id="text" autoComplete="text" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Mensaje" required defaultValue={selectedTemplate.text}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-gray-700">
                                                <button
                                                    type="submit"
                                                    className={"inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto dark:bg-primary-500 dark:hover:bg-primary-400 " + (editingButtonLoading ? "hidden" : "")}
                                                >
                                                    {t('Editar plantilla')}
                                                </button>
                                                <button disabled type="button" className={"inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto dark:bg-primary-500 dark:hover:bg-primary-400 items-center " + (editingButtonLoading ? "" : "hidden")}>
                                                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                    </svg>
                                                    {t('Editando plantilla...')}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 dark:border-gray-900 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
                                                    onClick={() => { setEditTemplateModalOpen(false); }}
                                                >
                                                    {t('Cancelar')}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>


            <Transition.Root show={deleteTemplateModalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" initialFocus={deleteTemplateModalOpen} onClose={() => { setDeleteTemplateModalOpen(false); }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl dark:bg-gray-800">
                                    <div>
                                        <form onSubmit={handleDeleteTemplate}>
                                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-gray-800">
                                                <div className="sm:flex sm:items-start">
                                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 dark:bg-red-400">
                                                        <XCircleIcon className="h-6 w-6 text-red-600 dark:text-white" aria-hidden="true" />
                                                    </div>
                                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                                            {t('Eliminar plantilla')}
                                                        </Dialog.Title>
                                                        <div className="mt-2">
                                                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                                                Vas a eliminar la plantilla <span className="font-semibold">{selectedTemplate.name}</span><br></br>
                                                                {t('¿Estás seguro de que quieres eliminar la siguiente plantilla?')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-gray-700">
                                                <button
                                                    type="submit"
                                                    className={"inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto dark:bg-red-500 dark:hover:bg-red-400 " + (deletingButtonLoading ? "hidden" : "")}
                                                >
                                                    {t('Eliminar plantilla')}
                                                </button>
                                                <button disabled type="button" className={"inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto dark:bg-red-500 dark:hover:bg-red-400 items-center " + (deletingButtonLoading ? "" : "hidden")}>
                                                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                    </svg>
                                                    {t('Eliminando plantilla...')}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
                                                    onClick={() => { setDeleteTemplateModalOpen(false) }}
                                                >
                                                    {t('Cancelar')}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}