/* eslint-disable no-unused-vars */
import React, { useEffect, useState, Fragment, useRef, useCallback, memo } from "react";
import { Transition, Dialog } from '@headlessui/react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { motion, AnimatePresence } from "framer-motion";
import { useAuth } from "../hooks/useAuth";
import axios from "axios";
import { MagnifyingGlass } from 'react-loader-spinner'
import { NavBreadcrumb } from "../components/NavBreadcrumb";
import { XCircleIcon, UserCircleIcon } from "@heroicons/react/20/solid";
import { GetUsuarioXCorreduria } from "../data/GetUsuarioXCorreduria";
import { TotalProjectsGraph } from "../components/graphs/TotalProjectsGraph";
import { ProjectsByUser } from "../components/graphs/ProjectsByUser";
import { PoliciesCreatedByUser } from "../components/graphs/PoliciesCreatedByUser";
import { Trans, useTranslation } from "react-i18next";
import { debounce, get } from "lodash";
import { json } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";

export const Manager = () => {
    const { t, i18n } = useTranslation();
    const { user, correduria } = useAuth();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState({});
    const [usuarioxcorreduria, setUsuarioxcorreduria] = useState({});
    const [selectedItem, setSelectedItem] = useState({});
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [notAllowed, setNotAllowed] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState({});
    const [deleteCheck, setDeleteCheck] = useState('');
    const [checkboxValue, setCheckboxValue] = useState(false);

    const debouncedSetDeleteCheck = useCallback(
        debounce((value) => setDeleteCheck(value), 400),
        [] // Will recreate if dependencies change
    );

    //Función para obtener los usuarios
    const getUsuarioXCorreduria = useCallback(async () => {
        const usuarioxcorreduria = await GetUsuarioXCorreduria();
        setUsuarioxcorreduria(usuarioxcorreduria);
        if (usuarioxcorreduria.staff == true) {
            setNotAllowed(false);
            const getData = async () => {
                try {
                    const supabaseClient = createClient(
                        'https://uoclqlfzojdjrhcqhgef.supabase.co',
                        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
                    )
                    const { data, error } = await supabaseClient.auth.getSession()
                    const access_token = data.session.access_token
                    // Get backend url from .env
                    let url = 'https://api.office.coventia.es/api/manager/get_users/'
                    if (error) throw error;
                    let headers = {
                        'Authorization': access_token,
                        'Content-Type': 'application/json',
                    }
                    await axios.get(url, { headers: headers }).then((response) => {
                        if (response.status == 200) {
                            setUsers(response.data)
                            setLoading(false);
                        } else {
                            toast.error(t('Ha ocurrido un error inesperado al cargar los datos de la página.'))
                        }
                    }).catch((error) => {
                        toast.error(t('Ha ocurrido un error inesperado al cargar los datos de la página.'))

                    });
                } catch (error) {
                    toast.error(t('Ha ocurrido un error inesperado al cargar los datos de la página.'))

                    console.log(error)
                }
            }
            getData();
        } else {
            setNotAllowed(true);
            setLoading(false);
        }
    }, []);

    async function handleSubmitCreateUser(event) {
        event.preventDefault()
        setButtonLoading(true)
        try {
            var formData = new FormData(event.target);
            formData.set("acceso_total", formData.get("acceso_total") == "on" ? true : false)
            formData.set("enviar_credenciales", formData.get("enviar_credenciales") == "on" ? true : false)
            if (correduria.name == "coventia") {
                formData.set("whatsapp", formData.get("whatsapp") == "on" ? true : false)
            }
            const supabaseClient = createClient(
                'https://uoclqlfzojdjrhcqhgef.supabase.co',
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
            )           
            const { data, error } = await supabaseClient.auth.getSession()
            const access_token = data.session.access_token
            let headers = {
                'Authorization': access_token,
                'Content-Type': 'multipart/form-data',
            }
            await axios.post('https://api.office.coventia.es/api/manager/add', formData, { headers: headers }).then((response) => {
                toast.success(t("Usuario creado correctamente"))
                setButtonLoading(false)
                setUsers([...users, response.data])
                setOpen(false)
            }).catch((error) => {
                if (error.response.data == 'User already registered') {
                    toast.error(t("El usuario ya está registrado"))
                } else {
                    toast.error(t("Ha ocurrido un error inesperado"))
                }
                setButtonLoading(false)
            })
        } catch (error) {
            toast.error(t("Ha ocurrido un error inesperado"))
            setButtonLoading(false)
        }
    }

    // Función para abrir y cerrar los dropdowns individualmente por cada fila
    const toggleDropdown = (id) => {
        setDropDownOpen(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
        for (let key in dropDownOpen) {
            if (key !== id) {
                setDropDownOpen(prevState => ({
                    ...prevState,
                    [key]: false
                }));
            }
        }
    };

    // Función para manejar el botón de eliminar usuario en el Transition de eliminar
    const handleDeleteCheck = (event) => {
        debouncedSetDeleteCheck(event.target.value);
    };

    function getRowDataEdit(row) {
        setSelectedItem(row)
        setEditOpen(true)
    }

    function getRowDataDelete(row) {
        setSelectedItem(row)
        setDeleteOpen(true)
    }

    const formatEditData = (event, selectedItem) => {
        const formData = new FormData(event.target);
        let dataObj = {};
        for (let pair of formData.entries()) {
            if (pair[1] !== '' && pair[1] !== null) {
                dataObj[pair[0]] = pair[1];
            }
        }

        dataObj = {
            ...dataObj,
            acceso_total: formData.get("acceso_total") === "on",
            action: 'edit',
            id: selectedItem.id,
            current_username: selectedItem.username,
            current_email: selectedItem.email,
        };
        if (correduria.name == "coventia") {
            dataObj = {
                ...dataObj,
                whatsapp: formData.get("whatsapp") === "on"
            }
        }

        return JSON.stringify(dataObj);
    }

    const sendEditLambda = async (data) => {
        try {
            await axios.post('https://eoz3awcwxk23iziavpv2glnmxy0umjiv.lambda-url.eu-west-3.on.aws/', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(t("Usuario editado correctamente"));
            setButtonLoading(false);
            setEditOpen(false);
        } catch (error) {
            toast.error(error.response && error.response.data === 'User already registered' ? t("El usuario ya está registrado") : t("Ha ocurrido un error inesperado"));
            setButtonLoading(false);
        }
    }

    const handleSubmitEditUser = async (event) => {
        event.preventDefault();
        setButtonLoading(true);
        const jsonData = formatEditData(event, selectedItem);
        const edit = await sendEditLambda(jsonData);

        const parsedData = JSON.parse(jsonData);

        console.log('parsed DATA' + parsedData.new_username + parsedData.new_email + parsedData.acceso_total)
        const newUsers = users.map((user) => {
            if (user.id === selectedItem.id) {
                return {
                    ...user,
                    username: parsedData.new_username ? parsedData.new_username : user.username,
                    email: parsedData.new_email ? parsedData.new_email : user.email,
                    acceso_total: parsedData.acceso_total ? parsedData.acceso_total : user.acceso_total,
                    whatsapp: parsedData.whatsapp ? parsedData.whatsapp : user.whatsapp
                }
            }
            return user
        })
        setUsers(newUsers)

        getUsuarioXCorreduria();

        setButtonLoading(false);
    }

    const formatDeleteData = (event, selectedItem) => {
        const formData = new FormData(event.target);
        let dataObj = {};
        for (let pair of formData.entries()) {
            if (pair[1] !== '' && pair[1] !== null) {
                dataObj[pair[0]] = pair[1];
            }
        }

        dataObj = {
            ...dataObj,
            action: 'delete',
            id: selectedItem.id,
            current_username: selectedItem.username
        };

        return JSON.stringify(dataObj);
    }

    const sendDeleteLambda = async (data) => {
        try {
            await axios.post('https://eoz3awcwxk23iziavpv2glnmxy0umjiv.lambda-url.eu-west-3.on.aws/', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success("Usuario eliminado correctamente");
            setButtonLoading(false);
            setDeleteOpen(false);
        } catch (error) {
            toast.error(error.response && error.response.data === 'Error al eliminar el usuario' ? "Error al eliminar el usuario" : "Ha ocurrido un error inesperado");
            setButtonLoading(false);
        }
    }

    const handleSubmitDeleteUser = async (event) => {
        event.preventDefault();
        setButtonLoading(true);

        const jsonData = formatDeleteData(event, selectedItem);
        await sendDeleteLambda(jsonData);
        getUsuarioXCorreduria();
    }

    useEffect(() => {
        if (user) {
            getUsuarioXCorreduria();
        }
    }, []);
    
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                getUsuarioXCorreduria();
            }
        };
    
        document.addEventListener('visibilitychange', handleVisibilityChange);
    
        // Asegúrate de eliminar el event listener cuando el componente se desmonte
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [getUsuarioXCorreduria]);

    document.title = "Coventia Office | Manager";

    useEffect(() => {
        console.log(selectedItem)
    }, [selectedItem]);
    

    return (
        <>
            {loading &&
                <div className="flex justify-center items-center h-screen">
                    <div role="status">
                        <svg aria-hidden="true" className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span className="sr-only">{t('Cargando...')}</span>
                    </div>
                </div>
            }
            {!loading &&
                <>
                    {notAllowed &&
                        <div className="flex justify-center items-center h-96">
                            <div className="flex flex-col justify-center items-center">
                                <div className="flex flex-col justify-center items-center">
                                    <XCircleIcon className="w-12 h-12 text-gray-400 dark:text-gray-600" />
                                    <span className="text-gray-400 dark:text-gray-600">{t('No tienes permisos para acceder a esta página')}</span>
                                </div>
                            </div>
                        </div>
                    }
                    {!notAllowed &&
                        <>
                            <NavBreadcrumb currentPage={[{text:"Manager",url:''}]} />
                            <div className="max-w-full mx-4 py-6 sm:mx-auto sm:px-6 lg:px-8 mt-10">
                                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4 mb-3">
                                    <div className="w-full">
                                        <h1 className="text-1xl font-semibold text-gray-900 dark:text-white">{t('Gestor de empleados y usuarios')}</h1>
                                    </div>
                                    <div className="w-full md:w-auto flex flex-col md:flex-row items-stretch md:items-center justify-end  flex-shrink-0">
                                        {/* Aquí deben ir un botón para crear un nuevo usuario */}
                                        <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300" onClick={() => { setOpen(true) }}>
                                            {t('Crear usuario')}
                                        </button>
                                    </div>
                                </div>
                                <div className="relative overflow-x-auto overflow-y-hidden shadow-md sm:rounded-lg">
                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    {t('Usuario')}
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Email
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    {t('Último acceso')}
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    {t('DB Acceso')}
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    {t('Manager')}
                                                </th>
                                                {correduria.name == "coventia" && 
                                                    <th scope="col" className="px-6 py-3">
                                                        {t('WhatsApp Acceso')}
                                                    </th>
                                                }
                                                <th scope="col" className="px-6 py-3">
                                                    {t('Acciones')}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users && users.length == 0 &&
                                                <tr className="bg-white dark:bg-gray-800 dark:border-gray-700">
                                                    <td colSpan="5">
                                                        <div className="flex flex-col justify-center items-center my-8">
                                                            <XCircleIcon className="w-12 h-12 text-gray-400 dark:text-gray-600" />
                                                            <span className="text-gray-400 dark:text-gray-600">{t('No hay resultados')} </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                            {users ? users.map((register) => (
                                                <tr key={register.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        {register.username}
                                                    </th>
                                                    <td className="px-6 py-4">
                                                        {register.email}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {register.last_login == '-' ? "Nunca" : register.last_login}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {register.bbdd_total == true ? <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-500/10 dark:text-red-900 dark:bg-red-200">Acceso total</span> : <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10 dark:text-green-900 dark:bg-primary-200">Acceso a sus proyectos</span>}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {register.staff == true ? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10 dark:text-green-900 dark:bg-primary-200">Sí</span> : <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-500/10 dark:text-red-900 dark:bg-red-200">No</span>}
                                                    </td>
                                                    {correduria.name == "coventia" &&
                                                        <td className="px-6 py-4">
                                                            {register.whatsapp == true ? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10 dark:text-green-900 dark:bg-primary-200">Sí</span> : <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-500/10 dark:text-red-900 dark:bg-red-200">No</span>}
                                                        </td>
                                                    }
                                                    <td className="px-6 py-4 h-30">
                                                        <button onClick={() => toggleDropdown(register.id)} id="dropdownMenuIconHorizontalButton" data-dropdown-toggle="dropdownDotsHorizontal" className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
                                                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                                                                <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                                                            </svg>
                                                        </button>
                                                        {dropDownOpen[register.id] && (
                                                            <AnimatePresence>
                                                                <motion.div
                                                                    initial={{ opacity: 0, y: -10 }}
                                                                    animate={{ opacity: 1, y: 0 }}
                                                                    exit={{ opacity: 0, y: -10 }}
                                                                    transition={{ duration: 0.3 }}
                                                                    className="z-10 mb-5 bg-white divide-y divide-gray-100 rounded-lg shadow w-20 dark:bg-gray-700 dark:divide-gray-600"
                                                                >
                                                                    <ul className="py-2 w-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconHorizontalButton">
                                                                        <li className="cursor-pointer">
                                                                            <a type="button" onClick={() => { getRowDataEdit(register) }} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{t('Editar')}</a>
                                                                        </li>
                                                                        <li className="cursor-pointer">
                                                                            <a type="button" onClick={() => { getRowDataDelete(register) }} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{t('Eliminar')}</a>
                                                                        </li>
                                                                    </ul>
                                                                </motion.div>
                                                            </AnimatePresence>
                                                        )}
                                                    </td>
                                                </tr>
                                            )) :
                                                <>
                                                    <tr className="bg-white dark:bg-gray-800 dark:border-gray-700">
                                                        <td colSpan="5">
                                                            <div className="flex justify-center items-center my-8">
                                                                <MagnifyingGlass
                                                                    visible={true}
                                                                    height="80"
                                                                    width="80"
                                                                    ariaLabel="MagnifyingGlass-loading"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass="MagnifyingGlass-wrapper"
                                                                    glassColor='#deeaff'
                                                                    color='#5b73f9'
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Transition.Root show={open} as={Fragment}>
                                <Dialog as="div" className="relative z-50" initialFocus={open} onClose={() => { setOpen(false); }}>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                    </Transition.Child>

                                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            >
                                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl dark:bg-gray-800">
                                                    <div>
                                                        <form onSubmit={handleSubmitCreateUser}>
                                                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-gray-800">
                                                                <div className="sm:flex sm:items-start">
                                                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10 dark:bg-primary-400">
                                                                        <UserCircleIcon className="h-6 w-6 text-primary-600 dark:text-primary-600" aria-hidden="true" />
                                                                    </div>
                                                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                                                            {t('Crear usuario')}
                                                                        </Dialog.Title>
                                                                        <div className="mt-2">
                                                                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                                                                {t('Rellena los campos para crear un nuevo usuario')}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                                                    <div className="col-span-2 sm:col-span-2 mb-4 mt-4">
                                                                        {/* Usuario */}
                                                                        <label htmlFor="username" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Nombre de usuario')} *</label>
                                                                        <input type="text" name="username" id="username" autoComplete="username" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Nombre de usuario" required></input>
                                                                    </div>
                                                                    <div className="col-span-2 sm:col-span-2 mb-4 mt-4">
                                                                        {/* Nombre completo */}
                                                                        <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Nombre completo')} *</label>
                                                                        <input type="text" name="first_name" id="first_name" autoComplete="first_name" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Nombre completo" required></input>
                                                                    </div>
                                                                    <div className="col-span-2 sm:col-span-2 mb-4">
                                                                        {/* Email */}
                                                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Email *</label>
                                                                        <input type="email" name="email" id="email" autoComplete="email" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email" required></input>
                                                                    </div>
                                                                    <div className="col-span-2 sm:col-span-2 mb-8">
                                                                        {/* Contraseña */}
                                                                        <label htmlFor="contraseña" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Contraseña')} *</label>
                                                                        <input type="text" minLength="8" name="contraseña" id="contraseña" autoComplete="contraseña" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Contraseña" required></input>
                                                                    </div>
                                                                    <div className="col-span-2 sm:col-span-2 mb-4">
                                                                        {/* Enviar credenciales */}
                                                                        <div className="flex items-start">
                                                                            <div className="flex items-center h-5">
                                                                                <input id="enviar_credenciales" name="enviar_credenciales" type="checkbox" className="focus:ring-primary-600 h-4 w-4 text-primary-600 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                                                            </div>
                                                                            <div className="ml-3 text-sm">
                                                                                <label htmlFor="enviar_credenciales" className="font-medium text-gray-700 dark:text-gray-400">{t('Enviar credenciales al usuario por email')}</label>
                                                                                <p className="text-gray-500 dark:text-gray-400">{t('El sistema envía un correo electrónico al usuario con sus credenciales de acceso y un enlace a la plataforma')}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-span-2 sm:col-span-2 mb-4">
                                                                        {/* Acceso total */}
                                                                        <div className="flex items-start">
                                                                            <div className="flex items-center h-5">
                                                                                <input id="acceso_total" name="acceso_total" type="checkbox" className="focus:ring-primary-600 h-4 w-4 text-primary-600 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                                                            </div>
                                                                            <div className="ml-3 text-sm">
                                                                                <label htmlFor="acceso_total" className="font-medium text-gray-700 dark:text-gray-400">{t('Acceso total a la base de datos')}</label>
                                                                                <p className="text-gray-500 dark:text-gray-400">{t('Permite al usuario acceder a todos los proyectos de la base de datos')}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {correduria.name == "coventia" &&
                                                                        <div className="col-span-2 sm:col-span-2 mb-4">
                                                                            {/* Acceso WhatsApp */}
                                                                            <div className="flex items-start">
                                                                                <div className="flex items-center h-5">
                                                                                    <input id="whatsapp" name="whatsapp" type="checkbox" className="focus:ring-primary-600 h-4 w-4 text-primary-600 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                                                                </div>
                                                                                <div className="ml-3 text-sm">
                                                                                    <label htmlFor="whatsapp" className="font-medium text-gray-700 dark:text-gray-400">{t('Acceso a WhatsApp')}</label>
                                                                                    <p className="text-gray-500 dark:text-gray-400">{t('Permite al usuario acceder a la funcionalidad de WhatsApp')}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-gray-700">
                                                                <button
                                                                    type="submit"
                                                                    className={"inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto dark:bg-primary-500 dark:hover:bg-primary-400 " + (buttonLoading ? "hidden" : "")}
                                                                >
                                                                    {t('Crear usuario')}
                                                                </button>
                                                                <button disabled type="button" className={"inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto dark:bg-primary-500 dark:hover:bg-primary-400 items-center " + (buttonLoading ? "" : "hidden")}>
                                                                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                    </svg>
                                                                    {t('Creando usuario...')}
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
                                                                    onClick={() => setOpen(false)}
                                                                >
                                                                    {t('Cancelar')}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </Dialog.Panel>
                                            </Transition.Child>
                                        </div>
                                    </div>
                                </Dialog>
                            </Transition.Root>
                            {/* Transition editar */}
                            <Transition.Root show={editOpen} as={Fragment}>
                                <Dialog as="div" className="relative z-50" initialFocus={editOpen} onClose={() => { setEditOpen(false); setDeleteCheck('') }}>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                    </Transition.Child>

                                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            >
                                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl dark:bg-gray-800">
                                                    <div>
                                                        <form onSubmit={handleSubmitEditUser}>
                                                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-gray-800">
                                                                <div className="sm:flex sm:items-start">
                                                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10 dark:bg-primary-400">
                                                                        <UserCircleIcon className="h-6 w-6 text-primary-600 dark:text-primary-600" aria-hidden="true" />
                                                                    </div>
                                                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                                                            {t('Editar usuario')}
                                                                        </Dialog.Title>
                                                                        <div className="mt-2">
                                                                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                                                                {t('Rellena los campos para editar el usuario')}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <input type="text" style={{ display: "none" }} tabIndex="-1" />

                                                                <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                                                    <div className="col-span-2 sm:col-span-2 mb-4 mt-4">
                                                                        {/* Usuario */}
                                                                        <label htmlFor="current_username" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Nombre de usuario actual')}</label>
                                                                        <input type="text" name="current_username" id="current_username" autoComplete="current_username" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" defaultValue={selectedItem.username} placeholder="Nombre de usuario" disabled required></input>
                                                                    </div>
                                                                    {/* Nuevo usuario */}
                                                                    <div className="col-span-2 sm:col-span-2 mb-4 mt-4">
                                                                        <label htmlFor="new_username" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Nuevo nombre de usuario')}</label>
                                                                        <input type="text" name="new_username" id="new_username" autoComplete="new_username" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Nombre de usuario" ></input>
                                                                    </div>
                                                                    {/* Nuevo email */}
                                                                    <div className="col-span-2 sm:col-span-2 mb-4">
                                                                        <label htmlFor="new_email" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Nuevo email')}</label>
                                                                        <input type="email" name="new_email" id="new_email" autoComplete="new_email" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email" ></input>
                                                                    </div>
                                                                    {/* Nueva contraseña */}
                                                                    <div className="col-span-2 sm:col-span-2 mb-4">
                                                                        <label htmlFor="new_password" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Nueva contraseña')}</label>
                                                                        <input type="text" minLength="8" name="new_password" id="new_password" autoComplete="new_password" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Contraseña" ></input>
                                                                    </div>
                                                                    {/* Acceso total */}
                                                                    <div className="col-span-2 sm:col-span-2 mb-4">
                                                                        <div className="flex items-start">
                                                                            <div className="flex items-center h-5">
                                                                                <input id="acceso_total" defaultChecked={selectedItem.bbdd_total} onChange={e => setCheckboxValue(e.target.checked)} name="acceso_total" type="checkbox" className="focus:ring-primary-600 h-4 w-4 text-primary-600 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                                                            </div>
                                                                            <div className="ml-3 text-sm">
                                                                                <label htmlFor="acceso_total" className="font-medium text-gray-700 dark:text-gray-400">{t('Acceso total a la BBDD')}</label>
                                                                                <p className="text-gray-500 dark:text-gray-400">{t('Permite al usuario acceder a todos los proyectos de la base de datos')}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {correduria.name == "coventia" &&
                                                                        <div className="col-span-2 sm:col-span-2 mb-4">
                                                                            {/* Acceso WhatsApp */}
                                                                            <div className="flex items-start">
                                                                                <div className="flex items-center h-5">
                                                                                    <input id="whatsapp" defaultChecked={selectedItem.whatsapp} name="whatsapp" type="checkbox" className="focus:ring-primary-600 h-4 w-4 text-primary-600 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                                                                </div>
                                                                                <div className="ml-3 text-sm">
                                                                                    <label htmlFor="whatsapp" className="font-medium text-gray-700 dark:text-gray-400">{t('Acceso a WhatsApp')}</label>
                                                                                    <p className="text-gray-500 dark:text-gray-400">{t('Permite al usuario acceder a la funcionalidad de WhatsApp')}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-gray-700">
                                                                <button
                                                                    type="submit"
                                                                    className={"inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto dark:bg-primary-500 dark:hover:bg-primary-400 " + (buttonLoading ? "hidden" : "")}
                                                                >
                                                                    {t('Editar usuario')}
                                                                </button>
                                                                <button disabled type="button" className={"inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto dark:bg-primary-500 dark:hover:bg-primary-400 items-center " + (buttonLoading ? "" : "hidden")}>
                                                                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                    </svg>
                                                                    {t('Editando usuario...')}
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
                                                                    onClick={() => setEditOpen(false)}
                                                                >
                                                                    {t('Cancelar')}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </Dialog.Panel>
                                            </Transition.Child>
                                        </div>
                                    </div>
                                </Dialog>
                            </Transition.Root>

                            {/* Transition eliminar */}
                            <Transition.Root show={deleteOpen} as={Fragment}>
                                <Dialog as="div" className="relative z-50" initialFocus={deleteOpen} onClose={() => { setDeleteOpen(false); }}>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                    </Transition.Child>

                                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            >
                                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl dark:bg-gray-800">
                                                    <div>
                                                        <form onSubmit={handleSubmitDeleteUser}>
                                                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-gray-800">
                                                                <div className="sm:flex sm:items-start">
                                                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10 dark:bg-primary-400">
                                                                        <UserCircleIcon className="h-6 w-6 text-primary-600 dark:text-primary-600" aria-hidden="true" />
                                                                    </div>
                                                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                                                            {t('Eliminar usuario')}
                                                                        </Dialog.Title>
                                                                        <div className="mt-2">
                                                                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                                                                {t('Escribe')} &apos;{selectedItem.username}&apos;  {t('para confirmar la eliminación del usuario')}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                                                    <div className="col-span-2 sm:col-span-2 mb-4 mt-4">
                                                                        {/* Usuario */}
                                                                        <label htmlFor="username" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Nombre de usuario')} *</label>
                                                                        <input type="text" name="username" id="username" autoComplete="username" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={selectedItem.username} onChange={handleDeleteCheck} required></input>
                                                                    </div>
                                                                    <div className="col-span-2 sm:col-span-2 mb-4">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-gray-700">
                                                                <button
                                                                    type="submit"
                                                                    disabled={deleteCheck !== selectedItem.username}
                                                                    className={"inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto " +
                                                                        (buttonLoading ? "hidden " : "") +
                                                                        (deleteCheck !== selectedItem.username ? "bg-gray-400 hover:bg-gray-400 dark:bg-gray-500 dark:hover:bg-gray-500" : "bg-primary-600 hover:bg-primary-500 dark:bg-primary-500 dark:hover:bg-primary-400")
                                                                    }                                                                >
                                                                    {t('Eliminar usuario')}
                                                                </button>
                                                                <button disabled type="button" className={"inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto dark:bg-primary-500 dark:hover:bg-primary-400 items-center " + (buttonLoading ? "" : "hidden")}>
                                                                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                    </svg>
                                                                    {t('Eliminando usuario...')}
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
                                                                    onClick={() => { setDeleteOpen(false); setDeleteCheck('') }}
                                                                >
                                                                    {t('Cancelar')}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </Dialog.Panel>
                                            </Transition.Child>
                                        </div>
                                    </div>
                                </Dialog>
                            </Transition.Root>
                            <div className="max-w-full mx-4 py-2 sm:mx-auto sm:px-6 lg:px-8">
                                <div className="flex flex-col md:flex-row items-center justify-between md:space-y-0 md:space-x-4 p-4">
                                    <div className="w-full">
                                        <h1 className="text-1xl font-semibold text-gray-900 dark:text-white">{t('Estadísticas generales')}</h1>
                                    </div>
                                </div>
                                <div className="max-w-full py-6 sm:mx-auto sm:6 lg:px-2">
                                    <div>
                                        <div className="w-full bg-white rounded-tl-xl rounded-tr-xl rounded-br-xl rounded-bl-xl  shadow dark:bg-gray-800 p-4 md:p-6 mb-10">
                                            <h1 className="mb-5 text-1xl font-semibold text-gray-900 dark:text-white">{t('Proyectos totales (Últimos 5 meses)')}</h1>
                                            <TotalProjectsGraph />
                                        </div>
                                        <div className="w-full bg-white rounded-tl-xl rounded-tr-xl rounded-br-xl rounded-bl-xl  shadow dark:bg-gray-800 p-4 md:p-6 mb-10">
                                            <h1 className="mb-5 text-1xl font-semibold text-gray-900 dark:text-white">{t('Proyectos según usuario / empleado (Últimos 5 meses)')}</h1>
                                            <ProjectsByUser />
                                        </div>
                                        <div className="w-full bg-white rounded-tl-xl rounded-tr-xl rounded-br-xl rounded-bl-xl  shadow dark:bg-gray-800 p-4 md:p-6 mb-10">
                                            <h1 className="mb-5 text-1xl font-semibold text-gray-900 dark:text-white">{t('Pólizas según usuario / empleado (Últimos 5 meses)')}</h1>
                                            <PoliciesCreatedByUser />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </>
    );
}