/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef, Fragment } from "react";
import { Link } from "react-router-dom";
import { NavBreadcrumb } from "../components/NavBreadcrumb";
import { Tooltip } from 'react-tooltip'
import { useAuth } from "../hooks/useAuth";
import axios from "axios";
import { createClient } from "@supabase/supabase-js";
import countries from "../data/countries";
import list_countries from "../data/list_contries";
import companies from "../data/companies";
import { BoltIcon } from "@heroicons/react/20/solid";
import { v4 as uuidv4 } from 'uuid';
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import { Transition, Dialog } from '@headlessui/react';


export const CrearPresupuesto = () => {
    const { t, i18n } = useTranslation();
    const [nacionalidad, setNacionalidad] = useState("España");
    const { correduria } = useAuth();
    const [currentStep, setCurrentStep] = useState(1);
    const [step1Loading, setStep1Loading] = useState(false);
    const [step2Loading, setStep2Loading] = useState(false);
    const [enviarPresupuestoLoading, setEnviarPresupuestoLoading] = useState(false);
    const [response1Data, setResponse1Data] = useState({});
    const [pep, setPep] = useState(false);
    const [bancoBeneficiario, setBancoBeneficiario] = useState(false);
    const [beneficiariosDesignados, setBeneficiariosDesignados] = useState(false);
    const [open, setOpen] = useState(false);
    const [factorData, setFactorData] = useState({});
    const [factorLoading, setFactorLoading] = useState(false);
    const inputRef = useRef();
    const postalCodeRef = useRef();
    const countryList = countries();
    const [theme, setTheme] = useState(document.documentElement.classList.contains('dark') ? 'light' : 'dark');
    const [beneficiariosDesignadosData, setBeneficiariosDesignadosData] = useState([{ nombre: "", dni: "", porcentaje: ""}]);
    const MAX_BENEFICIARIOS = 6;

    document.title = "Coventia Office | Crear Presupuesto";

    useEffect(() => {
        const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
        autocomplete.setComponentRestrictions({
            'country': ['es']
        });
        autocomplete.setFields(['address_component']);

        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            var city = '';
            var country = '';
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (addressType == 'locality') {
                    city = place.address_components[i]['long_name'];
                }
                if (addressType == 'country') {
                    country = place.address_components[i]['long_name'];
                }
            }
            var address = inputRef.current.value;
            var index = address.indexOf(city);
            if (index >= 0) {
                address = address.substr(0, index);
            }
            index = address.indexOf(country);
            if (index >= 0) {
                address = address.substr(0, index);
            }
            inputRef.current.value = address;
        });

        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            var postal_code = '';
            for (var i = 0; i < place.address_components.length; i++) {
                for (var j = 0; j < place.address_components[i].types.length; j++) {
                    if (place.address_components[i].types[j] == "postal_code") {
                        postal_code = place.address_components[i].long_name;
                    }
                }
            }
            postalCodeRef.current.value = postal_code;
        });
    }, []);

    useEffect(() => {
        // Mapeo de los nombres de los parámetros a los identificadores de los elementos del formulario
        const fields = [
            'nombre', 'primer_apellido', 'segundo_apellido', 'sexo', 'fecha_nacimiento',
            'telefono', 'email', 'documento_identidad', 'dni_nie', 'nacionalidad',
            'prima_anual', 'suma_asegurada', 'garantia', 'compania_origen', 'fecha_efecto'
        ];

        // Extraer los parámetros de la URL
        const urlParams = new URLSearchParams(window.location.search);

        // Iterar sobre los campos y rellenar el formulario de manera dinámica
        fields.forEach(field => {
            var value = urlParams.get(field);
            if (value) {
                if (field === 'fecha_nacimiento') {
                    // Crear un objeto Date con la fecha de nacimiento sumando 1 día
                    let date = new Date(value);
                    document.getElementById(field).value = date.toISOString().split("T")[0];
                } else if (field == 'fecha_efecto') {
                    // Crear un objeto Date con la fecha de efecto sumando 1 día
                    let date = new Date(value);
                    document.getElementById(field).value = date.toISOString().split("T")[0];
                } else if (field === 'documento_identidad') {
                    // Si el documento de identidad es 'dni', seleccionar el radio button correspondiente
                    value = value.toUpperCase();
                    document.querySelector(`input[name=${field}][value=${value}]`).checked = true;
                } else {
                    document.getElementById(field).value = value;
                }
            }
        });
    }, []);

    async function handleSubmitStep1(event) {
        event.preventDefault()
        setStep1Loading(true)
        try {
            var formData = new FormData(event.target);
            // Si generar_presupuesto es on, cambiamos el valor a true, si no, a false
            formData.set("generar_presupuesto", formData.get("generar_presupuesto") == "on" ? true : false)
            if (formData.get("correduria") && formData.get("correduria") != "nadie") {
                formData.append("web_origen", window.location.href + "?" + formData.get("correduria"))
            } else {
                formData.append("web_origen", window.location.href)
            }
            const supabaseClient = createClient(
                'https://uoclqlfzojdjrhcqhgef.supabase.co',
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
            )
            const { data, error } = await supabaseClient.auth.getSession()
            const access_token = data.session.access_token
            let headers = {
                'Authorization': access_token,
                'Content-Type': 'multipart/form-data',
            }
            await axios.post('https://api.office.coventia.es/api/presupuesto/step/1/', formData, { headers: headers }).then((response) => {
                response.data.cobertura = formData.get("garantia")
                response.data.nombre_completo = formData.get("nombre") + " " + formData.get("primer_apellido") + " " + formData.get("segundo_apellido")
                setResponse1Data(response.data)
                setCurrentStep(2)
                setStep1Loading(false)
                window.scrollTo({ top: 0, behavior: 'smooth' })
            }).catch((error) => {
                console.log(error.response)
                if (error.response.data == "NIE incompatible con nacionalidad española") {
                    toast.error(t("El NIE es incompatible con la nacionalidad española"))
                } else if (error.response.data == "age_max") {
                    toast.error(t("La edad máxima es de 64 años"))
                } else if (error.response.data == "age_min") {
                    toast.error(t("La edad mínima es de 18 años"))
                } else if (error.response.data == "fecha_efecto_min") {
                    toast.error(t("La fecha de efecto debe ser como mínimo mañana"))
                } else if (error.response.data == "dni_error") {
                    toast.error(t("El DNI/NIE no es válido"))
                } else {
                    toast.error(t("Ha ocurrido un error inesperado"))
                }
                setStep1Loading(false)
            })
        } catch (error) {
            console.log(error)
            toast.error(t("Ha ocurrido un error inesperado"))
            setStep1Loading(false)
        }
    }

    async function handleSubmitStep2(event) {
        event.preventDefault()
        setStep2Loading(true)
        try {
            var formData = new FormData();
            formData.append("fraccionamiento", event.target.fraccionamiento.value)
            if (pep == false) {
                formData.append("pep", "No")
                formData.append("pep_puesto", " ")
            } else {
                formData.append("pep", "Si")
                formData.append("pep_puesto", event.target.pep_puesto.value)
            }
            if (bancoBeneficiario == false) {
                formData.append("banco_beneficiario", "false")
                formData.append("banco_prestamo", " ")
            } else {
                formData.append("banco_beneficiario", "true")
                formData.append("banco_prestamo", event.target.banco_prestamo.value)
                formData.append("numero_prestamo", event.target.numero_prestamo.value)
                formData.append("importe_prestamo", event.target.importe_prestamo.value)
                formData.append("fecha_vencimiento_prestamo", event.target.fecha_vencimiento_prestamo.value)
                formData.append("tipo_prestamo", event.target.tipo_prestamo.value)
            }
            if (beneficiariosDesignados == false) {
                formData.append("beneficiario", "false")
                formData.append("beneficiario_designados", " ")
            } else {
                formData.append("beneficiario", "true")
                // Debemos sumar todos los porcentajes de los beneficiarios designados y comprobar que sumen 100
                var porcentajes = 0
                for (var i = 0; i < beneficiariosDesignadosData.length; i++) {
                    porcentajes += parseInt(beneficiariosDesignadosData[i].porcentaje)
                }
                if (porcentajes != 100) {
                    toast.error(t("Los porcentajes de los beneficiarios designados deben sumar 100"))
                    setStep2Loading(false)
                    return false;
                }
                formData.append("beneficiario_designados", JSON.stringify(beneficiariosDesignadosData))
            }
            formData.append("direccion", event.target.direccion.value)
            formData.append("codigo_postal", event.target.cp.value)
            formData.append("iban", event.target.iban.value)
            // Si existe documento en el input file_dni, lo añadimos al formData

            if (list_countries.includes(nacionalidad)) {
                if (!event.target.file_cverde.files[0]) {
                    toast.error(t("Debes subir la carta verde"))
                    setStep2Loading(false)
                    return false;
                }
                if (!event.target.file_pasaporte.files[0] && !event.target.file_dninacional.files[0]) {
                    toast.error(t("Debes subir el pasaporte o el DNI nacional"))
                    setStep2Loading(false)
                    return false;
                }
            } else if (nacionalidad != "España") {
                if (!event.target.file_tie.files[0]) {
                    toast.error(t("Debes subir el TIE"))
                    setStep2Loading(false)
                    return false;
                }
            }



            if (event.target.file_dni.files[0]) {
                formData.append("dni", event.target.file_dni.files[0])
            }
            if (event.target.file_cverde.files[0]) {
                formData.append("cverde", event.target.file_cverde.files[0])
            }
            if (event.target.file_pasaporte.files[0]) {
                formData.append("pasaporte", event.target.file_pasaporte.files[0])
            }
            if (event.target.file_dninacional.files[0]) {
                formData.append("dninacional", event.target.file_dninacional.files[0])
            }
            if (event.target.file_tie.files[0]) {
                formData.append("tie", event.target.file_tie.files[0])
            }
            formData.append("caducidad_dni", event.target.caducidad_dni.value)
            if (event.target.file_recibo.files[0]) {
                formData.append("recibo_poliza", event.target.file_recibo.files[0])
            }
            if (event.target.file_poliza.files[0]) {
                formData.append("poliza", event.target.file_poliza.files[0])
            }
            formData.append("presupuesto_id", response1Data.presupuesto_id)
            formData.append("submission_id", uuidv4())
            const supabaseClient = createClient(
                'https://uoclqlfzojdjrhcqhgef.supabase.co',
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
            )
            const { data, error } = await supabaseClient.auth.getSession()
            const access_token = data.session.access_token
            let headers = {
                'Authorization': access_token,
                'Content-Type': 'multipart/form-data',
            }
            await axios.post('https://api.office.coventia.es/api/presupuesto/step/2/', formData, { headers: headers }).then((response) => {
                setCurrentStep(3)
                setStep2Loading(false)
                window.scrollTo({ top: 0, behavior: 'smooth' })
            }).catch((error) => {
                console.log(error.response)
                toast.error(t("Ha ocurrido un error inesperado"))
                setStep2Loading(false)
            })
        } catch (error) {
            console.log(error)
            toast.error(t("Ha ocurrido un error inesperado"))
            setStep2Loading(false)
        }
    }

    async function handleCalculateFactor(event) {
        event.preventDefault()
        setFactorLoading(true)
        try {
            var birthday = new Date(event.target.birthday.value)
            var birthdayString = (birthday.getDate() < 10 ? "0" + birthday.getDate() : birthday.getDate()) + "/" + (birthday.getMonth() < 10 ? "0" + birthday.getMonth() : birthday.getMonth()) + "/" + birthday.getFullYear()
            var empresa = event.target.empresa.value
            var importe_actual = event.target.importe_actual.value
            var cobertura = event.target.cobertura.value
            var endinsurance = new Date(event.target.endinsurance.value)
            var endinsuranceString = (endinsurance.getDate() < 10 ? "0" + endinsurance.getDate() : endinsurance.getDate()) + "/" + (endinsurance.getMonth() < 10 ? "0" + endinsurance.getMonth() : endinsurance.getMonth()) + "/" + endinsurance.getFullYear()
            var suma_asegurada_introducida = event.target.suma_asegurada_introducida.value
            // Enviar petición a través de axios a https://api.coventia.es/policy_calculation con los datos del formulario como parametros URL y con headers
            var origen = event.target.factor_caser.checked ? "caser" : "coventia";
            const headers = {
                'api_key': 'QAG0INRX4uATS3FCjGxDkdSsEPth1iwgrMF6cOsyftXHCX80cYZdDdDBT5qIFnBT86vQmTPDRXHd0oUiZcYnSYXBjxEF74Qtwd1N',
                'Content-Type': 'application/json'
            }
            const url = `https://api.coventia.es/policy_calculation?birthday=${birthdayString}&empresa=${empresa}&importe_actual=${importe_actual}&cobertura=${cobertura}&endinsurance=${endinsuranceString}&suma_asegurada_introducida=${suma_asegurada_introducida}&origen=${origen}`
            const response = await axios.get(url, { headers: headers })
            if (response.data.factor) {
                setFactorData(response.data)
                setFactorLoading(false)
            } else {
                toast.error(t("Ha ocurrido un error inesperado"))
                setFactorLoading(false)
            }

        } catch (error) {
            console.log(error)
            toast.error(t("Ha ocurrido un error inesperado"))
            setFactorLoading(false)
        }
    }

    async function enviarPresupuesto(presupuesto_id) {
        setEnviarPresupuestoLoading(true)
        var url = "https://api.office.coventia.es/api/presupuesto/presupuesto/enviar_cliente/"
        var formData = new FormData();
        formData.append("presupuesto_id", presupuesto_id)
        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        )
        const { data } = await supabaseClient.auth.getSession()
        const access_token = data.session.access_token
        let headers = {
            'Authorization': access_token,
            'Content-Type': 'multipart/form-data',
        }
        axios.post(url, formData, { headers: headers })
        .then((response) => {
            toast.success(t("Presupuesto enviado al cliente correctamente"))
            setEnviarPresupuestoLoading(false)
        })
        .catch((error) => {
            console.log(error)
            toast.error(t("Ha ocurrido un error inesperado"))
            setEnviarPresupuestoLoading(false)
        })
        
    }

    return (
        <>
        {correduria.name == "coventia" ? (
            <>
                <Tooltip id="factor" />
                <div data-dial-init className="fixed end-6 bottom-6 group">
                    <div data-tooltip-variant={theme} data-tooltip-place="left" data-tooltip-id="factor" data-tooltip-content={t("Calculo rápido del factor")} className="flex flex-col items-center mb-4 space-y-2">
                        <button type="button" data-dial-toggle="speed-dial-menu-default" aria-controls="speed-dial-menu-default" aria-expanded="false" className="flex items-center justify-center text-white bg-blue-700 rounded-full w-14 h-14 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800" onClick={() => { setOpen(true); setFactorData({}) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-white transition-transform group-hover:rotate-45">
                                <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </div>
                <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-50" initialFocus={open} onClose={() => { setOpen(false) }}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-gray-800">
                                            <div className="sm:flex sm:items-start">
                                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 inline-flex dark:text-white">
                                                        <div className="mx-auto flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-6 sm:w-6">
                                                            <BoltIcon className="h-4 w-4 text-primary-600" aria-hidden="true" />
                                                        </div>
                                                        <p className="ml-3">Calcula rápidamente el factor de corrección</p>
                                                    </Dialog.Title>
                                                    <div className="mt-4 w-full">
                                                        {/* Formulario que pregunte: Fecha de nacimiento, si es banco o aseguradora, importe de póliza actual, cobertura, fecha de efecto y suma asegurada */}
                                                        <div className="grid grid-cols-1">
                                                            <form onSubmit={handleCalculateFactor}>
                                                                <div className="col-span-2 sm:col-span-1 mt-4">
                                                                    <label htmlFor="birthday" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Fecha de nacimiento *</label>
                                                                    <input type="date" name="birthday" id="birthday" autoComplete="birthday" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Fecha de nacimiento" required max={new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().split("T")[0]} disabled={factorLoading}></input>
                                                                </div>
                                                                <div className="col-span-2 sm:col-span-1 mt-4">
                                                                    <label htmlFor="empresa" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Banco o aseguradora *</label>
                                                                    <select id="empresa" name="empresa" autoComplete="empresa" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required disabled={factorLoading}>
                                                                        <option value="BANKIA MAPFRE VIDA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS">{t('Banco')}</option>
                                                                        <option value="AEGON ESPAÑA S.A. DE SEGUROS Y REASEGUROS">{t('Aseguradora')}</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-span-2 sm:col-span-1 mt-4">
                                                                    <label htmlFor="importe_actual" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Importe de póliza actual *</label>
                                                                    <input type="number" name="importe_actual" id="importe_actual" autoComplete="importe_actual" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Importe de póliza actual" required min="0" disabled={factorLoading} step="0.01"></input>
                                                                </div>
                                                                <div className="col-span-2 sm:col-span-1 mt-4">
                                                                    <label htmlFor="cobertura" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Cobertura *</label>
                                                                    <select id="cobertura" name="cobertura" autoComplete="cobertura" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required disabled={factorLoading}>
                                                                        <option value="Fallecimiento por cualquier causa">{t('Fallecimiento por cualquier causa')}</option>
                                                                        <option value="Fallecimiento e Invalidez por cualquier causa">{t('Fallecimiento e Invalidez por cualquier causa')}</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-span-2 sm:col-span-1 mt-4">
                                                                    <label htmlFor="endinsurance" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Fecha de efecto *</label>
                                                                    <input type="date" name="endinsurance" id="endinsurance" autoComplete="endinsurance" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Fecha de efecto" required min={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split("T")[0]} disabled={factorLoading}></input>
                                                                </div>
                                                                <div className="col-span-2 sm:col-span-1 mt-4">
                                                                    <label htmlFor="suma_asegurada_introducida" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Suma asegurada *</label>
                                                                    <input type="number" name="suma_asegurada_introducida" id="suma_asegurada_introducida" autoComplete="suma_asegurada_introducida" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Suma asegurada" required min="0" disabled={factorLoading} step="0.01"></input>
                                                                </div>
                                                                <div className="col-span-2 sm:col-span-1 mt-4">
                                                                    <label className="relative inline-flex items-center cursor-pointer">
                                                                        <input disabled={factorLoading} type="checkbox" name="factor_caser" defaultChecked={false} className="sr-only peer"></input>
                                                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                                        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{t('¿Factor de Caser Agentes?')}</span>
                                                                    </label>
                                                                </div>
                                                                {/* Botón de calcular */}
                                                                <div className="col-span-2 sm:col-span-1 mt-4">
                                                                    <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-semibold text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm" disabled={factorLoading}>
                                                                        {factorLoading ? (
                                                                            t('Calculando...')
                                                                        ) : t('Calcular')}
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    {factorData.factor && !factorLoading ? (
                                                        <div className="mt-6 w-full mb-3">
                                                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                                        <tr>
                                                                            <th scope="col" className="px-6 py-3">
                                                                                {t('Factor')}
                                                                            </th>
                                                                            <th scope="col" className="px-6 py-3">
                                                                                {t('Nueva prima')}
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr className="text-gray-700 dark:text-gray-400">
                                                                            <td className="px-4 py-3">{Number(factorData.factor).toFixed(5)}</td>
                                                                            <td className="px-4 py-3">{factorData.poliza_nueva} €</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-gray-700">
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto dark:bg-gray-800 dark:text-white dark:ring-gray-700 dark:hover:bg-gray-600"
                                                onClick={() => { setOpen(false) }}
                                            >
                                                {t('Cerrar')}
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </>
        ) : null}
        <NavBreadcrumb currentPage={[{text:t("Crear Presupuesto"),url:''}]} />
        <div className={"max-w-full mx-4 py-14 sm:mx-auto sm:px-6 md:px-8 lg:px-10 xl:px-60 " + (currentStep == 1 ? "" : "hidden")}>
            <form onSubmit={handleSubmitStep1}>
                {/* Creamos un bloque donde van a ir dos columnas */}
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                    {/* Necesitamos dividirlo en dos columnas, para que haya dos inputs por fila (solo en escritorio) */}
                    <div className="col-span-2 sm:col-span-1">
                        <label htmlFor="nombre" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Nombre')} *</label>
                        <input disabled={step1Loading} type="text" name="nombre" id="nombre" autoComplete="name" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Nombre")} required></input>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        <label htmlFor="sexo" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Género')} *</label>
                        <select disabled={step1Loading} id="sexo" name="sexo" autoComplete="sexo" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                            <option value="M">{t('Hombre')}</option>
                            <option value="F">{t('Mujer')}</option>
                        </select>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        {/* Primer apellido */}
                        <label htmlFor="primer_apellido" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Primer apellido')} *</label>
                        <input disabled={step1Loading} type="text" name="primer_apellido" id="primer_apellido" autoComplete="primer_apellido" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Primer apellido")} required></input>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        {/* Segundo apellido */}
                        <label htmlFor="segundo_apellido" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Segundo apellido')} *</label>
                        <input disabled={step1Loading} type="text" name="segundo_apellido" id="segundo_apellido" autoComplete="segundo_apellido" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Segundo apellido")} required></input>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        {/* Fecha de nacimiento */}
                        <label htmlFor="fecha_nacimiento" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Fecha de nacimiento')} *</label>
                        {/* Debe ser mayor de 18 años */}
                        <input disabled={step1Loading} type="date" name="fecha_nacimiento" id="fecha_nacimiento" autoComplete="fecha_nacimiento" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" defaultValue={"01-01-2000"} placeholder={t("Fecha de nacimiento")} required max={new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().split("T")[0]}></input>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        {/* Teléfono */}
                        <label htmlFor="telefono" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Teléfono')} *</label>
                        <input disabled={step1Loading} type="tel" name="telefono" id="telefono" autoComplete="telefono" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Teléfono")} required></input>
                    </div>
                    <div className="col-span-2 sm:col-span-2">
                        {/* Email */}
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Email *</label>
                        <input disabled={step1Loading} type="email" name="email" id="email" autoComplete="email" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email" required></input>
                    </div>
                    <div className="col-span-2 sm:col-span-2">
                        {/* Documento de identidad (Radio Buttons con DNI o NIE) */}
                        <label htmlFor="dni" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Documento de identidad')} *</label>
                        <div className="mt-1">
                            <div>
                                <label className="inline-flex items-center">
                                    <input disabled={step1Loading} type="radio" name="documento_identidad" id="dni" value="DNI" className="text-primary-600 border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500" required></input>
                                    <span className="ml-2 font-small text-gray-700 dark:text-gray-400">DNI</span>
                                </label>
                                <label className="inline-flex items-center ml-6">
                                    <input disabled={step1Loading} type="radio" name="documento_identidad" id="documento_identidad" value="NIE" className="text-primary-600 border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500"></input>
                                    <span className="ml-2 font-small text-gray-700 dark:text-gray-400">NIE</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2 sm:col-span-2">
                        {/* DNI/NIE */}
                        <label htmlFor="dni_nie" className="block text-sm font-medium text-gray-700 dark:text-gray-400">DNI / NIE *</label>
                        <input disabled={step1Loading} type="text" name="dni_nie" id="dni_nie" autoComplete="dni_nie" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="DNI / NIE" required></input>
                    </div>
                    <div className="col-span-2 sm:col-span-2">
                        {/* Select con los países, el país 'España' debe estar preseleccionado */}
                        <label htmlFor="nacionalidad" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Nacionalidad')} *</label>
                        <select disabled={step1Loading} id="nacionalidad" name="nacionalidad" autoComplete="nacionalidad" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required
                            value={nacionalidad}
                            onChange={(e) => setNacionalidad(e.target.value)}
                        >
                            {countryList.map((country) => (
                                <option key={country} value={country}>{country}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-span-2 sm:col-span-2">
                        {/* Prima anual actual (en euros) */}
                        <label htmlFor="prima_anual" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Prima anual actual (en euros)')} *</label>
                        <input disabled={step1Loading} step="0.01" type="number" name="prima_anual" id="prima_anual" autoComplete="prima_anual" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Prima anual actual")} required></input>
                    </div>
                    <div className="col-span-2 sm:col-span-2">
                        {/* Suma asegurada (en euros) */}
                        <label htmlFor="suma_asegurada" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Suma asegurada (en euros)')} *</label>
                        <input disabled={step1Loading} step="0.01" max="600000" type="number" name="suma_asegurada" id="suma_asegurada" autoComplete="suma_asegurada" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Suma asegurada")} required></input>
                    </div>
                    <div className="col-span-2 sm:col-span-2">
                        {/* Garantía. Select con "Fallecimiento por cualquier causa" o "Fallecimiento e Invalidez por cualquier causa" */}
                        <label htmlFor="garantia" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Garantía')} *</label>
                        <select disabled={step1Loading} defaultValue="fallecimiento_invalidez" id="garantia" name="garantia" autoComplete="garantia" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                            <option value="Fallecimiento por cualquier causa">{t('Fallecimiento por cualquier causa')}</option>
                            <option value="Fallecimiento e Invalidez por cualquier causa">{t('Fallecimiento e Invalidez por cualquier causa')}</option>
                        </select>
                    </div>
                    <div className="col-span-2 sm:col-span-2">
                        {/* Compañía de origen */}
                        <label htmlFor="compania_origen" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Compañía de origen')} *</label>
                        <select disabled={step1Loading} id="compania_origen" name="compania_origen" autoComplete="compania_origen" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                            {companies.map((company) => (
                                <option key={company} value={company}>{company}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-span-2 sm:col-span-2">
                        {/* Fecha de efecto. No puede ser hoy o antes de hoy */}
                        <label htmlFor="fecha_efecto" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Fecha de efecto')} *</label>
                        <input min={new Date().toISOString().split("T")[0]} disabled={step1Loading} type="date" name="fecha_efecto" id="fecha_efecto" autoComplete="fecha_efecto" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Fecha de efecto")} required></input>
                    </div>
                    {correduria.name == "coventia" ? (
                        // Select con todas las corredurias para generar un proyecto en su nombre
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="correduria" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Hacer presupuesto en nombre de...')}</label>
                            <select defaultValue="nadie" disabled={step1Loading} id="correduria" name="correduria" autoComplete="correduria" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                                <option key="nadie" value="nadie">{t('Nadie')}</option>
                                <option key="selfbank" value="selfbank">SingularBank / SelfBank</option>
                                {/* <option key="safebrok" value="safebrok">Safebrok</option>
                                <option key="proyectosyseguros" value="proyectosyseguros">PYSSA</option>
                                <option key="acierto" value="acierto">Acierto</option>
                                <option key="hefame" value="hefame">Hefame</option>
                                <option key="vihotec" value="vihotec">Vihotec</option>
                                <option key="prudenzia" value="prudenzia">Prudenzia</option>
                                <option key="gemina" value="gemina">Gemina</option> */}
                            </select>
                        </div>
                    ) : null}
                    <div className="col-span-2 sm:col-span-2">
                        {/* Toggle "¿Generar presupuesto?". Debe estar seleccionado por defecto */}
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input disabled={step1Loading} type="checkbox" name="generar_presupuesto" defaultChecked={true} className="sr-only peer"></input>
                            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{t('¿Generar presupuesto?')}</span>
                        </label>
                    </div>
                    {/* Botón de enviar, el botón debe ocupar todo el ancho del formulario */}
                    <div className={"col-span-2 sm:col-span-2 " + (step1Loading ? "hidden" : "")}>
                        <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300">
                            {t('Siguiente')}
                        </button>
                    </div>
                    <div className={"col-span-2 sm:col-span-2 " + (step1Loading ? "" : "hidden")}>
                        <button disabled type="button" className={"w-full justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"}>
                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                            </svg>
                            {t('Cargando...')}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div className={"max-w-full mx-4 py-14 sm:mx-auto sm:px-6 md:px-8 lg:px-10 xl:px-10 " + (currentStep == 2 ? "" : "hidden")}>
            <form onSubmit={handleSubmitStep2}>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    <div className="lg:col-span-2 order-2 lg:order-1 gap-6 grid grid-cols-1 sm:grid-cols-2">
                        <div className="col-span-2 sm:col-span-2">
                            {/* Fraccionamiento (Mensualmente, Trimestralmente, Semestralmente, Anualmente) */}
                            <label htmlFor="fraccionamiento" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Fraccionamiento')} *</label>
                            <select disabled={step2Loading} id="fraccionamiento" name="fraccionamiento" autoComplete="fraccionamiento" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="Anualmente" disabled={response1Data.pago_anual == false ? true : false}>
                                    {response1Data.pago_anual ? t("Anualmente") + " - " + response1Data.pago_anual : t("Anualmente")}
                                </option>
                                <option value="Semestralmente" disabled={response1Data.pago_semestral == false ? true : false}>
                                    {response1Data.pago_semestral ? t("Semestralmente") +  " - " + response1Data.pago_semestral : t("Semestralmente")}
                                </option>
                                <option value="Trimestralmente" disabled={response1Data.pago_trimestral == false ? true : false}>
                                    {response1Data.pago_trimestral ? t("Trimestralmente") + " - " + response1Data.pago_trimestral : t("Trimestralmente")}
                                </option>
                                <option value="Mensualmente" disabled={response1Data.pago_mensual == false ? true : false}>
                                    {response1Data.pago_mensual ? t("Mensualmente") + " - " + response1Data.pago_mensual : t("Mensualmente")}
                                </option>
                            </select>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label className="relative inline-flex items-center cursor-pointer">
                                {/* Cuando cambie de valor, se debe actualizar el valor de pep con true o false y mostrar el valor por consola */}
                                <input disabled={step2Loading} type="checkbox" name="pep" defaultChecked={false} className="sr-only peer" onChange={(e) => setPep(e.target.checked)}></input>
                                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300 mr-2">PEP's</span>
                            </label>
                        </div>
                        <div className="col-span-2 sm:col-span-2" style={{ display: pep ? "block" : "none" }}>
                            <label htmlFor="pep_puesto" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Puesto')} *</label>
                            <input disabled={step2Loading} type="text" name="pep_puesto" id="pep_puesto" autoComplete="pep_puesto" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Puesto")}></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input disabled={step2Loading} type="checkbox" name="banco_beneficiario" defaultChecked={false} className="sr-only peer" onChange={(e) => setBancoBeneficiario(e.target.checked)}></input>
                                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300 mr-2">{t('¿Es el banco beneficiario?')}</span>
                            </label>
                        </div>
                        <div className="col-span-2 sm:col-span-2" style={{ display: bancoBeneficiario ? "block" : "none" }}>
                            <label htmlFor="banco_prestamo" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Entidad del préstamo')} *</label>
                            <input disabled={step2Loading} type="text" name="banco_prestamo" id="banco_prestamo" autoComplete="banco_prestamo" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Entidad del préstamo")}></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2" style={{ display: bancoBeneficiario ? "block" : "none" }}>
                            <label htmlFor="numero_prestamo" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Número del préstamo')} *</label>
                            <input disabled={step2Loading} type="text" name="numero_prestamo" id="numero_prestamo" autoComplete="numero_prestamo" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Número del préstamo")}></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2" style={{ display: bancoBeneficiario ? "block" : "none" }}>
                            <label htmlFor="importe_prestamo" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Importe del préstamo')} *</label>
                            <input disabled={step2Loading} type="number" name="importe_prestamo" id="importe_prestamo" autoComplete="importe_prestamo" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Importe del préstamo")} step="0.01"></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2" style={{ display: bancoBeneficiario ? "block" : "none" }}>
                            <label htmlFor="fecha_vencimiento_prestamo" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Fecha de vencimiento del préstamo')} *</label>
                            <input disabled={step2Loading} type="date" name="fecha_vencimiento_prestamo" id="fecha_vencimiento_prestamo" autoComplete="fecha_vencimiento_prestamo" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2" style={{ display: bancoBeneficiario ? "block" : "none" }}>
                            <label htmlFor="tipo_prestamo" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Tipo de préstamo')} *</label>
                            <select disabled={step2Loading} id="tipo_prestamo" name="tipo_prestamo" autoComplete="tipo_prestamo" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="Hipotecario">
                                    Hipotecario
                                </option>
                                <option value="Consumo">
                                    Consumo
                                </option>
                                <option value="Personal">
                                    Personal
                                </option>
                            </select>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="dni" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Beneficiarios')} *</label>
                            <div className="mt-1">
                                <div>
                                    <Tooltip id="c_estandar" className="z-50">
                                        <p>{t("En este orden:")}</p>
                                        <p>{t("1: Cónyuge del Asegurado/a no separado por resolución judicial.")}</p>
                                        <p>{t("2: Hijos supérstites del Asegurado/a a partes iguales.")}</p>
                                        <p>{t("3: Padres supérstites del Asegurado/a a partes iguales.")}</p>
                                        <p>{t("4: Herederos del asegurado/a.")}</p>
                                    </Tooltip>
                                    <label className="inline-flex items-center">
                                        <input disabled={step2Loading} defaultChecked={true} type="radio" name="beneficiarios" id="clausula_estandar" value="clausula_estandar" className="text-primary-600 border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={(e) => setBeneficiariosDesignados(false)}></input>
                                        <span className="ml-2 font-small text-gray-700 dark:text-gray-400 inline-flex items-center">
                                            {t('Cláusula estandar')} 
                                            <svg data-tooltip-variant={theme} data-tooltip-place="right" data-tooltip-id="c_estandar" className="ml-2 w-4 h-4" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                            </svg>
                                        </span>
                                    </label>
                                    <label className="inline-flex items-center ml-6">
                                        <input disabled={step2Loading} type="radio" name="beneficiarios" id="beneficiarios_designados" value="beneficiarios_designados" className="text-primary-600 border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e) => setBeneficiariosDesignados(true)}></input>
                                        <span className="ml-2 font-small text-gray-700 dark:text-gray-400">{t('Beneficiarios designados')} *</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-2 sm:col-span-2" style={{ display: beneficiariosDesignados ? "block" : "none" }}>
                            {beneficiariosDesignados &&
                                <>
                                {beneficiariosDesignadosData.map((beneficiario, index) => (
                                    <div key={index} className="grid grid-cols-1 gap-5 mb-4 border p-4 rounded-lg bg-gray-100 dark:bg-gray-800">
                                        {/* El título y el boton de eliminar deben estar en la misma fila, el boton alignado a la derecha */}
                                        <div className="col-span-2 sm:col-span-2 justify-between inline-flex items-center">
                                            <h3 className="text-md font-medium text-gray-900 dark:text-gray-300">{t('Beneficiario')} {index + 1}</h3>
                                            <button disabled={step2Loading} className="justify-center text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 inline-flex items-center" type="button" onClick={() => { if (beneficiariosDesignadosData.length > 1) { setBeneficiariosDesignadosData(beneficiariosDesignadosData.filter((item, i) => i !== index)); } else { toast.error(t("No puedes eliminar el último beneficiario")); } }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2">
                                                    <path fillRule="evenodd" d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
                                                </svg>
                                                {t('Eliminar beneficiario')}
                                            </button>
                                        </div>
                                        <div className="col-span-2 sm:col-span-2">
                                            <label htmlFor={"beneficiario_designados" + index + "_nombre"} className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Nombre del beneficiario')} *</label>
                                            <input disabled={step2Loading} type="text" name={"beneficiario_designados" + index + "_nombre"} autoComplete={"nombre_beneficiario_" + index} className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Nombre del beneficiario")} onChange={(e) => setBeneficiariosDesignadosData(beneficiariosDesignadosData.map((item, i) => i === index ? { ...item, nombre: e.target.value } : item))} value={beneficiario.nombre} required></input>
                                        </div>
                                        <div className="col-span-2 sm:col-span-2">
                                            <label htmlFor={"beneficiario_designados" + index + "_dni"} className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('DNI del beneficiario')} *</label>
                                            <input disabled={step2Loading} type="text" name={"beneficiario_designados" + index + "_dni"} autoComplete={"dni_beneficiario_" + index} className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("DNI del beneficiario")} onChange={(e) => setBeneficiariosDesignadosData(beneficiariosDesignadosData.map((item, i) => i === index ? { ...item, dni: e.target.value } : item))} value={beneficiario.dni} required></input>
                                        </div>
                                        <div className="col-span-2 sm:col-span-2">
                                            <label htmlFor={"beneficiario_designados" + index + "_porcentaje"} className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Porcentaje del beneficiario')} *</label>
                                            <input disabled={step2Loading} type="number" name={"beneficiario_designados" + index + "_porcentaje"} autoComplete={"porcentaje_beneficiario_" + index} className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Porcentaje del beneficiario")} onChange={(e) => setBeneficiariosDesignadosData(beneficiariosDesignadosData.map((item, i) => i === index ? { ...item, porcentaje: e.target.value } : item))} value={beneficiario.porcentaje} required></input>
                                        </div>
                                    </div>
                                ))}
                                </>
                            }
                            <button
                                disabled={step2Loading}
                                className="justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                type="button"
                                onClick={() => {
                                    if (beneficiariosDesignadosData.length < MAX_BENEFICIARIOS) {
                                        setBeneficiariosDesignadosData([...beneficiariosDesignadosData, { nombre: "", dni: "", porcentaje: "" }]);
                                    } else {
                                        toast.error(t("No puedes añadir más de ") + MAX_BENEFICIARIOS + t(" beneficiarios"));
                                    }
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2">
                                    <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
                                </svg>
                                {t("Añadir beneficiario")}
                            </button>                     
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="direccion" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Dirección')} *</label>
                            <input disabled={step2Loading} ref={inputRef} type="text" name="direccion" id="direccion" autoComplete="direccion" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Dirección")} required></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="cp" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Código postal')} *</label>
                            <input disabled={step2Loading} ref={postalCodeRef} type="text" name="cp" id="cp" autoComplete="cp" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Código postal")} required></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="iban" className="block text-sm font-medium text-gray-700 dark:text-gray-400">IBAN *</label>
                            <input disabled={step2Loading} type="text" name="iban" id="iban" autoComplete="iban" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="IBAN" required></input>
                        </div>
                        <div className={"col-span-2 sm:col-span-2 " + (nacionalidad == "España" ? "" : "hidden")}>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">DNI</label>
                            <input disabled={step2Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="file_dni" id="file_dni" type="file"></input>
                        </div>
                        <div className={"col-span-2 sm:col-span-2 " + (list_countries.includes(nacionalidad) ? "" : "hidden")}>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('Carta Verde (Certificado de registro de ciudadano de la unión)')}</label>
                            <input disabled={step2Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="file_cverde" id="file_cverde" type="file"></input>
                        </div>
                        <div className={"col-span-2 sm:col-span-2 " + (list_countries.includes(nacionalidad) ? "" : "hidden")}>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('Pasaporte')}</label>
                            <input disabled={step2Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="file_pasaporte" id="file_pasaporte" type="file"></input>
                        </div>
                        <div className={"col-span-2 sm:col-span-2 " + (list_countries.includes(nacionalidad) ? "" : "hidden")}>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('DNI nacional (Del país de origen)')} *</label>
                            <input disabled={step2Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="file_dninacional" id="file_dninacional" type="file"></input>
                        </div>
                        <div className={"col-span-2 sm:col-span-2 " + (nacionalidad != "España" && !list_countries.includes(nacionalidad) ? "" : "hidden")}>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('TIE (Permiso de residencia)')}</label>
                            <input disabled={step2Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="file_tie" id="file_tie" type="file"></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="fecha_caducidad" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Fecha de caducidad del DNI/Documento')} *</label>
                            <input disabled={step2Loading} min={new Date().toISOString().split("T")[0]} type="date" name="caducidad_dni" id="caducidad_dni" autoComplete="caducidad_dni" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('Recibo de la póliza')}</label>
                            <input disabled={step2Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="file_recibo" id="file_recibo" type="file"></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('Póliza')}</label>
                            <input disabled={step2Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="file_poliza" id="file_poliza" type="file"></input>
                        </div>
                        {/* Botón de enviar "Enviar solicitud" */}
                        <div className={"col-span-2 sm:col-span-2 " + (step2Loading ? "hidden" : "")}>
                            <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300">
                                {t('Enviar solicitud')}
                            </button>
                        </div>
                        {/* Botoón para volver atrás */}
                        <div className={"col-span-2 sm:col-span-2 " + (step2Loading ? "hidden" : "")}>
                            <button onClick={() => setCurrentStep(1)} type="button" className="w-full text-primary-600 hover:text-white border border-primary-600 hover:bg-primary-600 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center me-2 mb-2 dark:border-primary-500 dark:text-primary-500 dark:hover:text-white dark:hover:bg-primary-500 dark:focus:ring-primary-800">
                                {t('Volver')}
                            </button>
                        </div>
                        <div className={"col-span-2 sm:col-span-2 " + (step2Loading ? "" : "hidden")}>
                            <button disabled type="button" className={"w-full justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"}>
                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                                </svg>
                                {t('Enviando...')}
                            </button>
                        </div>
                    </div>
                    <div className="lg:col-span-1 order-1 lg:order-2">
                        <div className="p-6 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 shadow-2xl top-24 sticky">
                            <a>
                                <h5 className="mb-2 text-1xl font-bold tracking-tight text-gray-900 dark:text-white">{t('Seguro de')}<br></br><span className="text-primary-700 dark:text-primary-300">{response1Data.nombre_completo}</span></h5>
                            </a>
                            {/* Aquí deben ir una serie de datos (datos de ejemplo: Precio 700, Cobertura Fallecimiento e Invalided por cualquier causa, Suma asegurada 50000, ID recziiggMpZ6V2cEo). El título del dato debe estar alineado a la izquierda y el dato alineado a la derecha */}
                            <div className="flex justify-between items-center m-2 mt-10 mb-6">
                                <p className="text-gray-700 dark:text-gray-400 text-sm font-bold">{t('Precio')}</p>
                                <p className="text-gray-700 dark:text-gray-400 text-sm">{response1Data.poliza_nueva} €</p>
                            </div>
                            <div className="flex justify-between items-center m-2 mb-6">
                                <p className="text-gray-700 dark:text-gray-400 text-sm font-bold">{t('Cobertura')}</p>
                                {/* Este texto suele ser bastante grande por lo que debemos definir un ancho máximo para que no se pegue al titulo */}
                                <p className="text-gray-700 dark:text-gray-400 text-sm text-right max-w-[50%]">{response1Data.cobertura}</p>
                            </div>
                            <div className="flex justify-between items-center m-2 mb-6">
                                <p className="text-gray-700 dark:text-gray-400 text-sm font-bold">{t('Suma asegurada')}</p>
                                <p className="text-gray-700 dark:text-gray-400 text-sm">{response1Data.suma_asegurada}€</p>
                            </div>
                            <div className="flex justify-between items-center m-2 mb-6">
                                <p className="text-gray-700 dark:text-gray-400 text-sm font-bold">ID</p>
                                <p className="text-gray-700 dark:text-gray-400 text-sm">{response1Data.presupuesto_id}</p>
                            </div>
                            <div className={"items-center m-2 mt-6 mb-6 " + (response1Data.presupuesto_url ? "" : "hidden")}>
                                <p className="text-gray-700 dark:text-gray-400 text-sm font-bold mb-4">{t('Presupuesto')}</p>
                                {!enviarPresupuestoLoading ?
                                        <a className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-purple-700 rounded-lg hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800 m-2 cursor-pointer" onClick={() => { enviarPresupuesto(response1Data.presupuesto_id)}}>
                                            {t('Enviar al cliente')}
                                            <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                            </svg>
                                        </a>
                                    :
                                    <a className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-purple-700 rounded-lg hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800 m-2 cursor-wait" disabled>
                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                        {t('Enviando...')}
                                        <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                        </svg>
                                    </a>
                                    }
                                <br></br>
                                <a className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 m-2" href={response1Data.presupuesto_url} target="_blank">
                                    {t('Descargar')}
                                    <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                    </svg>
                                </a>
                                {/* En esta url debemos eliminar '?download=1' y añadir '?download=0' */}
                                <a className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 m-2" href={response1Data.presupuesto_url ? response1Data.presupuesto_url.replace('?download=1', '?download=0') : ""} target="_blank">
                                    {t('Ver')}
                                    <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div className={"max-w-full mx-4 py-14 sm:mx-auto sm:px-6 md:px-8 lg:px-10 xl:px-10 " + (currentStep == 3 ? "" : "hidden")}>
            <div className="flex flex-col items-center justify-center mt-12">
                <svg className="w-12 h-12 text-gray-800 dark:text-white mb-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <h3 className="text-2xl font-bold text-gray-800 dark:text-white">{t('Solicitud enviada correctamente')}</h3>
                <p className="text-gray-600 dark:text-gray-400">{t('En breve el cliente recibirá por email los documentos para firmar')}</p>
            </div>
        </div>
        </>
    );
}