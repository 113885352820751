import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import 'dayjs/locale/es';
import { Datepicker, Select } from 'flowbite-react';
import { FileInput, Label } from "flowbite-react";
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';
import { useAuth } from '../../hooks/useAuth';
import axios from 'axios';
import { supabaseClient } from '../../config/supabase-clients';

const supabase = supabaseClient;

dayjs.locale('es');
dayjs.extend(isBetween);



export const RequestAbsence = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [motivo, setMotivo] = useState('');
    const { userPublicData } = useAuth();

    const [records, setRecords] = useState([]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileName(file.name);
        }

        setFile(file);
    };

    useEffect(() => {
        const fetchRecords = async () => {
            const { data, error } = await supabase
                .from('absence')
                .select('*')
                .eq('user_id', userPublicData.user_id);

            if (error) {
                console.log('error', error);
                toast.error('Error al cargar los registros de ausencias');
            } else {
                const fetchedRecords = data.map(record => ({
                    ...record,
                    startDate: new Date(record.start_date),
                    endDate: new Date(record.end_date),
                    motivo: record.reason
                }));
                setRecords(fetchedRecords);
            }
        }
        fetchRecords();
    }, [userPublicData.user_id]);

    const handleSubmit = async () => {
        if (!startDate || !endDate || !motivo) {
            toast.error('Por favor, complete todos los campos');
            return;
        }
        setLoading(true);

        const existingRecord = records.find(record => {
            const checkStartDate = record.startDate;
            const checkEndDate = record.endDate;
            const checkNewStartDate = startDate;
            const checkNewEndDate = endDate;

            const recordStart = dayjs(checkStartDate);
            const recordEnd = dayjs(checkEndDate);
            const newStart = dayjs(checkNewStartDate);
            const newEnd = dayjs(checkNewEndDate);

            return (newStart.isBetween(recordStart, recordEnd, null, '[]') || newEnd.isBetween(recordStart, recordEnd, null, '[]') || recordStart.isBetween(newStart, newEnd, null, '[]') || recordEnd.isBetween(newStart, newEnd, null, '[]'));
        });

        if (existingRecord) {
            toast.error('Ya existe un registro de ausencia que se solapa con las fechas proporcionadas');
            setLoading(false);
            return;
        }

        let fileURL = null;

        if (file) {
            // Llamada a AWS Lambda para que se encargue de subir el archivo a S3 y mandárselo como base64
            const uploadFile = async (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.onloadend = async () => {
                        try {
                            const base64File = reader.result.split(',')[1]; // Elimina el prefijo de la cadena base64

                            const response = await axios.post('https://lekar5dpotxvon3we3plecroby0debbl.lambda-url.eu-west-3.on.aws/', {
                                file: base64File,
                                fileName: file.name,
                            });

                            resolve(response.data);
                        } catch (error) {
                            console.error('Error uploading file:', error);
                            reject(error);
                        }
                    };

                    reader.onerror = (error) => {
                        console.error('Error reading file:', error);
                        reject(error);
                    };
                });
            };

            try {
                const response = await uploadFile(file);
                fileURL = response.imageUrl; // Extraer directamente imageUrl de la respuesta

            } catch (error) {
                console.error('Error:', error);
                toast.error('Error al subir el archivo');
                setLoading(false);
            }
        }

        const adjustedStartDate = new Date(startDate);
        adjustedStartDate.setDate(adjustedStartDate.getDate() + 1);

        const adjustedEndDate = new Date(endDate);
        adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

        // Insertar en la base de datos con las fechas ajustadas
        const { data, error } = await supabase
            .from('absence')
            .insert([
                {
                    user_id: userPublicData.user_id,
                    start_date: adjustedStartDate.toISOString().split('T')[0], // Convertir a 'YYYY-MM-DD'
                    end_date: adjustedEndDate.toISOString().split('T')[0],   // Convertir a 'YYYY-MM-DD'
                    file: fileURL,
                    reason: motivo
                }
            ]);


        if (error) {
            console.log('error', error);
            toast.error('Error al registrar la ausencia');
        } else {

            setRecords([
                ...records,
                {
                    startDate: startDate,
                    endDate: endDate,
                    file: fileURL,
                    motivo: motivo
                }
            ]);
            toast.success('Ausencia registrada con éxito');
            setLoading(false);
        }
    }

    return (
        <div className="rounded-md px-4 md:px-8">
            <div className="overflow-x-auto shadow-md relative mt-4 sm:rounded-lg">
                <motion.table layout className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="py-3 px-6">Fecha de inicio</th>
                            <th scope="col" className="py-3 px-6">Fecha de fin</th>
                            <th scope="col" className="py-3 px-6">Motivo</th>
                            <th scope="col" className="py-3 px-6">Archivo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.length > 0 ? (
                            records.map((registro, index) => (
                                <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <td className="py-4 px-6">{new Date(registro.startDate).toLocaleDateString()}</td>
                                    <td className="py-4 px-6">{new Date(registro.endDate).toLocaleDateString()}</td>
                                    <td className="py-4 px-6">{registro.motivo}</td>
                                    <td className="py-4 px-6">
                                        {registro.file ? (
                                            <a href={registro.file} target='blank' className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400 inline-flex items-center justify-center">
                                                Ver archivo
                                            </a>
                                        ) : (
                                            <span className="text-red-500 text-xs font-semibold me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 inline-flex items-center justify-center">
                                                Sin archivo
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <td colSpan="5" className="py-4 px-4 md:px-6 text-center">No hay registros de ausencias</td>
                            </tr>
                        )}
                    </tbody>
                </motion.table>
            </div>

            <h2 className='text-1xl p-2 font-semibold text-gray-500 dark:text-white mt-2'>Pedir ausencia</h2>
            <div className='p-3 shadow-md bg-gray-100 dark:bg-gray-800 rounded-md'>
                <div className=" flex items-center mb-4 p-3">
                    <Datepicker
                        language='es-ES'
                        className="w-1/2"
                        onSelectedDateChanged={(e) => {
                            setStartDate(e);
                        }}
                        minDate={new Date()}
                        showClearButton={false}
                        labelTodayButton='Hoy'
                        weekStart={1}
                    />
                    <span className="text-gray-500 dark:text-gray-400 mx-2"> - </span>
                    <Datepicker
                        language='es-ES'
                        className="w-1/2"
                        onSelectedDateChanged={(e) => {
                            setEndDate(e);
                        }}
                        minDate={new Date()}
                        showClearButton={false}
                        labelTodayButton='Hoy'
                        weekStart={1}
                    />
                    <Select className='w-1/4 px-3' onChange={(e) => setMotivo(e.target.value)}>
                        <option value="">-</option>
                        <option value="Baja médica">Baja médica</option>
                        <option value="Permiso retribuido">Permiso retribuido</option>
                        <option value="Otras causas">Otras causas</option>
                    </Select>
                </div>
                <div className='flex items-center p-5 bg-gray-100 dark:bg-gray-800 rounded-md'>
                    <div className="flex w-full items-center justify-center">
                        <Label
                            htmlFor="dropzone-file"
                            className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                        >
                            <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                <svg
                                    className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 16"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                    />
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                    <span className="font-semibold">Click para subir un archivo</span> o arrástralo aquí
                                </p>
                                {fileName && (
                                    <p className="mt-2 text-sm text-green-500 dark:text-green-400">
                                        Archivo subido: {fileName}
                                    </p>
                                )}
                            </div>
                            <FileInput id="dropzone-file" className="hidden" onChange={handleFileChange} />
                        </Label>
                    </div>
                </div>
                <div className='flex items-end justify-end'>
                    <button onClick={handleSubmit} disabled={loading} className="bg-primary-600 text-white w-1/5 py-2 mr-4 rounded disabled:bg-primary-300">Completar ausencia</button>
                </div>
            </div>
        </div>
    );
};