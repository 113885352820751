const companies = [
    "Aseguradora",
    "Banco-Asegurador",
    "ABANCA VIDA Y PENSIONES DE SEGUROS Y REASEGUROS, S.A",
    "AEGON ESPAÑA S.A. DE SEGUROS Y REASEGUROS",
    "ALLIANZ POPULAR VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A., SOCIEDAD UNIPERSONAL",
    "ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.",
    "ASISA VIDA",
    "ATOCHA, S.A. DE SEGUROS",
    "AURA, SOCIEDAD ANONIMA DE SEGUROS.",
    "AXA AURORA VIDA, S.A. DE SEGUROS Y REASEGUROS",
    "AXA SEGUROS GENERALES, S. A. DE SEGUROS Y REASEGUROS",
    "BANKIA MAPFRE VIDA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS",
    "BANKINTER SEGUROS DE VIDA, S.A. DE SEGUROS Y REASEGUROS",
    "BANSABADELL VIDA, S.A. DE SEGUROS Y REASEGUROS",
    "BBVASEGUROS, S.A., DE SEGUROS Y REASEGUROS",
    "BILBAO, COMPAÑIA ANONIMA DE SEGUROS Y REASEGUROS.",
    "CA LIFE INSURANCE EXPERTS COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.U.",
    "CAIXA ENGINYERS VIDA-CAJA INGENIEROS VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.",
    "CAJA GRANADA VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, SOCIEDAD ANONIMA",
    "CAJAMAR SEGUROS GENERALES, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS",
    "CAJAMAR VIDA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS",
    "CAJAMURCIA VIDA Y PENSIONES DE SEGUROS Y REASEGUROS, S.A.",
    "CASER-CAJA DE SEGUROS REUNIDOS, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.",
    "CCM VIDA Y PENSIONES DE SEGUROS Y REASEGUROS, SOCIEDAD ANONIMA",
    "CESCE",
    "CNP PARTNERS DE SEGUROS Y REASEGUROS S.A.",
    "COMPAÑIA DE SEGUROS IGUALATORIO MEDICO QUIRURGICO Y DE ESPECIALIDADES DE NAVARRA, SOCIEDAD ANONIMA",
    "DKV SEGUROS Y REASEGUROS, SOCIEDAD ANONIMA ESPAÑOLA",
    "ERGO VIDA SEGUROS Y REASEGUROS, SOCIEDAD ANONIMA",
    "Familiar de Seguros Active, SOCIEDAD ANONIMA",
    "FENIX DIRECTO, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.",
    "FIATC, MUTUA DE SEGUROS Y REASEGUROS",
    "FIDELIDADE COMPANHIA DE SEGUROS S.A SUC.ESPAÑA",
    "GACM SEGUROS GENERALES, COMPAÑÍA DE SEGUROS Y REASEGUROS, S.A.",
    "GENERALI ESPAÑA, SOCIEDAD ANÓNIMA DE SEGUROS Y REASEGUROS",
    "GES, SEGUROS Y REASEGUROS, S.A.",
    "HELVETIA COMPAÑIA SUIZA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS",
    "IBERCAJA VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.",
    "KUTXABANK ASEGURADORA COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.",
    "KUTXABANK VIDA Y PENSIONES COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.",
    "LA FE PREVISORA COMPAÑIA DE SEGUROS, S.A.",
    "LA PREVISION MALLORQUINA DE SEGUROS, S.A.",
    "LA UNION ALCOYANA, S.A. DE SEGUROS Y REASEGUROS.",
    "LA UNION MADRILEÑA DE SEGUROS, S.A.",
    "LIBERBANK VIDA Y PENSIONES, SEGUROS Y REASEGUROS, S.A.",
    "LIBERTY SEGUROS, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.",
    "LINEA DIRECTA ASEGURADORA SOCIEDAD ANÓNIMA COMPAÑIA DE SEGUROS Y REASEGUROS",
    "MAPFRE ASISTENCIA, COMPAÑIA INTERNACIONAL DE SEGUROS Y REASEGUROS, S.A.",
    "MAPFRE ESPAÑA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.",
    "MAPFRE VIDA, S.A. DE SEGUROS Y REASEGUROS SOBRE LA VIDA HUMANA",
    "MARCH VIDA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUR OS",
    "MARTIERRA SEGUROS, S.A.",
    "MEDITERRANEO VIDA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS",
    "MERIDIANO, S.A., COMPAÑIA ESPAÑOLA DE SEGUROS",
    "METROPOLIS, S.A., COMPAÑIA NACIONAL DE SEGUROS Y REASEGUROS.",
    "MGS SEGUROS Y REASEGUROS S.A.",
    "MUTUA MADRILEÑA AUTOMOVILISTA, SOCIEDAD DE SEGUROS A PRIMA FIJA",
    "NATIONALE-NEDERLANDEN VIDA, SEGUROS S.A",
    "NATIONALE-NEDERLANDEN VIDA, SEGUROS S.A / ING BANK NV-Operador de Banca Seguros",
    "NORTEHISPANA DE SEGUROS Y REASEGUROS, S.A.",
    "OCASO, S.A., COMPAÑIA DE SEGUROS Y REASEGUROS.",
    "PATRIA HISPANA, S.A. DE SEGUROS Y REASEGUROS",
    "PELAYO VIDA SEGUROS Y REASEGUROS, S.A.",
    "PLUS ULTRA SEGUROS GENERALES Y VIDA, S.A. DE SEGUROS Y REASEGUROS",
    "POLICLINICO, CENTRO MEDICO DE SEGUROS, S.A.",
    "PREDICA -PREVOYANCE DIALOGUE CREDIT AGRICOLE SUC ESPAÑA",
    "PREVENTIVA, CIA. DE SEGUROS Y REASEGUROS, S.A.",
    "REALE SEGUROS GENERALES, S.A.",
    "REALE VIDA Y PENSIONES, SOCIEDAD ANÓNIMA DE SEGUROS",
    "RGA RURAL VIDA, S.A. DE SEGUROS Y REASEGUROS",
    "S.O.S. SEGUROS Y REASEGUROS, S.A.",
    "SA NOSTRA COMPAÑÍA DE SEGUROS DE VIDA, S.A.",
    "SALUS ASISTENCIA SANITARIA, S.A. DE SEGUROS",
    "SANITAS, SOCIEDAD ANONIMA DE SEGUROS.",
    "SANTA LUCÍA VIDA Y PENSIONES, S.A. , Compañía de Seguros y Reaseguros",
    "SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS",
    "SANTANDER MAPFRE SEGUROS Y REASEGUROS, S.A.",
    "SANTANDER SEGUROS Y REASEGUROS, COMPAÑIA ASEGURADORA, S.A.",
    "SANTANDER VIDA SEGUROS Y REASEGUROS, S.A.",
    "SEGURCAIXA ADESLAS, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS",
    "SEGUROS CATALANA OCCIDENTE, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS",
    "SEGUROS EL CORTE INGLES, VIDA, PENSIONES Y REASEGUROS, S.A.",
    "SEGUROS LAGUN ARO VIDA, S.A.",
    "SVRNE MUTUA DE SEGUROS Y REASEGUROS A PRIMA FIJA",
    "UNICORP VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.",
    "UNION DEL DUERO, COMPAÑIA DE SEGUROS DE VIDA, S.A.",
    "UNION MEDICA LA FUENCISLA, S.A. COMPAÑIA DE SEGUROS.",
    "VIDACAIXA, SOCIEDAD ANÓNIMA DE SEGUROS Y REASEGUROS",
    "VITAL SEGURO, S.A.",
    "ZURICH VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.",
    "ZURICH VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A. Pueyo Mediación, OBSV, S.L.",
    "METLIFE EUROPE D.A.C.",
    "PREVISORA AGRO-INDUSTRIAL, MUTUALITAT DE PREVISIÓ SOCIAL A PRIMA FIXA",
    "CARDIF ASSURANCE VIE, SUCURSAL EN ESPAÑA / UNION DE CRÉDITOS INMOBILIARIOS, S.A., E.F.C., O.B.S.V.",
    "ANTARES, S.A. (EXTINGUIDA) - PLUS ULTRA SEGUROS GENERALES Y VIDA, S.A. DE SEGUROS Y REASEGUROS",
    "BANCO MEDIOLANUM S.A."
]

export default companies;