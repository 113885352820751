import { useEffect, useState } from 'react';
import EditableTable from '../EditableTable';
import dayjs from 'dayjs';

import { supabaseClient } from '../../config/supabase-clients'; // Corrected import statement

const supabase = supabaseClient;

import { HolidaysUpdater } from '../../api/HolidaysUpdater';

export const HolidayManager = () => {
    const [holidays, setHolidays] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const HolidaysUpdaterFunction = async () => {
        await HolidaysUpdater();
        fetchHolidays();
    };

    const fetchHolidays = async () => {
        setIsLoading(true);
        const { data, error } = await supabase
            .from('holidays')
            .select('*')
            .order('holiday_date', { ascending: true });
        if (error) {
            console.log('Error fetching holidays:', error.message);
            setIsLoading(false);
            return;
        }
        const formattedHolidays = data.map(holiday => ({
            // Formatear fecha a formato dd/mm/yyyy
            index: holiday.index,
            Fecha: dayjs(holiday.holiday_date).format('DD/MM/YYYY'),
            Nombre_festivo: holiday.name,
            Tipo: holiday.type
        }));
        setHolidays(formattedHolidays);
        setIsLoading(false);
    };

    const columns = [
        {
            label: 'Nombre festivo',
            field: 'Nombre_festivo',
            type: 'text',
            width: 'w-1/3', // Ocupa 1/3 del ancho disponible
        },
        {
            label: 'Fecha',
            field: 'Fecha',
            type: 'date',
            width: 'w-1/4', // Ocupa 1/4 del ancho disponible
        },
        {
            label: 'Tipo',
            field: 'Tipo',
            type: 'select',
            width: 'w-1/4', // Ocupa 1/4 del ancho disponible
            options: [
                { value: 'Nacional', label: 'Nacional' },
                { value: 'Regional', label: 'Regional' },
                { value: 'Local', label: 'Local' },
            ],
        },
    ];

    useEffect(() => {
        fetchHolidays();
    }, []);

    const handleUpdateRow = async (updatedRow) => {

        // Tomar el día y mes y añadir el año actual
        const holidayDate = updatedRow.Fecha 

        // Supabase update
        const { error } = await supabase
            .from('holidays')
            .update({
                name: updatedRow.Nombre_festivo,
                holiday_date: holidayDate,
                type: updatedRow.Tipo
            })
            .eq('index', updatedRow.index);
        if (error) {
            console.log('Error updating holiday:', error.message);
            return;
        }
    };

    const handleAddRow = async (newRow) => {

        // Tomar el día y mes y añadir el año actual
        const holidayDate = dayjs(`${newRow.Fecha}`, 'DD/MM/YYYY').format('YYYY-MM-DD');

        // Supabase insert
        const { error } = await supabase
            .from('holidays')
            .insert({
                name: newRow.Nombre_festivo,
                holiday_date: holidayDate,
                type: newRow.Tipo
            });
        if (error) {
            console.log('Error inserting holiday:', error.message);
            return;
        }
    };


    const handleDeleteRow = async (deletedRow) => {
        // Supabase delete
        const { error } = await supabase
            .from('holidays')
            .delete()
            .eq('index', deletedRow.index);
        if (error) {
            console.log('Error deleting holiday:', error.message);
            return;
        }
    };

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    return (
        <>
            {/* Botón centrado a la derecha para actualizar las vacaciones */}
            <div className="flex justify-end">
                <button className="bg-primary-600 text-white w-1/8 py-2 px-2 rounded text:xs disabled:bg-primary-300 mb-4" onClick={HolidaysUpdaterFunction}>Actualizar festivos</button>
            </div>

            <EditableTable
                columns={columns}
                initialData={holidays}
                onUpdateRow={handleUpdateRow}
                onAddRow={handleAddRow}
                onDeleteRow={handleDeleteRow}
            />
        </>
    );
};