/* eslint-disable no-unused-vars */
import { useEffect, useState, Fragment, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import toast from 'react-hot-toast';
import { Toaster as SonnerToaster, toast as sonnerToast } from 'sonner';
import { useAuth } from "../hooks/useAuth";
import LogoSoloBlack from '../assets/img/logo/logo_rolo_black.png';
import LogoSoloWhite from '../assets/img/logo/logo_rolo_white.png';
import { createClient } from '@supabase/supabase-js';
import { GetUsuarioXCorreduria } from "../data/GetUsuarioXCorreduria";
import { GetNotifications } from "../data/GetNotifications";
import { LoadingComponent } from "./LoadingComponent";
import { MaintenanceComponent } from "./MaintenanceComponent";
import { debounce, get } from 'lodash';
import { GetMaintenance } from "../data/GetMaintenance";
import { GetAlerts } from "../data/GetAlerts";
import { motion, AnimatePresence } from "framer-motion";
import { initFlowbite } from "flowbite";
import { PlusIcon, BellAlertIcon, UserIcon, DocumentPlusIcon, HomeIcon, DocumentArrowDownIcon, CircleStackIcon, AdjustmentsHorizontalIcon, DocumentTextIcon, UsersIcon, ArrowRightOnRectangleIcon, ChatBubbleLeftRightIcon, LifebuoyIcon, MoonIcon, SunIcon, Cog6ToothIcon, ChatBubbleLeftIcon, BellIcon, BriefcaseIcon, UserCircleIcon } from "@heroicons/react/20/solid";
import { Transition, Dialog } from '@headlessui/react';
import packageJson from '../../package.json';
import axios from "axios";
import moment from "moment";
import 'moment/dist/locale/es';
import 'moment/dist/locale/en-gb';
import { useColorScheme } from "../context/ColorSchemeContext";
import { UpdateNotification } from "./UpdateNotification";
import { useTranslation } from 'react-i18next';
import { set } from "lodash";
import { use } from "i18next";
import { useClock } from "../hooks/useClock";

export const RootLayout = ({ children, active }) => {
    let currentDay = null;
    let correduriaUsers = [];
    let navigate = useNavigate();
    const { t, i18n } = useTranslation()
    const loadMoreRef = useRef(null);
    const messagesEndRef = useRef(null)
    const { user, userPublicData, correduria } = useAuth();
    const [usuarioxcorreduria, setUsuarioxcorreduria] = useState({});
    const [NavLoading, setNavLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [alertTimeRemaining, setAlertTimeRemaining] = useState(null);
    const [maintenance, setMaintenance] = useState({});
    const [open, setOpen] = useState(false);
    const [alertCollapsed, setAlertCollapsed] = useState(true);
    const [notificationsUnread, setNotificationsUnread] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [notificationUnReadClass, setNotificationUnReadClass] = useState("");
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState({});
    const [chatMinimized, setChatMinimized] = useState(false);
    const [messagesLoading, setMessagesLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [messagesUnread, setMessagesUnread] = useState(false);
    const [messagesRealtimeConnection, setMessagesRealtimeConnection] = useState(false);
    const [newConversationSearches, setNewConversationSearches] = useState([]);
    const language = localStorage.getItem('language');
    const [usersOnline, setUsersOnline] = useState([]);
    const [lastLoadedIndexNotifications, setLastLoadedIndexNotifications] = useState(0);
    const [hasMoreNotifications, setHasMoreNotifications] = useState(true);
    const { isWorking, handleClockInOut, elapsedTime } = useClock();
    moment.locale(language === 'en' ? 'en-gb' : 'es');
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const [currentTime, setCurrentTime] = useState(capitalizeFirstLetter(moment().format('dddd, D [de] MMMM [de] YYYY, HH:mm:ss')));
    const [status, setStatus] = useState({
        platform: "loading",
        login: "loading",
        api: "loading",
        whatsapp: "loading"
    });
    const { colorScheme, changeColorScheme } = useColorScheme(); //ColorScheme es el contexto que nos dice si el tema es dark o light
    const darkMode = colorScheme === 'dark'; //Dark mode estará a true si el contexto dice que el tema es dark

    useEffect(() => {
        initFlowbite();
        async function getUsuarioXCorreduria() {
            const usuarioxcorreduria = await GetUsuarioXCorreduria();
            setUsuarioxcorreduria(usuarioxcorreduria);
            setNavLoading(false);
        }

        if (user) {
            getUsuarioXCorreduria();
        }

        async function getMaintenance() {
            const maintenance = await GetMaintenance('all');
            if (!userPublicData.maintenance_excluded) {
                setMaintenance(maintenance);
            }
            if (userPublicData.home_modal == true && maintenance.maintenance == false) {
                setOpen(true);
            }
            loadNotifications();
            setLoading(false);
        }

        setNotificationsUnread(userPublicData.notifications_unread);
        if (userPublicData.notifications_unread) {
            setNotificationUnReadClass("growAndShake");
            console.log(notificationUnReadClass);
            setTimeout(() => {
                setNotificationUnReadClass("");
                console.log(notificationUnReadClass);
            }, 1500);
        }

        if (user) {
            getUsuarioXCorreduria();
        }
        getMaintenance();
        async function getAlerts() {
            const alert_array = await GetAlerts();
            // En aler hay un array con dos objetos, debemos cambiar alert al objeto en el que text_bool es false
            var alert = alert_array.filter((alert_array) => alert_array.text_bool == false)[0];
            var alert_text = alert_array.filter((alert_array) => alert_array.text_bool == true)[0];
            if (alert_text.activate == true) {
                setAlertText(alert_text.text);
            }
            setAlertTimeRemaining(alert.datetime);
            setAlert(alert.activate);
        }
        getAlerts();
        setInterval(() => {
            getAlerts();
        }, 60000);

        getConversations();

        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        )
        const channels = supabaseClient.channel('custom-update-channel')
            .on(
                'postgres_changes',
                { event: 'UPDATE', schema: 'public', table: 'maintenance_pages' },
                (payload) => {
                    if (payload.new.page == "all" && payload.new.maintenance == true) {
                        setMaintenance({
                            maintenance: true,
                        });
                    } else if (payload.new.page == "all" && payload.new.maintenance == false) {
                        setMaintenance({
                            maintenance: false,
                        });
                    }
                }
            )
            .on(
                'postgres_changes',
                { event: 'UPDATE', schema: 'public', table: 'alerts' },
                (payload) => {
                    if (payload.new.text_bool == true && payload.new.activate == true) {
                        setAlertText(payload.new.text);
                        setAlertCollapsed(true);
                    }
                    else if (payload.new.text_bool == true && payload.new.activate == false) {
                        setAlertText("");
                    } else if (payload.new.text_bool == false && payload.new.activate == true) {
                        setAlert(true);
                        setAlertTimeRemaining(payload.new.datetime);
                    } else if (payload.new.text_bool == false && payload.new.activate == false) {
                        setAlert(false);
                    }
                }
            )
            .on(
                'postgres_changes',
                { event: 'INSERT', schema: 'public', table: 'notifications' },
                async (payload) => {
                    if (payload.new.conversation_id) {
                        return;
                    }
                    setNotificationsUnread(true);
                    setNotifications(prevNotifications => [payload.new, ...prevNotifications]);
                    if (payload.new.url && payload.new.url.includes("whatsapp?wa_id")) {
                        sonnerToast.message('Nuevo WhatsApp', {
                            description: payload.new.text,
                            duration: 900000,
                            icon: <ChatBubbleLeftIcon className="w-4 h-4" />,
                            action: {
                                label: 'Abrir',
                                onClick: async () => {
                                    const { data, error } = await supabaseClient.from('users').update({ notifications_unread: false }).eq('user_id', user.id).select();
                                    setNotificationsUnread(false);
                                    navigate(payload.new.url);
                                }
                            }
                        });
                    } else {
                        sonnerToast.message('Nueva notificación', {
                            description: payload.new.text,
                            duration: 900000,
                            icon: <BellAlertIcon className="w-4 h-4" />
                        });
                    }
                    const supabaseClient = createClient(
                        'https://uoclqlfzojdjrhcqhgef.supabase.co',
                        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
                    )
                    const { data, error } = await supabaseClient.from('users').update({ notifications_unread: true }).eq('user_id', user.id).select();
                }
            )
            .subscribe()

        if (selectedConversation.id) {
            getMessages(selectedConversation.id);
        }


        const userPresence = {
            user_id: user.id
        }

        const presenceRoom = supabaseClient.channel('presence:' + correduria.id)
            .on('presence', { event: 'sync' }, () => {
                const newState = presenceRoom.presenceState()
                // En newState tenemos un array con un array dentro de cada uno que incluye el user_id. Debemos guardar los user_id en un array
                let usersOnline = [];
                for (const key in newState) {
                    usersOnline.push(newState[key][0].user_id);
                }
                setUsersOnline(usersOnline);
            })
            .on('presence', { event: 'join' }, ({ key, newPresences }) => {
            })
            .on('presence', { event: 'leave' }, ({ key, leftPresences }) => {
            })
            .subscribe(async (status) => {
                if (status !== 'SUBSCRIBED') { return }

                const presenceTrackStatus = await presenceRoom.track(userPresence)
            })

        return () => {
            channels.unsubscribe()
        }
    }, []);

    const batchSizeNotifications = 10;

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                loadNotifications();
            }
        });

        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }

        return () => {
            if (loadMoreRef.current) {
                observer.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, loadNotifications]);

    async function loadNotifications() {
        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        )
        const { data, error } = await supabaseClient
            .from('notifications')
            .select('*')
            .order('created_at', { ascending: false })
            .range(lastLoadedIndexNotifications, lastLoadedIndexNotifications + batchSizeNotifications - 1);

        if (error) {
            console.error('Error loading notifications:', error);
            return;
        }

        if (data.length < batchSizeNotifications) {
            setHasMoreNotifications(false);
        }

        setNotifications(prevNotifications => [...prevNotifications, ...data]);
        setLastLoadedIndexNotifications(lastLoadedIndexNotifications + data.length);
    }

    useEffect(() => {
        if (!hasMoreNotifications && loadMoreRef.current) {
            observer.unobserve(loadMoreRef.current);
        }
    }, [hasMoreNotifications, loadMoreRef]);

    async function getConversations() {
        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        )
        const { data, error } = await supabaseClient.from('internal_chat_conversations').select('*').order('created_at', { ascending: false });
        const { data: correduriaUsersData, error: error2 } = await supabaseClient.rpc('get_correduria_users');
        correduriaUsers = correduriaUsersData;
        setConversations(data);
    }

    useEffect(() => {
        setMessagesRealtimeConnection(false);
        if (selectedConversation && selectedConversation.id) {
            const supabaseClient = createClient(
                'https://uoclqlfzojdjrhcqhgef.supabase.co',
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
            )
            let initialMessageSkipped = false;
            let previouslyDisconnected = true;
            const channels = supabaseClient.channel('custom-messages-channel-selected-conversation')
                .on(
                    'postgres_changes',
                    { event: 'INSERT', schema: 'public', table: 'internal_chat_messages' },
                    (payload) => {
                        if (selectedConversation.id == payload.new.conversation_id) {
                            setMessages(prevMessages => [...prevMessages, payload.new]);
                            // Para saber si debemos hacer scroll, debemos comprobar si el usuario está consultando los mensajes antiguos
                            if (messagesEndRef.current.getBoundingClientRect().top < window.innerHeight) {
                                setTimeout(() => {
                                    scrollToBottom();
                                }, 100);
                            }
                            if (payload.new.from != user.id && chatMinimized) {
                                setMessagesUnread(true);
                            }
                        }
                    }
                ).on(
                    'postgres_changes',
                    { event: 'INSERT', schema: 'public', table: 'notifications' },
                    async (payload) => {
                        if (payload.new.conversation_id) {
                            if (selectedConversation.id == payload.new.conversation_id) {
                                console.log('Nuevo mensaje en conversación seleccionada');
                                const { data, error } = await supabaseClient.from('notifications').delete().eq('id', payload.new.id);
                                console.log(data);
                                return;
                            } else {
                                setNotificationsUnread(true);
                                setNotifications(prevNotifications => [payload.new, ...prevNotifications]);
                                sonnerToast.message('Nuevo mensaje', {
                                    description: payload.new.text,
                                    duration: 10000,
                                    icon: <ChatBubbleLeftIcon className="w-4 h-4" />,
                                    action: {
                                        label: 'Ver',
                                        onClick: () => {
                                            setSelectedConversation(conversations.find(conversation => conversation.id == payload.new.conversation_id));
                                            setChatMinimized(false);
                                            getMessages(payload.new.conversation_id);
                                        }
                                    }
                                });
                            }
                        }
                    }
                ).on('system', {}, (payload) => {
                    if (payload.extension !== 'postgres_changes' || payload.status !== 'ok') {
                        return;
                    }

                    if (!initialMessageSkipped) {
                        initialMessageSkipped = true;
                        return;
                    }

                    if (previouslyDisconnected) {
                        previouslyDisconnected = false;
                    }
                    setMessagesRealtimeConnection(true);
                })
                .on(
                    // Cuando se cierra la conexión, se vuelve a abrir
                    'close',
                    () => {
                        setMessagesRealtimeConnection(false);
                    }
                )
                .subscribe((status) => {
                    if (status === 'SUBSCRIBED') {
                        previouslyDisconnected = true;
                    }
                })

            return () => {
                channels.unsubscribe()
                setMessagesRealtimeConnection(false);
            }
        }
        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        )
        const channels = supabaseClient.channel('custom-messages-channel')
            .on(
                'postgres_changes',
                { event: 'UPDATE', schema: 'public', table: 'internal_chat_conversations' },
                (payload) => {
                    const conversationIndex = conversations.findIndex(conversation => conversation.id == payload.new.id);
                    if (conversationIndex != -1) {
                        const newConversations = [...conversations];
                        newConversations[conversationIndex] = payload.new;
                        setConversations(newConversations);
                    }
                }
            ).on(
                'postgres_changes',
                { event: 'INSERT', schema: 'public', table: 'notifications' },
                async (payload) => {
                    console.log(payload);
                    if (payload.new.conversation_id) {
                        console.log(payload.new.conversation_id);
                        if (selectedConversation.id == payload.new.conversation_id) {
                            console.log('Nuevo mensaje en conversación seleccionada');
                            const { data, error } = await supabaseClient.from('notifications').delete().eq('id', payload.new.id);
                            const { data: data2, error: error2 } = await supabaseClient.from('users').update({ notifications_unread: false }).eq('user_id', user.id).select();
                            console.log(data);
                            return;
                        } else {
                            setNotificationsUnread(true);
                            setNotifications(prevNotifications => [payload.new, ...prevNotifications]);
                            sonnerToast.message('Nuevo mensaje', {
                                description: payload.new.text,
                                duration: 10000,
                                icon: <ChatBubbleLeftIcon className="w-4 h-4" />,
                                action: {
                                    label: 'Ver',
                                    onClick: () => {
                                        setSelectedConversation(conversations.find(conversation => conversation.id == payload.new.conversation_id));
                                        setChatMinimized(false);
                                        setNotificationsUnread(false);
                                        getMessages(payload.new.conversation_id);
                                    }
                                }
                            });
                        }
                    }
                }
            ).on(
                'postgres_changes',
                { event: 'INSERT', schema: 'public', table: 'internal_chat_conversations' },
                (payload) => {
                    console.log(payload.new);
                    setConversations(prevConversations => [payload.new, ...prevConversations]);
                }
            ).subscribe()

        return () => {
            channels.unsubscribe()
        }
    }, [selectedConversation, conversations]);


    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(capitalizeFirstLetter(moment().format('dddd, D [de] MMMM [de] YYYY, HH:mm:ss')));
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    async function signOut() {
        const loadingToast = toast.loading("Cerrando sesión...");
        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        );
        const { error } = await supabaseClient.auth.signOut();
        toast.dismiss(loadingToast);
        if (error) {
            toast.error(t("Ha ocurrido un error inesperado"));
        }
    }

    async function onCloseModal() {
        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        );
        setOpen(false);
        const { data, error } = await supabaseClient.from('users').update({ home_modal: false }).eq('user_id', user.id).select();
        if (error) {
            toast.error(error.message);
            return false;
        }
    }

    function changeTheme() { //Función que cambia el tema usando el contexto y añadiendo o quitando la clase dark
        if (colorScheme === 'dark') {
            changeColorScheme('light');
            document.documentElement.classList.remove('dark');
        } else {
            changeColorScheme('dark');
            document.documentElement.classList.add('dark');
        }
    }

    function toggleCollapse() {
        const dropdownCta = document.getElementById('dropdown-cta');
        dropdownCta.classList.toggle('collapsed');
        setAlertCollapsed(!alertCollapsed);
    }

    async function getStatus() {
        setStatus({
            platform: "loading",
            login: "loading",
            api: "loading",
            whatsapp: "loading"
        });

        await axios.get('https://api.office.coventia.es/api/status/get_status/')
            .then(function (response) {
                setStatus({
                    platform: response.data.platform,
                    login: response.data.login,
                    api: response.data.api,
                    whatsapp: response.data.whatsapp
                });
            })
            .catch(function (error) {
                setStatus({
                    platform: "up",
                    login: "up",
                    api: "error",
                    whatsapp: "error"
                });
            })
    }

    async function opOpenNotifications() {
        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        )
        await supabaseClient.from('users').update({ notifications_unread: false }).eq('user_id', user.id).select();
        setNotificationsUnread(false);
    }

    async function getMessages(id) {
        setMessagesLoading(true);
        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        )
        // Seleccionar los mensajes de la conversation_id seleccionada
        const { data, error } = await supabaseClient.from('internal_chat_messages').select('*').eq('conversation_id', id).order('created_at', { ascending: true });
        setMessages(data);
        setMessagesLoading(false);
        setTimeout(() => {
            scrollToBottom();
        }, 100);
    }

    async function sendMessage(id, text) {
        if (!text) {
            return false;
        }
        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        )
        const { data, error } = await supabaseClient.from('internal_chat_messages').insert([{ conversation_id: id, from: user.id, text: text }]).select();
        if (error) {
            toast.error(error.message);
            return false;
        }
    }

    const handleNewConversationInputChange = useCallback(async (event) => {
        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        )
        console.log(correduriaUsers);
        const filteredData = correduriaUsers.filter((user) => user.first_name.toLowerCase().includes(event.target.value.toLowerCase()) || user.username.toLowerCase().includes(event.target.value.toLowerCase()) || user.email.toLowerCase().includes(event.target.value.toLowerCase()));
        setNewConversationSearches(filteredData);;
    }, []);

    async function createNewConversation(user_id, user_name) {
        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        )
        const { data, error } = await supabaseClient.from('internal_chat_conversations').insert([{ user_1: user.id, user_2: user_id, user_1_name: userPublicData.first_name, user_2_name: user_name }]).select();
        if (error) {
            toast.error(error.message);
            return false;
        }
        setSelectedConversation(data[0]);
        setConversations(prevConversations => [data[0], ...prevConversations]);
        setNewConversationSearches([]);
        getMessages(data[0].id);
        setChatMinimized(false);
    }


    const debouncedHandleNewConversationInputChange = useRef(debounce(handleNewConversationInputChange, 500)).current;

    const activeClass = "sidebar-item activeClass flex items-center p-2 text-base text-gray-900 rounded-lg bg-gray-100 group dark:text-gray-200 dark:bg-gray-700  bg-gray-100 dark:bg-gray-700";
    const noActiveClass = "sidebar-item noActiveClass flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group";

    return (
        <>
            <div className={!loading ? "hidden" : ""}>
                <LoadingComponent />
            </div>
            {/* Ahora el div que debe comprobar si loading es false y si maintenance es true */}
            <div className={!loading && maintenance.maintenance == true ? "" : "hidden"}>
                <MaintenanceComponent />
            </div>
            <div className={loading || maintenance.maintenance == true ? "hidden" : ""}>
                {selectedConversation.id && (
                    // Debe tener animación de entrada
                    <div id="chat-container" className="fixed bottom-14 right-4 w-96 z-50">
                        <div className={"fixed right-0 mb-4 mr-4 transition-all duration-500 ease-in-out " + (chatMinimized ? "-bottom-50" : "bottom-0")}>
                            <div className="bg-white shadow-md rounded-lg max-w-lg w-full dark:bg-gray-800 w-full">
                                <div className={"p-4 border-b bg-primary-500 text-white rounded-t-lg flex justify-between items-center dark:bg-gray-700 dark:border-gray-600 w-96 " + (chatMinimized ? "cursor-pointer" : "")} onClick={() => { if (chatMinimized) { setChatMinimized(false); setMessagesUnread(false); setTimeout(() => { scrollToBottom(); }, 100); } }}>
                                    <div className="flex items-center">
                                        <div style={{ position: 'relative' }} title={usersOnline.includes(selectedConversation.user_1 == user.id ? selectedConversation.user_2 : selectedConversation.user_1) ? "En línea" : "Desconectado"}>
                                            <UserIcon className="w-6 h-6" />
                                            <div className={usersOnline.includes(selectedConversation.user_1 == user.id ? selectedConversation.user_2 : selectedConversation.user_1) ?
                                                "animate-ping absolute bottom-0 right-0 w-3 h-3 bg-green-500 border-2 border-white rounded-full dark:border-white-900" :
                                                "animate-ping absolute bottom-0 right-0 w-3 h-3 bg-red-500 border-2 border-white rounded-full dark:border-gray-900"}>
                                            </div>
                                            <div className={usersOnline.includes(selectedConversation.user_1 == user.id ? selectedConversation.user_2 : selectedConversation.user_1) ?
                                                "absolute bottom-0 -right-0 w-3 h-3 bg-green-500 border-2 border-white rounded-full dark:border-gray-900" :
                                                "absolute bottom-0 -right-0 w-3 h-3 bg-red-500 border-2 border-white rounded-full dark:border-gray-900"}>
                                            </div>
                                        </div>
                                        <p className={"text-lg font-semibold ml-2 " + (chatMinimized && messagesUnread ? "truncate w-32" : "")}>{selectedConversation.user_1 == user.id ? selectedConversation.user_2_name : selectedConversation.user_1_name}</p>
                                        {chatMinimized && messagesUnread && (
                                            <span className="bg-primary-400 text-white text-xs font-semibold rounded-full px-2 py-1 ml-2">Nuevos mensajes</span>
                                        )}
                                    </div>
                                    <div className="inline-flex items-center">
                                        <button id="minimize-chat" className={"transition-all duration-500 text-white hover:text-gray-200 ml-2 " + (chatMinimized ? "rotate-180" : "")} onClick={() => { setChatMinimized(!chatMinimized); if (chatMinimized) setMessagesUnread(false); if (chatMinimized) setTimeout(() => { scrollToBottom(); }, 100); }}>
                                            <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 9-7 7-7-7" />
                                            </svg>
                                        </button>
                                        {/* Cerrar y desminimizar */}
                                        <button id="close-chat" className="text-white hover:text-gray-200 focus:outline-none ml-2" onClick={() => { setSelectedConversation({}); setChatMinimized(false) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div id="chatbox" className="p-4 h-80 overflow-y-auto scrollbar scrollbar-thumb-gray-200 dark:scrollbar-thumb-gray-700 scrollbar-thumb-rounded-full w-96">
                                    {!messagesRealtimeConnection && (
                                        <div className="absolute top-0 right-0 bg-red-500 text-white font-semibold rounded-tr-lg rounded-bl-lg px-2 py-1 text-xs/[12px]">
                                            Estableciendo conexión...
                                        </div>
                                    )}
                                    {messagesLoading ? (
                                        <div className="flex items-center justify-center h-full">
                                            <div className="flex justify-center items-center">
                                                <div role="status">
                                                    <svg aria-hidden="true" className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                    </svg>
                                                    <span className="sr-only">{t('Cargando...')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {messages.map((message, index) => {
                                                const messageDay = moment(message.created_at).format('YYYY-MM-DD');
                                                let showDay = false;

                                                if (currentDay === null || currentDay !== messageDay) {
                                                    currentDay = messageDay;
                                                    showDay = true;
                                                }

                                                return (
                                                    <div key={message.id}>
                                                        {showDay && <div className="text-center text-gray-500 text-xs mt-2 mb-2">{moment(message.created_at).format('D [de] MMMM [de] YYYY')}</div>}
                                                        <AnimatePresence>
                                                            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className={"mb-2 " + (message.from === user.id ? "text-right" : "")}>
                                                                <div className={"mb-2 " + (message.from === user.id ? "text-right" : "")}>
                                                                    <p className={"bg-" + (message.from === user.id ? "primary-500" : "gray-200") + " text-" + (message.from === user.id ? "white" : "gray-700") + " rounded-lg py-2 px-4 inline-block dark:bg-gray-700 dark:text-gray-200 max-w-96"}>
                                                                        {message.text.split(/\b(https?:\/\/\S+)/gi).map((part, index) => {
                                                                            if (part.match(/\b(https?:\/\/\S+)/gi)) {
                                                                                return (
                                                                                    <a key={index} href={part} target="_blank" rel="noopener noreferrer" className={message.from === user.id ? "text-gray-200 hover:underline break-all dark:text-gray-300" : "text-gray-600 hover:underline break-all dark:text-gray-300"}>
                                                                                        {part}
                                                                                    </a>
                                                                                );
                                                                            } else {
                                                                                return part;
                                                                            }
                                                                        })}
                                                                        <br />
                                                                        <span title={moment(message.created_at).format('LLL')} className={"text-[11px] text-" + (message.from === user.id ? "white" : "black") + " dark:text-gray-400"}>
                                                                            {moment(message.created_at).format('LT')}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </motion.div>
                                                        </AnimatePresence>
                                                    </div>
                                                );
                                            })}
                                            <div ref={messagesEndRef} />
                                        </>
                                    )}
                                </div>
                                <div className="p-4 border-t bg-gray-100 dark:bg-gray-800 dark:border-gray-600 rounded-b-lg">
                                    <form className="flex items-center max-w-lg mx-auto" onSubmit={(e) => { e.preventDefault(); sendMessage(selectedConversation.id, e.target.message.value); e.target.message.value = ""; }}>
                                        <input disabled={messagesLoading} type="text" id="message" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:focus:ring-2 dark:focus:ring-opacity-50" placeholder="Escribe un mensaje" />
                                        <button disabled={messagesLoading || !messagesRealtimeConnection} type="submit" className="inline-flex items-center py-2.5 px-3 ms-2 text-sm font-medium text-white bg-primary-500 rounded-lg border border-primary-500 hover:bg-primary-600 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-800">
                                            <svg className="w-4 h-4 ml-1 text-white-800 dark:text-white rotate-90" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M12 2a1 1 0 0 1 .932.638l7 18a1 1 0 0 1-1.326 1.281L13 19.517V13a1 1 0 1 0-2 0v6.517l-5.606 2.402a1 1 0 0 1-1.326-1.281l7-18A1 1 0 0 1 12 2Z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <SonnerToaster closeButton position="top-right" />
                <UpdateNotification />
                <nav className="fixed top-0 z-50 w-full border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700 bg-white">
                    <div className="px-3 py-3 lg:px-5 lg:pl-3">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center justify-start">
                                <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                                    <span className="sr-only">Open sidebar</span>
                                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                                    </svg>
                                </button>
                                {!correduria.logo &&
                                    <Link className="flex items-center ml-2 md:mr-2 mt-2 sm:mt-0" to="/">
                                        <img src={LogoSoloBlack} className={"h-4 mr-3 " + (darkMode ? "hidden" : "")} alt="Coventia Office" />
                                        <img src={LogoSoloWhite} className={"h-4 mr-3 " + (darkMode ? "" : "hidden")} alt="Coventia Office" />
                                        <span className="self-center text-xl font-semibold sm:text-1xl whitespace-nowrap dark:text-white">Coventia Office</span>
                                    </Link>
                                }
                                {correduria.logo &&
                                    <Link className="flex items-center ml-2 md:mr-2 mt-2 sm:mt-0" to="/">
                                        <img src={correduria.logo} className="h-10 mr-2" alt="Coventia Office" />
                                        <span className="self-center text-xl font-semibold sm:text-1xl whitespace-nowrap dark:text-white">by Coventia</span>
                                    </Link>
                                }
                                <span className="bg-gray-100 text-gray-800 text-sm font-semibold px-2.5 py-0.5 rounded dark:bg-gray-600 dark:text-gray-300 hidden md:block">v{packageJson.version}</span>
                                <button onClick={() => { setOpen(true) }} className="hidden">
                                    <span className="bg-blue-100 text-blue-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-900 hidden md:block">{t('Novedades')}</span>
                                </button>
                                {alert &&
                                    <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 hidden md:inline-flex md:items-center">
                                        <svg className="w-4 h-4 mr-1 text-red-800 dark:text-red-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                            <path fillRule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2 1 1 0 1 0 0-2Z" clipRule="evenodd" />
                                        </svg>
                                        {t('Mantenimiento')} {moment(alertTimeRemaining).fromNow()}
                                    </span>
                                }
                                <span className="ml-2 text-sm text-gray-500 dark:text-gray-400 hidden md:inline-flex">{currentTime}</span>
                            </div>
                            <div className="flex items-center">
                                <div className="flex items-center ml-3">
                                    <div className="flex items-center ml-3">
                                        <button id="dropdownChatButton" data-dropdown-toggle="dropdownChat" className="ml-2 items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600 hidden md:inline-flex" type="button">
                                            <div className="relative inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
                                                <ChatBubbleLeftRightIcon className="w-5 h-5 text-gray-600 dark:text-white" />
                                                {/*
                                                <div className="animate-ping absolute top-0 right-0 w-2.5 h-2.5 bg-red-500 border-2 border-white rounded-full dark:border-gray-900"></div>
                                                <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-red-500 border-2 border-white rounded-full dark:border-gray-900"></div> 
                                                */}
                                            </div>
                                            <span className="ml-1">Chat</span>
                                        </button>
                                        {correduria.name == "coventia" &&
                                            // Solo visible en móviles
                                            <Link to="/panel_empleado" className={"ml-2 relative inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600 inline-flex md:hidden"}>
                                                <BriefcaseIcon className="w-5 h-5 text-gray-600 dark:text-white" />
                                            </Link>
                                        }
                                        <button onClick={opOpenNotifications} id="dropdownNotificationButton" data-dropdown-toggle="dropdownNotification" className={"ml-2 relative inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600 " + notificationUnReadClass} type="button">
                                            <svg className="w-5 h-5 text-gray-600 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14 20">
                                                <path d="M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z" />
                                            </svg>
                                            {notificationsUnread &&
                                                <>
                                                    <div className="animate-ping absolute top-1 right-1.5 w-3 h-3 bg-red-500 border-2 border-white rounded-full dark:border-gray-900"></div>
                                                    <div className="absolute top-1 right-1.5 w-3 h-3 bg-red-500 border-2 border-white rounded-full dark:border-gray-900"></div>
                                                </>
                                            }
                                        </button>
                                        <button id="dropdownStatusButton" data-dropdown-toggle="dropdownStatus" onFocus={getStatus} className="ml-2 items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600 hidden md:inline-flex" type="button">
                                            <LifebuoyIcon className="w-5 h-5 text-gray-600 dark:text-white" />
                                            <span className="ml-1">Status</span>
                                        </button>
                                        {correduria.name == "coventia" &&
                                            <button id="dropdownClockInOutButton" data-dropdown-toggle="dropdownClockInOut" className="ml-2 items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600 hidden md:inline-flex" type="button">
                                                <BriefcaseIcon className="w-5 h-5 text-gray-600 dark:text-white" />
                                                <span className="ml-1">Fichaje</span>
                                            </button>
                                        }
                                        <button type="button" className="ml-3 inline-flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" aria-expanded="false" data-dropdown-toggle="dropdown-user">
                                            <span className="sr-only">Open user menu</span>
                                            <img className="w-8 h-8 rounded-full" src={userPublicData.profile_pic} alt="user photo" id="navbar_profile_picture"></img>
                                        </button>
                                    </div>
                                    <div className="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600" id="dropdown-user">
                                        <div className="px-4 py-3" role="none">
                                            <p className="text-sm text-gray-900 dark:text-white" role="none">
                                                {userPublicData.first_name}
                                            </p>
                                            <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300" role="none">
                                                {user.email}
                                            </p>
                                        </div>
                                        <ul className="py-1" role="none">
                                            <li>
                                                <Link to="/auth/profile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">{t('Ajustes')}</Link>
                                            </li>
                                            <li>
                                                <Link onClick={() => { signOut() }} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">{t('Cerrar sesión')}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div id="dropdownChat" className="z-20 hidden w-full max-w-sm bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-800 dark:divide-gray-700" aria-labelledby="dropdownNotificationButton">
                                        <div className="block px-4 py-2 font-medium text-center text-gray-700 rounded-t-lg bg-gray-50 dark:bg-gray-800 dark:text-white justify-between items-center flex">
                                            {t('Conversaciones')}
                                            <button type="button" className={"px-3 py-2 text-xs font-medium text-center text-white bg-primary-600 rounded-lg hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 " + (conversations.length > 0 ? "" : "hidden")} data-dropdown-toggle="dropdownNewConversation">{t('Crear')}</button>
                                        </div>
                                        <div className="divide-gray-100 dark:divide-gray-700 overflow-y-auto max-h-80 divide-y">
                                            {conversations.length > 0 ?
                                                <>
                                                    {conversations.map((conversation, index) => (
                                                        // En la tabla de conversaciones tenemos: id, user_1, user_2, last_message, created_at, user_1_name y user_2_name. Debemos buscar el nombre del usuario que no sea el nuestro.
                                                        <a key={index} className="cursor-pointer flex px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-700" onClick={() => { setSelectedConversation(conversation); getMessages(conversation.id) }}>
                                                            <div className="flex-shrink-0 relative">
                                                                <UserIcon className="w-8 h-8 text-gray-600 dark:text-white" />
                                                                <div className={usersOnline.includes(conversation.user_1 == user.id ? conversation.user_2 : conversation.user_1) ?
                                                                    "animate-ping absolute bottom-2 right-0 w-3 h-3 bg-green-500 border-2 border-white rounded-full dark:border-white-900" :
                                                                    "animate-ping absolute bottom-2 right-0 w-3 h-3 bg-red-500 border-2 border-white rounded-full dark:border-gray-900"}>
                                                                </div>
                                                                <div className={usersOnline.includes(conversation.user_1 == user.id ? conversation.user_2 : conversation.user_1) ?
                                                                    "absolute bottom-2 -right-0 w-3 h-3 bg-green-500 border-2 border-white rounded-full dark:border-gray-900" :
                                                                    "absolute bottom-2 -right-0 w-3 h-3 bg-red-500 border-2 border-white rounded-full dark:border-gray-900"}>
                                                                </div>
                                                            </div>
                                                            <div className="w-full ps-3">
                                                                <div className="text-gray-700 text-sm mb-1.5 dark:text-gray-300">{conversation.user_1 == user.id ? conversation.user_2_name : conversation.user_1_name}</div>
                                                                <div className="text-xs text-gray-500 dark:text-gray-400">Conversación creada {moment(conversation.created_at).fromNow()}</div>
                                                            </div>
                                                        </a>
                                                    ))}
                                                </>
                                                :
                                                <div className="flex flex-col justify-center items-center my-5">
                                                    <div className="inline-flex items-center text-center">
                                                        <span className="mb-2 text-gray-400 dark:text-gray-600">{t('No tienes conversaciones')}</span>
                                                    </div>
                                                    <button type="button" className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" id="createNewConversationEmpty" data-dropdown-toggle="dropdownNewConversation">Crear conversación</button>
                                                </div>
                                            }
                                        </div>
                                        <div id="dropdownNewConversation" className="z-20 hidden w-30 max-w-sm bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-800 dark:divide-gray-700 max-w-30 overflow-y-auto" aria-labelledby="dropdownNewConversationButton">
                                            <div className="divide-gray-100 dark:divide-gray-700 overflow-y-auto max-h-80 divide-y">
                                                <div className="flex items-center px-4 py-3">
                                                    <input type="text" className="w-full bg-gray-100 dark:bg-gray-700 dark:text-gray-300 text-gray-700 rounded-lg p-1.5 text-sm focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder={t('Buscar usuarios')} onChange={debouncedHandleNewConversationInputChange} />
                                                </div>
                                            </div>
                                            <div className="divide-gray-100 dark:divide-gray-700 overflow-y-auto max-h-80 divide-y">
                                                {newConversationSearches.length > 0 ?
                                                    <>
                                                        {newConversationSearches.map((user, index) => (
                                                            <a key={index} className="flex px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer" onClick={() => { createNewConversation(user.user_id, user.first_name) }}>
                                                                <div className="flex-shrink-0">
                                                                    <UserIcon className="w-8 h-8 text-gray-600 dark:text-white" />
                                                                </div>
                                                                <div className="w-full ps-3">
                                                                    <div className="text-gray-700 text-sm mb-1.5 dark:text-gray-300">{user.first_name}</div>
                                                                    <div className="text-xs text-gray-500 dark:text-gray-400">{user.email}</div>
                                                                </div>
                                                            </a>
                                                        ))}
                                                    </>
                                                    :
                                                    <div className="flex justify-center items-center my-5">
                                                        <div className="inline-flex items-center text-center">
                                                            <span className="ml-2 text-gray-400 dark:text-gray-600">{t('Sin resultados')}</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div id="dropdownNotification" className="z-20 hidden w-full max-w-sm bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-800 dark:divide-gray-700" aria-labelledby="dropdownNotificationButton">
                                        <div className="block px-4 py-2 font-medium text-center text-gray-700 rounded-t-lg bg-gray-50 dark:bg-gray-800 dark:text-white">
                                            {t('Notificaciones')}
                                        </div>
                                        <div className="divide-gray-100 dark:divide-gray-700 overflow-y-auto max-h-80 divide-y">
                                            {notifications.length > 0 ?
                                                <>
                                                    {notifications.map((notification, index) => (
                                                        // Si la notificación tiene un conversation_id, es una notificación de chat y cuando se haga click en ella, se debe abrir el chat con ese conversation_id (buscar en el array conversations)
                                                        <a key={index} target={notification.new_page ? '_blank' : ''} className="flex px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-700" rel="noreferrer" href={notification.url ? notification.url : '#'} onClick={() => { if (notification.conversation_id) { setSelectedConversation(conversations.find(conversation => conversation.id === notification.conversation_id)); getMessages(notification.conversation_id); setChatMinimized(false); } }}>
                                                            <div className="flex-shrink-0">
                                                                {/* Si existe conversation_id o la url incluye "whatsapp?wa_id" */}
                                                                {notification.conversation_id || (notification.url && notification.url.includes("whatsapp?wa_id")) ?
                                                                    <ChatBubbleLeftIcon className="w-5 h-5 text-gray-600 dark:text-white" />
                                                                    :
                                                                    <BellIcon className="w-5 h-5 text-gray-600 dark:text-white" />
                                                                }
                                                            </div>
                                                            <div className="w-full ps-3">
                                                                <div className="text-gray-500 text-sm mb-1.5 dark:text-gray-400">{notification.text}</div>
                                                                <div className="text-xs text-blue-600 dark:text-blue-500">{moment(notification.created_at).fromNow()}</div>
                                                            </div>
                                                        </a>
                                                    ))}
                                                    {hasMoreNotifications &&
                                                        <div ref={loadMoreRef} className="flex justify-center items-center py-2">
                                                            <div className="inline-flex items-center text-center">
                                                                <span className="ml-2 text-gray-400 dark:text-gray-600">{t('Cargando más notificaciones...')}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                                :
                                                <div className="flex justify-center items-center my-5">
                                                    <div className="inline-flex items-center text-center">
                                                        <span className="ml-2 text-gray-400 dark:text-gray-600">{t('No tienes notificaciones')}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div id="dropdownStatus" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-64 dark:bg-gray-700 dark:divide-gray-600">
                                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconHorizontalButton">
                                            <div id="tooltip-loading" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                                {t('Cargando...')}
                                                <div className="tooltip-arrow" data-popper-arrow></div>
                                            </div>
                                            <div id="tooltip-up" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                                {t('Funcionando correctamente')}
                                                <div className="tooltip-arrow" data-popper-arrow></div>
                                            </div>
                                            <div id="tooltip-down" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                                {t('No funciona')}
                                                <div className="tooltip-arrow" data-popper-arrow></div>
                                            </div>
                                            <div id="tooltip-slow" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                                {t('Lento')}
                                                <div className="tooltip-arrow" data-popper-arrow></div>
                                            </div>
                                            <li className="inline-flex items-center">
                                                <div data-tooltip-target="tooltip-loading" role="status" className={"ml-4 " + (status.platform == "loading" ? "" : "hidden")}>
                                                    <svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin-fast dark:text-gray-600 fill-gray-600 dark:fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                    </svg>
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                <svg data-tooltip-target="tooltip-up" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className={"ml-4 w-4 h-4 text-green-500 dark:text-white " + (status.platform == "up" ? "" : "hidden")}>
                                                    <path fillRule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z" clipRule="evenodd" />
                                                </svg>
                                                <svg data-tooltip-target="tooltip-down" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className={"ml-4 w-4 h-4 text-red-500 dark:text-white " + (status.platform == "down" ? "" : "hidden")}>
                                                    <path fillRule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z" clipRule="evenodd" />
                                                </svg>
                                                <svg data-tooltip-target="tooltip-slow" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className={"w-4 h-4 ml-4 text-orange-500 dark:text-white " + (status.platform == "maintenance" || status.platform == "validating" ? "" : "hidden")}>
                                                    <path fillRule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2 1 1 0 1 0 0-2Z" clipRule="evenodd" />
                                                </svg>
                                                <a className="block px-4 py-2">Coventia Office Platform</a>
                                            </li>
                                            <li className="inline-flex items-center">
                                                <div data-tooltip-target="tooltip-loading" role="status" className={"ml-4 " + (status.login == "loading" ? "" : "hidden")}>
                                                    <svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin-fast dark:text-gray-600 fill-gray-600 dark:fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                    </svg>
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                <svg data-tooltip-target="tooltip-up" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className={"ml-4 w-4 h-4 text-green-500 dark:text-white " + (status.login == "up" ? "" : "hidden")}>
                                                    <path fillRule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z" clipRule="evenodd" />
                                                </svg>
                                                <svg data-tooltip-target="tooltip-down" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className={"ml-4 w-4 h-4 text-red-500 dark:text-white " + (status.login == "down" ? "" : "hidden")}>
                                                    <path fillRule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z" clipRule="evenodd" />
                                                </svg>
                                                <svg data-tooltip-target="tooltip-slow" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className={"w-4 h-4 ml-4 text-orange-500 dark:text-white " + (status.login == "maintenance" || status.login == "validating" ? "" : "hidden")}>
                                                    <path fillRule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2 1 1 0 1 0 0-2Z" clipRule="evenodd" />
                                                </svg>
                                                <a className="block px-4 py-2">Coventia Office Login</a>
                                            </li>
                                            <li className="inline-flex items-center">
                                                <div data-tooltip-target="tooltip-loading" role="status" className={"ml-4 " + (status.api == "loading" ? "" : "hidden")}>
                                                    <svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin-fast dark:text-gray-600 fill-gray-600 dark:fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                    </svg>
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                <svg data-tooltip-target="tooltip-up" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className={"ml-4 w-4 h-4 text-green-500 dark:text-white " + (status.api == "up" ? "" : "hidden")}>
                                                    <path fillRule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z" clipRule="evenodd" />
                                                </svg>
                                                <svg data-tooltip-target="tooltip-down" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className={"ml-4 w-4 h-4 text-red-500 dark:text-white " + (status.api == "down" ? "" : "hidden")}>
                                                    <path fillRule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z" clipRule="evenodd" />
                                                </svg>
                                                <svg data-tooltip-target="tooltip-slow" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className={"w-4 h-4 ml-4 text-orange-500 dark:text-white " + (status.api == "maintenance" || status.api == "validating" ? "" : "hidden")}>
                                                    <path fillRule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2 1 1 0 1 0 0-2Z" clipRule="evenodd" />
                                                </svg>
                                                <a className="block px-4 py-2">Coventia Office API</a>
                                            </li>
                                            <li className="inline-flex items-center">
                                                <div data-tooltip-target="tooltip-loading" role="status" className={"ml-4 " + (status.whatsapp == "loading" ? "" : "hidden")}>
                                                    <svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin-fast dark:text-gray-600 fill-gray-600 dark:fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                    </svg>
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                <svg data-tooltip-target="tooltip-up" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className={"ml-4 w-4 h-4 text-green-500 dark:text-white " + (status.whatsapp == "up" ? "" : "hidden")}>
                                                    <path fillRule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z" clipRule="evenodd" />
                                                </svg>
                                                <svg data-tooltip-target="tooltip-down" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className={"ml-4 w-4 h-4 text-red-500 dark:text-white " + (status.whatsapp == "down" ? "" : "hidden")}>
                                                    <path fillRule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z" clipRule="evenodd" />
                                                </svg>
                                                <svg data-tooltip-target="tooltip-slow" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className={"w-4 h-4 ml-4 text-orange-500 dark:text-white " + (status.whatsapp == "maintenance" || status.whatsapp == "validating" ? "" : "hidden")}>
                                                    <path fillRule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2 1 1 0 1 0 0-2Z" clipRule="evenodd" />
                                                </svg>
                                                <a className="block px-4 py-2">Coventia WhatsApp API</a>
                                            </li>
                                        </ul>
                                        <div className="py-2">
                                            <a href="https://status.coventia.es" target="_blank" rel="noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">{t('Ver más')}</a>
                                        </div>
                                    </div>

                                    {/* PONER RESTRICCION SOLO PARA COVENTIA */}

                                    {correduria.name == "coventia" &&
                                        <div id="dropdownClockInOut" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-40 dark:bg-gray-700 dark:divide-gray-600">
                                            <div className="items-center justify-center p-2">
                                                <ul className="text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconHorizontalButton">
                                                    <li className="inline-flex items-center w-full">
                                                        <button
                                                            type="button"
                                                            className={`transition ease-in-out delay-150 rounded-lg hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer my-2 ${isWorking ? 'text-red-500 text:bg-red-800' : 'text-primary-500 dark:text-primary-600'}`}
                                                            onClick={handleClockInOut}
                                                        >
                                                            <div className="flex items-center justify-center text-xl p-1 dark:text-gray-400">
                                                                <svg className="mr-2" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                                                                    <polyline points="10 17 15 12 10 7"></polyline>
                                                                    <line x1="15" y1="12" x2="3" y2="12"></line>
                                                                </svg>
                                                                <div className="flex text-xs justify-center align-middle font-normal 200">
                                                                    Fichar {isWorking ? 'salida' : 'entrada'}
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </li>
                                                    <li className="inline-flex w-full items-center border-t border-gray-100 dark:border-gray-600">
                                                        <Link
                                                            to="/panel_empleado"
                                                            className={`transition ease-in-out delay-150 rounded-lg hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer my-2 text-gray-400`}
                                                        >
                                                            <div
                                                                className="flex items-center justify-center text-xl p-1 hover:bg-gray-100 dark:hover:bg-gray-600  rounded-xl"
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 mr-2">
                                                                    <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
                                                                </svg>
                                                                <span className="flex text-xs w-full h-full justify-center align-middle font-normal">Panel de empleado</span>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li className="inline-flex w-full items-center justify-center border-t border-gray-100 dark:border-gray-600">
                                                        <div
                                                            className={`transition ease-in-out delay-150 rounded-lg duration-300 cursor-pointer my-2 text-gray-400`}
                                                        >
                                                            <div className="flex text-xs justify-center align-middle font-normal">
                                                                Turno actual
                                                            </div>
                                                            <div
                                                                className="flex items-center justify-center text-lg rounded-xl"
                                                            >
                                                                {elapsedTime}
                                                            </div>

                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                <aside id="default-sidebar" className="pt-14 fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidenav">
                    {NavLoading &&
                        <LoadingComponent />
                    }
                    {!NavLoading &&
                        <>
                            <div className="overflow-y-auto py-10 sm:py-5 sm:pb-20 px-3 h-full bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700 scrollbar scrollbar-thumb-gray-200 dark:scrollbar-thumb-gray-700 scrollbar-thumb-rounded-full">
                                <div className="container">
                                    <div className="menu-div">
                                        <ul className="space-y-2">
                                            <li>
                                                {/* La clase del Link debe ser la constante activeClass si active=inicio, sino "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" */}
                                                <Link to="/" className={active == "inicio" ? activeClass : noActiveClass}>
                                                    <div className="semicircle transition duration-150"></div>
                                                    <HomeIcon className="w-6 h-6 text-gray-400 transition duration-150 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                                                    <span className="ml-3">{t('Inicio')}</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/" + correduria.name + "/crear_presupuesto"} className={active == "crear_presupuesto" ? activeClass : noActiveClass}>
                                                    <div className="semicircle"></div>
                                                    <DocumentPlusIcon className="w-6 h-6 text-gray-400 transition duration-150 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                                                    <span className="ml-3">{t('Crear Presupuesto')} </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/" + correduria.name + "/recuperar_presupuesto"} className={active == "recuperar_presupuesto" ? activeClass : noActiveClass}>
                                                    <div className="semicircle"></div>
                                                    <DocumentArrowDownIcon className="w-6 h-6 text-gray-400 transition duration-150 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                                                    <span className="ml-3">{t('Recuperar Presupuesto')}</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/" + correduria.name + "/db"} className={active == "db" ? activeClass : noActiveClass}>
                                                    <div className="semicircle"></div>
                                                    <CircleStackIcon className="w-6 h-6 text-gray-400 transition duration-150 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                                                    <span className="ml-3">{t('Base de Datos')}</span>
                                                </Link>
                                            </li>
                                            {/* {usuarioxcorreduria.staff == true && correduria.name == 'coventia' &&
                                                <li>
                                                    <Link to={"/" + correduria.name + "/revision_polizas"} className={active == "revision_polizas" ? activeClass : noActiveClass}>
                                                        <svg className="w-6 h-6 text-gray-400 transition duration-150 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                            <path fillRule="evenodd" d="M9 7V2.2a2 2 0 0 0-.5.4l-4 3.9a2 2 0 0 0-.3.5H9Zm2 0V2h7a2 2 0 0 1 2 2v5.7l-4.3-1.6a2 2 0 0 0-1.4 0l-5 1.9A2 2 0 0 0 8 11.9 13.1 13.1 0 0 0 13 22H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Z" clipRule="evenodd"/>
                                                            <path fillRule="evenodd" d="M15.3 9a1 1 0 0 0-.6 0l-5 1.9a1 1 0 0 0-.7 1 12.1 12.1 0 0 0 5.4 10c.4.2.8.2 1.2 0a12.4 12.4 0 0 0 5.4-10 1 1 0 0 0-.7-1l-5-1.8ZM15 19.9a10.4 10.4 0 0 0 4-7.3l-4-1.4v8.7Z" clipRule="evenodd"/>
                                                        </svg>
                                                        <span className="ml-3">Rev. / Emi. de Pólizas</span>
                                                    </Link>
                                                </li>
                                            } */}
                                            <li>
                                                <Link to={"/" + correduria.name + "/simuladores"} className={active == "simuladores" ? activeClass : noActiveClass}>
                                                    {active == "simuladores" && <div className="semicircle"></div>}
                                                    <AdjustmentsHorizontalIcon className="w-6 h-6 text-gray-400 transition duration-150 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                                                    <span className="ml-3">{t('Simuladores')}</span>
                                                </Link>
                                            </li>
                                            {correduria.name == 'coventia' &&
                                                <li>
                                                    <Link to={"/" + correduria.name + "/generar_documentos"} className={active == "generar_documentos" ? activeClass : noActiveClass}>
                                                        <div className="semicircle"></div>
                                                        <DocumentTextIcon className="w-6 h-6 text-gray-400 transition duration-150 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                                                        <span className="ml-3">{t('Generar Documentos')}</span>
                                                    </Link>
                                                </li>
                                            }
                                            {userPublicData.whatsapp == true &&
                                                <li>
                                                    <Link to={"/" + correduria.name + "/whatsapp"} className={active == "whatsapp" ? activeClass : noActiveClass}>
                                                        <div className="semicircle"></div>
                                                        <ChatBubbleLeftRightIcon className="w-6 h-6 text-gray-400 transition duration-150 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                                                        <span className="ml-3">{t('WhatsApp')}</span>
                                                    </Link>
                                                </li>
                                            }
                                        </ul>
                                        <ul className="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
                                            {usuarioxcorreduria.staff == true &&
                                                <li>
                                                    <Link to={"/" + correduria.name + "/manager"} className={active == "manager" ? activeClass : noActiveClass}>
                                                        <div className="semicircle"></div>
                                                        <UsersIcon className="w-6 h-6 text-gray-400 transition duration-150 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                                                        <span className="flex-1 ml-3 whitespace-nowrap">Manager</span>
                                                        <span className="bg-primary-100 text-primary-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-primary-900 dark:text-primary-300">Staff</span>
                                                    </Link>
                                                </li>
                                            }
                                            <li>
                                                <Link onClick={() => { signOut() }} className={noActiveClass}>
                                                    <ArrowRightOnRectangleIcon className="w-6 h-6 text-gray-400 transition duration-150 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                                                    <span className="ml-3">{t('Cerrar sesión')}</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="alert-div">
                                        {/* <div id="dropdown-cta" className={"absolute bottom-0 left-0 right-0 p-4 mt-6 rounded-lg bg-blue-50 dark:bg-blue-900 lg:mb-20 mb-5 mr-3 ml-3 " + (alertText != "" ? "hidden" : "")}>
                                <div className="flex items-center mb-3">
                                    <span className="bg-orange-100 text-orange-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-orange-200 dark:text-orange-900">Beta</span>
                                </div>
                                <p className="mb-3 text-sm text-blue-800 dark:text-blue-400">
                                    Bienvenido a la nueva versión de Coventia Office. Esta rediseñada versión está en fase de pruebas, por lo que puede contener errores. Si encuentras alguno, por favor, repórtalo a <a href="mailto:golcina@coventia.es" className="text-blue-800 underline font-medium hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300">nuestro equipo técnico</a>.
                                </p>
                            </div> */}
                                        <div id="dropdown-cta" className={"absolute bottom-0 left-0 right-0 p-4 mt-6 rounded-lg border-2 border-red-200 bg-red-50 dark:bg-red-50 dark:border-red-400 dark:border-2 lg:mb-20 mb-5 mr-3 ml-3 collapsed " + (alertText != "" ? "" : "hidden")}>
                                            <div className={"flex items-center mb-3"}>
                                                <span className="bg-red-200 text-red-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-800">{t('Alerta')}</span>
                                                <svg style={{ right: "10px" }} className={"svg-not-collapsed w-6 h-6 text-red-800 dark:text-red absolute cursor-pointer " + (alertCollapsed ? "opacity-0" : "opacity-100")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" onClick={toggleCollapse}>
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 9-7 7-7-7" />
                                                </svg>
                                                <svg style={{ right: "10px" }} className={"svg-collapsed w-6 h-6 text-red-800 dark:text-red absolute cursor-pointer " + (alertCollapsed ? "opacity-100" : "opacity-0")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" onClick={toggleCollapse}>
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m5 15 7-7 7 7" />
                                                </svg>
                                            </div>
                                            <p className={`mb-3 text-sm text-red-800 dark:text-red-800 max-w-xs ${alertCollapsed ? 'collapsed' : ''}`}>
                                                {alertText}
                                            </p>
                                            <p className={`mb-3 text-sm text-red-800 dark:text-red-400 ${alertCollapsed ? 'collapsed' : ''}`}>
                                                <a href="https://status.coventia.es/" target="_blank" rel="noreferrer" className="text-red-800 underline font-medium hover:text-red-900 dark:text-red-800 dark:hover:text-red-900">Ver más</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden absolute bottom-0 left-0 justify-center p-4 space-x-4 w-full lg:flex bg-white dark:bg-gray-800 z-20 border-r border-gray-200 dark:border-gray-700">
                                <Link to="/auth/profile" className="inline-flex justify-center p-2 text-gray-500 rounded cursor-pointer dark:text-gray-400 dark:hover:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-600">
                                    <Cog6ToothIcon className="w-6 h-6" />
                                </Link>
                                <button onClick={changeTheme} id="theme-toggle" type="button" className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5">
                                    {/* Los svg deben ser hidden o no hidden dependiendo del tema */}
                                    <MoonIcon id="theme-toggle-dark-icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className={"w-5 h-5 " + (darkMode ? "hidden" : "")}></MoonIcon>
                                    <SunIcon id="theme-toggle-light-icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className={"w-5 h-5 " + (darkMode ? "" : "hidden")}></SunIcon>
                                </button>
                            </div>
                        </>
                    }
                </aside>

                <div className="p-4 sm:ml-64 dark:bg-gray-900">
                    <div className="sm:p-2 md:p-4 mt-14">
                        {children}
                    </div>
                </div>
                {/* Crea un modal avisando de las nuevas funcionalidades de la plataforma */}
                <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-50" initialFocus={open} onClose={() => { onCloseModal() }}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <PlusIcon className="h-6 w-6 text-primary-600" aria-hidden="true" />
                                                </div>
                                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                        Bienvenido a la nueva versión de Coventia Office
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500">
                                                            Versión <span className="bg-purple-100 text-purple-800 text-xs font-medium mr-2 px-2 py-0.5 rounded-full border border-purple-500 dark:bg-purple-900 dark:text-purple-300">{packageJson.version}</span>
                                                        </p>
                                                    </div>
                                                    <div className="mt-4">
                                                        <p className="text-sm text-gray-500">
                                                            Después de meses de trabajo en esta nueva versión, <span className="font-semibold">hemos descartado la versión anterior de Coventia Office</span>.
                                                        </p>
                                                    </div>
                                                    <div className="mt-4">
                                                        <p className="text-sm text-gray-500">
                                                            Se ha <span className="font-semibold">cambiado toda la tecnología de la plataforma y se ha reescrito el código</span> desde cero. Esta nueva versión está pensada para ser más rápida, más segura y más escalable. Además, se ha rediseñado la interfaz para que sea más intuitiva y fácil de usar.
                                                        </p>
                                                    </div>
                                                    <div className="mt-4 ml-3">
                                                        <p className="text-sm text-gray-500">
                                                            - Se ha mejorado la <b>velocidad de carga de la aplicación</b>.<br />
                                                            - Se ha mejorado la <b>velocidad de las peticiones</b> de recuperación de datos, como la página de inicio o la base de datos.<br />
                                                            - Se ha cambiado el diseño, un estilo mucho más <b>minimalista y moderno</b>.<br />
                                                            - Un largo etcétera de mejoras.
                                                        </p>
                                                    </div>
                                                    <div className="mt-4">
                                                        <p className="text-sm text-gray-500">
                                                            {t('Esta nueva versión está en fase de pruebas, por lo que puede contener errores. Si encuentras alguno, por favor, repórtalo a')} <a href="mailto:golcina@coventia.es" className="text-blue-800 underline font-medium hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300">{t('nuestro equipo técnico')}</a>.
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                onClick={() => { onCloseModal() }}
                                            >
                                                {t('¡Entendido!')}
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div >
        </>
    )
}

RootLayout.propTypes = {
    children: PropTypes.node.isRequired,
    active: PropTypes.string,
}
